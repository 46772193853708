import React from 'react';
import {
  Box,
  Text,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from '@chakra-ui/react';
import { useHistory } from 'react-router';

import errorNewLogo from './../../../../assets/images/errorNewLogo.png';
import {
  cancelText,
  primaryColor,
  primaryText,
  px13,
  px14,
} from '../../constants';

const QRErrorModal = ({ isOpen, onClose }) => {
  const history = useHistory();

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent width={300}>
        <ModalBody>
          <Box p={5}>
            <Box display="flex" justifyContent="center">
              <Image
                boxSize="55px"
                objectFit="cover"
                src={errorNewLogo}
                alt="Error"
              />
            </Box>
            <Text
              textAlign="center"
              fontSize={px14}
              mt={8}
              fontWeight="bold"
              color={primaryText}
            >
              QR Not working?
            </Text>

            <Box display="flex" flexDir="column" justifyContent="center" mt={5}>
              <Button
                color={primaryColor}
                border="2px"
                borderColor={primaryColor}
                onClick={() => {
                  onClose();
                  history.push('/pay');
                }}
                size="sm"
                height={['66px']}
                variant="outline"
              >
                <Text fontSize={px13}>Try different method</Text>
              </Button>

              <Text
                as="button"
                color={cancelText}
                mt={5}
                fontSize={px13}
                onClick={onClose}
              >
                Cancel
              </Text>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default QRErrorModal;
