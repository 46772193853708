import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  VStack,
  Text,
  // useBreakpointValue,
  Button,
} from '@chakra-ui/react';
import QrReader from 'react-qr-reader';
import useAlertHook from '../../../scan/hooks/errorHook';
import AlertModal from '../../subComponents/alertModal/alertModal';
// import { instance } from '../../api';
import Loader from '../../subComponents/loader';
import { retrieveHelper, pc, storeHelper } from '../../../utils';
import { checkInInstance } from '../../../api';
// import { storeHelper, pc } from '../../utils';

import './index.css';

const CameraScanner = ({ data, setPage }) => {
  // const instructionTextSize = useBreakpointValue(['md']);

  const qrReader = useRef(null);

  const [result, setResult] = useState(null);
  const [isMounted, setIsMounted] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const { showAlertModal, setShowAlertModal, errorMessage, setErrorMessage } =
    useAlertHook();

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  useEffect(() => {
    if (result != null) {
      qrScanCheckIn(result);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const qrScanCheckIn = result => {
    let extractedCode = result;

    setIsLoading(true);

    let location = retrieveHelper(pc.location);
    let visitorId = retrieveHelper(pc.visitorId);
    let orderId = retrieveHelper(pc.orderId);

    let payload = {
      order_id: orderId,
      qr_code: extractedCode,
      coordinates: location,
      fingerprint_id: visitorId,
    };
    storeHelper(pc.retryPayload, payload);
    setIsLoading(true);

    checkInInstance
      .post('/scan-auth/authorization', payload)
      .then(response => {
        setIsLoading(false);
        let data = response.data;

        if (data.status === 'success') {
          if (data.response.status === 'success') {
            setPage('success');
          } else if (data.response.status === 'pending') {
            storeHelper(pc.retryPayload, payload);
            setPage('pending');
          } else {
            //setErrorMessage('Please contact support');
            setPage('failed');
          }
        } else {
          setErrorMessage(data.message);
        }
      })
      .catch(err => {
        setIsLoading(false);
        setErrorMessage('Something went wrong');
      });
  };

  const previewStyle = {
    width: '90%',
    height: '90%',
    margin: '5%',
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Box
          width="100%"
          display="flex"
          flexDir="column"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Box
            position="relative"
            borderRadius={10}
            width={['90%']}
            margin="auto"
            overflow="clip"
          >
            <Box
              width={['100%', '50%', '30%']}
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
            >
              <Loader mt={0} />
            </Box>
            {isMounted && (
              <QrReader
                ref={qrReader}
                delay={100}
                style={previewStyle}
                facingMode="environment"
                showViewFinder={false}
                onError={err => {
                  console.error(err);
                }}
                onScan={data => {
                  setResult(data);
                }}
              />
            )}
            <Box
              display="flex"
              justifyContent="space-between"
              height="100%"
              style={{ position: 'absolute', top: 0, left: 0, right: 0 }}
            >
              <Box
                display="flex"
                flexDir="column"
                justifyContent="space-between"
              >
                <Box
                  w="45px"
                  h="37px"
                  borderLeft="5px solid #0058ff"
                  borderTop="5px solid #0058ff"
                  borderTopLeftRadius="25%"
                ></Box>
                <Box
                  w="45px"
                  h="37px"
                  borderLeft="5px solid #0058ff"
                  borderBottom="5px solid #0058ff"
                  borderBottomLeftRadius="25%"
                ></Box>
              </Box>
              <Box
                display="flex"
                flexDir="column"
                justifyContent="space-between"
              >
                <Box
                  w="45px"
                  h="37px"
                  borderRight="5px solid #0058ff"
                  borderTop="5px solid #0058ff"
                  borderTopRightRadius="25%"
                ></Box>
                <Box
                  w="45px"
                  h="37px"
                  borderRight="5px solid #0058ff"
                  borderBottom="5px solid #0058ff"
                  borderBottomRightRadius="25%"
                ></Box>
              </Box>
            </Box>
          </Box>

          <Text
            mt={10}
            color="#0058FF"
            fontWeight="500"
            fontfamily="'Rubik', sans-serif"
          >
            Please scan QR code to continue
          </Text>
        </Box>
      )}

      <VStack width="90%" marginLeft="5%" marginRight="5%">
        <Button
          loadingText="booking"
          isFullWidth
          color="#fff"
          borderWidth={1.5}
          borderColor="#0059ff"
          variant="outline"
          size="md"
          onClick={() => qrScanCheckIn('manual check in')}
          bgColor="#0058FF"
          fontWeight="400"
          fontFamily="'Rubik', sans-serif"
        >
          Trouble scanning? Check-in
        </Button>
        <Button
          loadingText="booking"
          isFullWidth
          bgColor="#fff"
          borderWidth={1.5}
          size="md"
          onClick={() => setPage('checkIn')}
          fontWeight="400"
          fontFamily="'Rubik', sans-serif"
        >
          Cancel
        </Button>
      </VStack>

      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          onClose={() => {
            setShowAlertModal(false);
          }}
          message={errorMessage}
        />
      )}
    </>
  );
};

export default CameraScanner;
