import React, { useEffect, useState } from 'react';
import { Box, VStack, Text, Input } from '@chakra-ui/react';
import { useHistory } from 'react-router';

import { retrieveHelper, pc } from '../../../utils';
import {
  primaryColor,
  primaryText,
  px14,
  px16,
  tealGreenColor,
} from '../../constants';
import { BackButton } from '../../subComponents/button';

const TransactionForm = ({ amount, setAmount, isLoading }) => {
  // const [data, setData] = useState({ merchant_type: '' });
  // const [paymentCode, setPaymentCode] = useState('');
  const [qrName, setQrName] = useState('');

  const history = useHistory();
  // const storedClinic = retrieveHelper(pc.clinic);
  const qrCode = retrieveHelper(pc.qrCode);

  const isSmallScreen = window.screen.width < 325;

  useEffect(() => {
    const qrName = retrieveHelper(pc.qrName);

    if (qrCode == null) {
      const inAppDataURL = retrieveHelper(pc.inAppDataURL);
      history.push(`/partner/opd?data=${inAppDataURL}`);
    } else {
      setQrName(qrName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <Box width="100%">
      <VStack>
        <Box
          display="flex"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
        >
          {/* <Box
            width={['75px']}
            height={['75px']}
            borderRadius={['7px']}
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor="#E6EFFF"
          >
            {getTypeIcon('others', '40px')}
          </Box> */}
          <Text
            fontSize={px16}
            my={2}
            textAlign="center"
            color={primaryText}
            className="poppins fw-500"
          >
            Paying to {qrName}
          </Text>

          {/* <Text fontSize={px12} color={secondryText} mb={3}>
            Beneficiary name as per UPI ID
          </Text> */}
        </Box>

        <Box width="60%">
          <Input
            disabled={isLoading}
            textAlign="center"
            height={['36px']}
            color={tealGreenColor}
            type="number"
            autoFocus={true}
            min={0}
            step={1}
            value={amount}
            pattern="[0-9]"
            className='poppins fw-600'
            onChange={event => {
              if (
                Number(event.target.value) <= 100000 &&
                Number(event.target.value >= 0)
              ) {
                if (
                  Number(event.target.value) === 0 &&
                  event.target.value.length > 0
                ) {
                  setAmount('0');
                } else {
                  let t = event.target.value;
                  t = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
                  setAmount(t);
                }
              }
            }}
            placeholder="Enter Amount"
            fontSize={isSmallScreen ? px14 : px16}
          />
        </Box>

        {/* <Box>
          {Number(amount) === 0 ? null : (
            <Box
              width={['100%']}
              textAlign="center"
              fontSize={px12}
              color={secondryText}
            >
              {`Available OPD Benefits: `}
              <span style={{ color: primaryColor }}>
                ₹{Math.round(balance).toString()}{' '}
              </span>
            </Box>
          )}
        </Box> */}

        {/* <Text fontSize={px13} fontWeight="bold" color={primaryText}>
          ISO 27001 Certified
        </Text> */}

        {!isLoading && (
          <BackButton
            color={primaryColor}
            onClick={() => history.goBack()}
          />
        )}
      </VStack>
    </Box>
  );
};

export default TransactionForm;
