import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Image,
  Button,
  VStack,
  Tooltip,
  Input,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { InputControl, SubmitButton } from 'formik-chakra-ui';
import * as Yup from 'yup';
// import OtpInput from 'react-otp-input';

import { parseErrorMessage, state as vState } from './../../../utils';
import { onboardInstance } from './../../../api';
import { useLocation } from 'react-router-dom';
import successLogo from './../../../../assets/images/onboard-success.png';
import { EditIcon } from '@chakra-ui/icons';
import Loader from '../../../scan/subComponents/loader';
// import introVideo from './../../../../assets/images/intro.mp4';
// import { useLocation } from "react-router-dom";

const validationSchema = Yup.object({
  full_name: Yup.string()
    .required('Full name is required')
    .min(2, 'min 2 char allowed')
    .max(50, 'max 50 char allowed'),
  last_name: Yup.string('Last name').max(50, 'max 50 char allowed'),
  whatsapp_number: Yup.number()
    .required('Must be 10 digit')
    .max(9999999999, 'Must be a valid number')
    .min(6000000000, 'Must be a valid number'),
  corporate_email_id: Yup.string()
    .email('Must be a valid Email')
    .max(100, 'max 100 char allowed')
    .required('Email is required'),
  // toc: Yup.bool().oneOf([true], 'Plase check this.'),
});

const isValidName = value => {
  const expression = /^[a-z. ]*$/i;
  let valid = expression.test(value);
  let validStr = !value.includes('..') && !value.includes(' .');
  if (valid && validStr) {
    return true;
  }
  return false;
};

const OnboardingForm = ({ setPage, setErrorMessage, setType }) => {
  const [corporateLoading, setCorporateLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disableWhatsAppField, setDisableWhatsAppField] = useState(false);
  const [formState, setFormState] = useState({});
  const [otp, setOtp] = useState('');
  const [state, setState] = useState('');

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let onboardingPartnerId = '';
    let corporateName = '';
    let fullName = '';
    let corporateEmailId = '';
    let corporateId = null;
    let whatsapp_number = '';

    try {
      let data = atob(params.get('data'));
      data = JSON.parse(data);

      onboardingPartnerId = data['onboarding_partner_id'];
      corporateName = data['corporate_name'];
      fullName = data['full_name'];
      corporateEmailId = data['corporate_email_id'];

      if (data['whatsapp_number'] != null) {
        whatsapp_number = data['whatsapp_number'];
        setDisableWhatsAppField(true);
      }

      if (onboardingPartnerId == null || corporateName == null) {
        setErrorMessage('Please use correct URL');
        return;
      }

      const payload = {
        corporate_name: corporateName,
        onboarding_partner_id: onboardingPartnerId,
        created_by: onboardingPartnerId,
      };

      async function getCorporateId(payload) {
        const response = await onboardInstance.post(
          '/opd/partner/onboard/corporate',
          payload
        );
        return response.data;
      }

      setCorporateLoading(true);
      const resp = getCorporateId(payload);
      resp
        .then(response => {
          let data = response;

          if (data.status === 'success') {
            corporateId = data.response.corporate_id;
            corporateName = data.response.corporate_name;

            setFormState({
              corporate_id: corporateId,
              full_name: fullName,
              corporate_name: corporateName,
              whatsapp_number: whatsapp_number,
              corporate_email_id: corporateEmailId,
              // toc: true,
            });
          } else {
            setErrorMessage(data.message);
            setFormState({
              corporate_id: corporateId,
              full_name: fullName,
              corporate_name: corporateName,
              whatsapp_number: whatsapp_number,
              corporate_email_id: corporateEmailId,
              // toc: true,
            });
          }
        })
        .catch(err => {
          setFormState({
            corporate_id: corporateId,
            full_name: fullName,
            corporate_name: corporateName,
            whatsapp_number: whatsapp_number,
            corporate_email_id: corporateEmailId,
            // toc: true,
          });
          const errMsg = parseErrorMessage(err);
          setErrorMessage(errMsg);
        })
        .finally(() => {
          setCorporateLoading(false);
        });
    } catch (error) {
      setErrorMessage('Please use correct URL');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [counter, setCounter] = useState(30);
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const onResendOTP = () => {
    const params = JSON.parse(localStorage.getItem('payload'));
    const payload = {
      whatsapp_number: params['whatsapp_number'],
    };

    setState(vState.otp);
    setOtp('');

    // setIsLoading(true);
    onboardInstance
      .post('/opd/partner/employee/otp/resend', payload)
      .then(response => {
        // setIsLoading(false);
        let data = response.data;
        if (data.status === 'success') {
          const payload = { ...params, ...data.response };
          localStorage.setItem('payload', JSON.stringify(payload));
          setCounter(30);
          setType('success');
          setErrorMessage('OTP resent successfully on WhatsApp');
        } else {
          setErrorMessage(data.message);
        }
      })
      .catch(err => {
        // setIsLoading(false);
        const errMsg = parseErrorMessage(err);
        setErrorMessage(errMsg);
      });
  };

  const onSubmitOTP = otp => {
    const params = JSON.parse(localStorage.getItem('payload'));
    delete params['corporate_name'];
    const payload = {
      ...params,
      otp: otp.toString(),
    };

    setState(vState.otp);

    setIsLoading(true);
    onboardInstance
      .post('/opd/partner/employee/otp/validate', payload)
      .then(response => {
        setIsLoading(false);
        let data = response.data;
        if (data.status === 'success') {
          setState(vState.success);
        } else {
          // setState(vState.fail);
          setErrorMessage(data.response.message);
        }
        // console.log(response.data);
      })
      .catch(err => {
        setIsLoading(false);
        const errMsg = parseErrorMessage(err);
        setErrorMessage(errMsg);
      });
  };

  const onSubmit = values => {
    localStorage.clear();
    const params = new URLSearchParams(location.search);
    let data = null;
    let onboardingPartnerId = null;

    try {
      data = atob(params.get('data'));
      data = JSON.parse(data);
      onboardingPartnerId = data['onboarding_partner_id'];

      // console.log(onboardingPartnerId, values);
      if (onboardingPartnerId == null || values.corporate_id == null) {
        setErrorMessage('Please use correct URL');
        return;
      }
    } catch (error) {
      // console.log(error);
      setErrorMessage('Please use correct URL');
      return;
    }

    let payload = {
      ...data,
      corporate_id: values.corporate_id,
      full_name: values.full_name,
      corporate_email_id: values.corporate_email_id,
      whatsapp_number: values.whatsapp_number.toString(),
    };
    delete payload['corporate_name'];

    setIsLoading(true);

    onboardInstance
      .post(`/opd/partner/employee`, payload)
      .then(response => {
        let data = response.data;
        setIsLoading(false);

        if (data.status === 'success') {
          payload = {
            ...payload,
            ...data.response,
          };
          // console.log(payload);
          localStorage.setItem('payload', JSON.stringify(payload));
          setCounter(30);
          setState(vState.otp);
          setOtp('');
          setType('success');
          setErrorMessage('OTP sent successfully on WhatsApp');
        } else {
          setIsLoading(false);
          setErrorMessage(data.response.message);
        }
      })
      .catch(err => {
        setIsLoading(false);
        const errMsg = parseErrorMessage(err);
        setErrorMessage(errMsg);
      });
  };

  const successText = (
    <Box
      mt={10}
      display="flex"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
    >
      <Text textAlign="center" fontSize={['md', 'xl']}>
        Success!
      </Text>
      <Text textAlign="center" fontSize={['md', 'xl']}>
        Please check your WhatsApp to access Corporate Cashless Program.
      </Text>
      <Image
        mt={10}
        boxSize={['75px', '75px', '100px']}
        objectFit="cover"
        src={successLogo}
        alt="Success"
      />
    </Box>
  );

  const failText = (
    <Text color="red" textAlign="center" mb={5} width="75%">
      Otp verification failed, Please enter correct OTP.
    </Text>
  );

  const submitButton = (
    <Button
      mt={10}
      isLoading={isLoading}
      loadingText="submitting"
      backgroundColor="#0058ff"
      _hover={{
        backgroundColor: '#0058ff',
      }}
      _active={{
        backgroundColor: '#0058ff',
      }}
      color="white"
      onClick={() => onSubmitOTP(otp)}
    >
      Submit
    </Button>
  );

  const disableResend = counter > 0;

  const otpView = (
    <Box display="flex" flexDir="column" alignItems="center" mb={10}>
      {state === vState.fail && failText}
      {state !== vState.success && (
        <>
          <Box textAlign="end" width="98%">
            <Text color="black" size="sm" textAlign="left">
              Enter OTP sent on the WhatsApp
            </Text>
            <Input
              width="100%"
              type="number"
              value={otp}
              placeholder="OTP"
              onChange={e => {
                if (e.target.value.length <= 6) {
                  setOtp(e.target.value);
                }
              }}
            />
            {/* <OtpInput
              placeholder="000000"
              value={otp}
              onChange={otp => setOtp(otp)}
              numInputs={6}
              inputStyle={{
                width: '40px',
                height: '40px',
                border: '1px solid black',
                'border-radius': '5px',
                margin: '5px',
                'font-size': '20px',
              }}
            /> */}
            <VStack alignItems="flex-end">
              {/* <Text
                mr={1}
                as="button"
                onClick={() => {
                  setState('');
                }}
                color={'#0058ff'}
                fontSize="xs"
                textAlign="right"
              >
                Wrong number? Change
              </Text> */}
              <Text
                mr={1}
                as="button"
                onClick={() => {
                  if (!disableResend) {
                    onResendOTP();
                  }
                }}
                pointerEvents={disableResend ? 'none' : 'painted'}
                color={disableResend ? 'gray.500' : '#0058ff'}
                fontSize="xs"
                textAlign="right"
                disabled={disableResend}
              >
                Resend OTP {counter > 0 ? `in ${counter}` : ''}
              </Text>
            </VStack>
          </Box>

          {submitButton}
        </>
      )}
      {state === vState.success && successText}
    </Box>
  );

  const otpStates = [vState.otp, vState.fail];

  return (
    <Box width={['100%']} mt={['70px']}>
      {state !== vState.success && (
        <>
          <Box width={['90%']} margin={'auto'} mb={10}>
            <video
              width="100%"
              height="300px"
              controls
              src={
                'https://agreement-api-static-files.s3.ap-south-1.amazonaws.com/intro.mp4'
              }
            >
              Your browser does not support the video.
            </video>
          </Box>

          {otpStates.includes(state) && state !== '' && (
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              width="90%"
              margin="auto"
            >
              <Box as="button" onClick={() => setState('')}>
                <Tooltip label="Edit">
                  <EditIcon color="#0058ff" boxSize={['20px']} />
                </Tooltip>
              </Box>
            </Box>
          )}
          {!corporateLoading && (
            <Formik
              key={JSON.stringify(formState)}
              enableReinitialize={true}
              initialValues={formState}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {({ handleSubmit, values, errors, setFieldValue }) => (
                <Box
                  width="100%"
                  paddingX={6}
                  m="10px auto"
                  as="form"
                  onSubmit={handleSubmit}
                >
                  <InputControl
                    inputProps={{
                      type: 'text',
                      placeholder: 'Corporate Name',
                      isDisabled: true,
                      color: 'black',
                    }}
                    width={['100%']}
                    name="corporate_name"
                    minH={'60px'}
                    isRequired
                  />

                  <InputControl
                    inputProps={{
                      type: 'text',
                      placeholder: 'Full Name',
                    }}
                    onChange={e => {
                      const value = e.target.value;
                      const valid = isValidName(value);
                      if (valid) {
                        setFieldValue('full_name', value);
                      } else {
                        setFieldValue('full_name', values.full_name);
                      }
                    }}
                    isDisabled={state !== ''}
                    width={['100%']}
                    name="full_name"
                    // label="Full Name"
                    minH={'60px'}
                    isRequired
                  />

                  <InputControl
                    inputProps={{
                      type: 'email',
                      placeholder: 'Corporate Email id',
                    }}
                    isDisabled={state !== ''}
                    width={['100%']}
                    name="corporate_email_id"
                    // label="Corporate Email id"
                    minH={'60px'}
                    isRequired
                  />

                  <InputControl
                    inputProps={{
                      type: 'number',
                      placeholder: 'Whatsapp No',
                    }}
                    onChange={e => {
                      const value = e.target.value;
                      if (value.length <= 10) {
                        setFieldValue('whatsapp_number', value);
                      } else {
                        setFieldValue('whatsapp_number', value.slice(0, -1));
                      }
                    }}
                    isDisabled={state !== '' || disableWhatsAppField}
                    width={['100%']}
                    name="whatsapp_number"
                    // label="Whatsapp No"
                    minH={'60px'}
                    isRequired
                  />

                  {state === '' ? (
                    <>
                      {/* <CheckboxControl name="toc">
                      I give my consent for sharing my information.
                    </CheckboxControl> */}

                      <Box fontSize="sm" textAlign="center">
                        By proceeding, you agree to our
                        <span
                          onClick={() => {
                            window
                              .open(
                                'https://www.medpay.in/termsofservice.html',
                                '_blank'
                              )
                              .focus();
                          }}
                          style={{
                            cursor: 'pointer',
                            color: '#0058ff',
                            textDecoration: 'underline',
                          }}
                        >
                          {' '}
                          Terms of Service.
                        </span>
                      </Box>

                      <Box display="flex" justifyContent="center" my={20}>
                        <SubmitButton
                          isLoading={isLoading}
                          loadingText="Generating"
                          backgroundColor="#0058ff"
                          _hover={{
                            backgroundColor: '#0058ff',
                          }}
                          _active={{
                            backgroundColor: '#0058ff',
                          }}
                        >
                          Generate OTP
                        </SubmitButton>
                      </Box>
                    </>
                  ) : null}
                </Box>
              )}
            </Formik>
          )}

          {corporateLoading && <Loader />}
        </>
      )}
      <Box width={['90%']} margin={'auto'}>
        {otpStates.includes(state) && state !== '' && otpView}
      </Box>

      {state === vState.success && successText}
    </Box>
  );
};

export default OnboardingForm;
