import React, { useEffect, useState } from 'react';
import { Box, Button, Text, Image } from '@chakra-ui/react';
import OtpInput from 'react-otp-input';

import { state as vState } from './../../../utils';
import successLogo from './../../../../assets/images/onboard-success.png';
import { instance } from '../../../api';

const OTPView = ({ setErrorMessage, setType }) => {
  const [otp, setOtp] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState(vState.otp);

  const [counter, setCounter] = useState(59);
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const onResendOTP = () => {
    const params = JSON.parse(localStorage.getItem('payload'));
    const payload = {
      whatsapp_number: params['whatsapp_number'],
    };

    setState(vState.otp);
    setOtp('');

    // setIsLoading(true);
    instance
      .post('/opd/partner/employee/otp/resend', payload)
      .then(response => {
        // setIsLoading(false);
        let data = response.data;
        if (data.status === 'success') {
          setCounter(59);
          setType('success');
          setErrorMessage('OTP sent successfully');
        } else {
          setErrorMessage(data.message);
        }
      })
      .catch(error => {
        // setIsLoading(false);
        setErrorMessage('Something went wrong');
      });
  };

  const onSubmitOTP = otp => {
    const params = JSON.parse(localStorage.getItem('payload'));
    const payload = {
      whatsapp_number: params['whatsapp_number'],
      otp: otp.toString(),
    };

    setState(vState.otp);

    setIsLoading(true);
    instance
      .post('/opd/partner/employee/otp/validate', payload)
      .then(response => {
        setIsLoading(false);
        let data = response.data;
        if (data.status === 'success') {
          setState(vState.success);
        } else {
          setState(vState.fail);
        }
        // console.log(response.data);
      })
      .catch(error => {
        setIsLoading(false);
        setErrorMessage('Something went wrong');
      });
    // setTimeout(() => {
    //   setState(vState.success);
    //   setIsLoading(false);
    // }, 2000);
  };

  const submitButton = (
    <Button
      mt={10}
      isLoading={isLoading}
      loadingText="submitting"
      backgroundColor="#0058ff"
      _hover={{
        backgroundColor: '#0058ff',
      }}
      _active={{
        backgroundColor: '#0058ff',
      }}
      color="white"
      onClick={() => onSubmitOTP(otp)}
    >
      Submit
    </Button>
  );

  const successText = (
    <Box
      mt={10}
      display="flex"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
    >
      <Text textAlign="center" fontSize={['md', 'xl']}>
        Successfully authenticated, we will start onboarding now.
      </Text>
      <Image
        mt={10}
        boxSize={['75px', '75px', '100px']}
        objectFit="cover"
        src={successLogo}
        alt="Success"
      />
    </Box>
  );

  const failText = (
    <Text color="red" textAlign="center" mb={5} width="75%">
      Otp verification failed, Please enter correct OTP.
    </Text>
  );

  const disableResend = counter > 0;

  return (
    <Box width={['100%']} mt={['70px']}>
      <Box display="flex" flexDir="column" alignItems="center">
        {state === vState.fail && failText}
        {state !== vState.success && (
          <>
            <Box textAlign="end">
              <OtpInput
                placeholder="000000"
                value={otp}
                onChange={otp => setOtp(otp)}
                numInputs={6}
                inputStyle={{
                  width: '40px',
                  height: '40px',
                  border: '1px solid black',
                  'border-radius': '5px',
                  margin: '5px',
                  'font-size': '20px',
                }}
              />
              <Text
                mr={1}
                as="button"
                onClick={() => {
                  if (!disableResend) {
                    onResendOTP();
                  }
                }}
                pointerEvents={disableResend ? 'none' : 'painted'}
                color={disableResend ? 'gray.500' : '#0058ff'}
                fontSize="xs"
                textAlign="right"
                disabled={disableResend}
              >
                Resend OTP {counter > 0 ? `in ${counter}` : ''}
              </Text>
            </Box>

            {submitButton}
          </>
        )}
        {state === vState.success && successText}
      </Box>
    </Box>
  );
};

export default OTPView;
