import React from 'react';
import { Box, Text, HStack, Stack, Button, Image } from '@chakra-ui/react';

import { QRIconNew, TimeIcon, LocationIcon } from '../../../../assets/icons';

import MedPayLogoBlack from '../../../../assets/images/medpayLogoBlack.png';
import { CheckCircleIcon } from '@chakra-ui/icons';

const CheckIn = ({
  handleCancel,
  handleCheckIn,
  cancelLoading,
  checkInLoading,
  data,
}) => {
  return (
    <Box>
      {data.authorization_type && (
        <>
          <Image src={MedPayLogoBlack} width="40%" marginLeft="30%" pt={2} />
          <Box
            display="flex"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            my={10}
            mx={12}
          >
            {data.authorization_type === 'postpaid' && (
              <QRIconNew width={['50px']} height={['50px']} />
            )}
            <Text
              color="#000"
              fontSize="18px"
              fontWeight="500"
              fontFamily="'Rubik', sans-serif"
              textAlign="center"
            >
              {data.authorization_type === 'postpaid'
                ? 'Scan any Payment QR to complete Check-in'
                : 'Please Click on Check-in Below'}
            </Text>
          </Box>

          <Box
            bgColor="white"
            borderRadius={7}
            style={{ boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)' }}
            width="90%"
            marginLeft="5%"
            marginRight="5%"
          >
            <Stack p={5} fontSize={['14px']} fontWeight="normal">
              <Box
                display="flex"
                justifyContent="center"
                flexDir="column"
                alignItems="center"
              >
                <Box
                  style={{
                    border: '1px solid #0058FF',
                    padding: '5% 20%',
                    borderRadius: '9px',
                    marginBottom: '40px',
                  }}
                >
                  <HStack mb={2}>
                    <CheckCircleIcon
                      color="#0058ff"
                      width={['18px']}
                      height={['18px']}
                    />
                    <Text fontFamily="'Inter', sans-serif" fontWeight="500">
                      Check-in benefits:
                    </Text>
                  </HStack>
                  <Text fontFamily="'Inter', sans-serif" fontWeight="400">
                    1. Less wait time
                  </Text>
                  <Text fontFamily="'Inter', sans-serif" fontWeight="400">
                    2. Cashless Transaction
                  </Text>
                </Box>
                <HStack textAlign="left" width="100%" alignItems="start" my={1}>
                  <LocationIcon width={['20px']} height={['20px']} />
                  <Text
                    width="40%"
                    fontWeight="500"
                    fontFamily="'Rubik', sans-serif"
                  >
                    Address
                  </Text>
                  <Text
                    width="60%"
                    fontWeight="400"
                    color="#000000"
                    className="two-line-wrap"
                    fontFamily="'Inter', sans-serif"
                  >
                    {data.provider_name} <br />
                    {data.provider_address?.length > 60
                      ? data.provider_address.substr(0, 60) + ' ...'
                      : data.provider_address}
                  </Text>
                </HStack>

                <HStack textAlign="left" width="100%" alignItems="start" my={1}>
                  <TimeIcon width={['20px']} height={['20px']} />
                  <Text
                    fontWeight="500"
                    fontFamily="'Rubik', sans-serif"
                    width="40%"
                  >
                    Time of Visit
                  </Text>
                  <Text
                    width="60%"
                    fontWeight="400"
                    color="#000000"
                    className="two-line-wrap"
                    fontFamily="'Inter', sans-serif"
                  >
                    {data.appointment_time}
                  </Text>
                </HStack>

                <HStack
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  my={2}
                  mt={5}
                >
                  {/* <Button
                    isLoading={cancelLoading}
                    loadingText="cancelling"
                    color="black"
                    borderWidth={1.5}
                    borderColor="#0059ff"
                    variant="outline"
                    size="xs"
                    onClick={handleCancel}
                  >
                    <Text
                      fontWeight="normal"
                      color="#3C3732"
                      fontSize={['11px']}
                    >
                      Cancel
                    </Text>
                  </Button> */}

                  <Button
                    isLoading={checkInLoading}
                    loadingText="checking-in"
                    onClick={handleCheckIn}
                    _active={{
                      bgColor: '#0059ff',
                      color: 'white',
                    }}
                    _hover={{
                      bgColor: '#0059ff',
                      color: 'white',
                    }}
                    bgColor="#0059ff"
                    color="white"
                    variant="solid"
                    size="xs"
                    style={{
                      borderRadius: '2px',
                      boxShadow: '0px 0px 4px -1px #0058FF',
                      padding: '16px 40px',
                      marginTop: '30px',
                    }}
                  >
                    <Text
                      fontWeight="500"
                      fontFamily="'Rubik', sans-serif"
                      fontSize={['14px']}
                    >
                      {data.authorization_type === 'postpaid'
                        ? 'Scan'
                        : 'Check-in'}
                    </Text>
                  </Button>
                </HStack>
              </Box>
            </Stack>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CheckIn;
