import React, { useEffect } from 'react';
import { useLocation, useHistory, withRouter } from 'react-router';
import ReactGA from 'react-ga';

import { storeHelper, retrieveHelper, isValidPathMove, pc } from '../utils';
import useAlertHook from '../scan/hooks/errorHook';
import AlertModal from '../scan/subComponents/alertModal/alertModal';

const Wrapper = props => {
  const { errorMessage, showAlertModal, setErrorMessage, setShowAlertModal } =
    useAlertHook();

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    let currentPath = location.pathname;
    let prevPath = retrieveHelper(pc.prevPath);
    if (prevPath == null) {
      storeHelper(pc.prevPath, '/');
      prevPath = '/';
    }
    // console.log(currentPath, prevPath);
    let response = isValidPathMove(currentPath, prevPath);

    if (response.result) {
      storeHelper(pc.prevPath, currentPath);
    } else {
      // console.log('in error message');
      // console.log(response);
      if(currentPath.includes('inApp')){
        return;
      }
      setErrorMessage(response.message);
      storeHelper(pc.prevPath, '/');
      let employeeId = retrieveHelper(pc.employeeId);
      if (employeeId == null) {
        history.push('/');
      } else {
        history.push(`/?id=${employeeId}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  return (
    <>
      {props.children}
      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          onClose={() => {
            setErrorMessage('');
            setShowAlertModal(false);
          }}
          message={errorMessage}
        />
      )}
    </>
  );
};

export default withRouter(Wrapper);
