import React, { useState, useEffect } from 'react';
import { Box, Text, Input, Button } from '@chakra-ui/react';
import PaymentOptions from './../../scanUI/paymentOptions';
import { instance } from '../../../api';
import {
  storeHelper,
  pc,
  parseErrorMessage,
  registerEvent,
  ec,
  actions,
} from '../../../utils';
import useAlertHook from '../../hooks/errorHook';
import { useHistory } from 'react-router-dom';
import AlertModal from '../../subComponents/alertModal/alertModal';
import {
  primaryColor,
  primaryText,
  px13,
  px16,
  secondryText,
} from './../../constants';

const ManualPay = () => {
  const [upiID, setUpiID] = useState('');
  const [selectedMethod, setSelectedMethod] = useState({
    name: 'Paytm',
    image: 'paytmLogo',
  });
  const [isLoading, setIsLoading] = useState(false);

  const { errorMessage, setErrorMessage, showAlertModal, setShowAlertModal } =
    useAlertHook();

  const history = useHistory();

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  const handleValidateCode = () => {
    const payload = {
      payment_number: upiID,
      payment_partner: selectedMethod.name,
    };

    registerEvent(ec.Pay, actions.MANUAL_PAYMENT_METHOD_VALIDATE_INIT);

    setIsLoading(true);

    instance
      .post('/opd/payment/qr/validate', payload)
      .then(response => {
        setIsLoading(false);
        let data = response.data;
        if (data.status === 'success') {
          registerEvent(ec.Pay, actions.MANUAL_PAYMENT_METHOD_VALIDATE_SUCCESS);
          storeHelper(pc.paymentType, 'manual');
          if (data.response.provider_account_name == null) {
            storeHelper(pc.qrName, '');
          } else {
            storeHelper(pc.qrName, data.response.provider_account_name);
          }
          storeHelper(pc.qrCode, data.response.payment_upi_id);
          history.push('/transaction');
        } else {
          setErrorMessage(data.message);
        }
      })
      .catch(err => {
        setIsLoading(false);
        const errMsg = parseErrorMessage(err);
        setErrorMessage(errMsg);
      });
  };

  return (
    <>
      <Box
        display="flex"
        flexDir="column"
        justifyContent="space-between"
        className="containerHeight"
      >
        <Box
          width="90%"
          margin="auto"
          mt={5}
          p={5}
          bgColor="white"
          boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
          borderRadius="5px"
        >
          <Text
            fontSize={px16}
            textAlign="center"
            fontWeight="bold"
            color={primaryText}
          >
            Pay using mobile number
          </Text>
          <Box mt={5}>
            <Text fontSize={px13} mb={2} color={secondryText}>
              Enter phone Number
            </Text>
            <Input
              width="100%"
              height={['36px']}
              type="number"
              autoFocus={true}
              fontSize={px16}
              value={upiID}
              onChange={event => {
                if (event.target.value.toString().length <= 10) {
                  setUpiID(event.target.value);
                }
              }}
              bgColor="white"
            />
          </Box>
        </Box>

        <Box py={5} flex={1} overflowY="scroll" width="100%">
          {/* <Text
            textAlign="center"
            color={secondryText}
            fontSize={px13}
            pb={2}
          >
            Select provider
          </Text> */}

          <Box>
            <PaymentOptions
              upiID={upiID}
              selectedMethod={selectedMethod}
              setSelectedMethod={setSelectedMethod}
              isLoading={isLoading}
            />
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDir="column"
          width="90%"
          margin="auto"
          mb="3vh"
        >
          <Button
            isFullWidth
            _active={{
              bgColor: { primaryColor },
              color: 'white',
            }}
            _hover={{
              bgColor: { primaryColor },
              color: 'white',
            }}
            bgColor={primaryColor}
            height={['40px']}
            color="white"
            variant="solid"
            disabled={upiID.trim().length !== 10 || selectedMethod === ''}
            onClick={() => {
              handleValidateCode();
            }}
            isLoading={isLoading}
          >
            <Text fontSize={px13} fontWeight="bold">
              Next
            </Text>
          </Button>

          <Box
            as="button"
            height={['40px']}
            fontSize={px13}
            onClick={() => {
              history.goBack();
            }}
          >
            <Text color={primaryColor}>Go Back</Text>
          </Box>
        </Box>
      </Box>

      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          onClose={() => {
            setShowAlertModal(false);
            setErrorMessage('');
          }}
          message={errorMessage}
        />
      )}
    </>
  );
};

export default ManualPay;
