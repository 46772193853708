import React, { useEffect, useState } from 'react';
import { Box, Text, Stack, HStack, Image } from '@chakra-ui/react';

import { CheckInSuccessIcon } from '../../../../assets/icons';

import MedPayLogoWhite from '../../../../assets/images/medpayLogo.png';

import { checkInInstance } from '../../../api';

const Success = ({ data, checkInId }) => {
  const [orderData, setOrderData] = useState({});
  useEffect(() => {
    checkInInstance
      .get(`/scan-auth/authorization/status/${checkInId}`)
      .then(response => {
        if (response.data.status === 'success') {
          setOrderData(response.data.response);
        }
      })
      .catch(err => {
        console.log('something went wrong');
      });
  }, [checkInId]);
  return (
    <Box
      height="100vh"
      display="flex"
      flexDir="column"
      justifyContent="flex-start"
      alignItems="center"
      bgColor="#0058ff"
    >
      {data && (
        <>
          <Image src={MedPayLogoWhite} width="40%" pt={2} />
          <Box textAlign="center" mt={10}>
            <CheckInSuccessIcon width={['80px']} height={['80px']} />
            <Text
              color="#FFF"
              fontSize="24px"
              fontWeight="700"
              fontFamily="'Mulish', sans-serif"
            >
              Check-in Successful
            </Text>
          </Box>

          <Box
            width="100%"
            mt={10}
            // margin="auto"
            // bgColor="white"
            // borderRadius={10}
          >
            <Stack p={5} fontSize={['14px']} fontWeight="normal">
              <Box
                display="flex"
                justifyContent="center"
                flexDir="column"
                alignItems="center"
              >
                <HStack textAlign="left" width="100%" alignItems="start" my={1}>
                  <Text color="#fff" width="40%">
                    Patient Name
                  </Text>
                  <Text
                    width="60%"
                    fontWeight="400"
                    color="#fff"
                    className="two-line-wrap"
                    fontFamily="'Rubik', sans-serif"
                  >
                    {data.patient_name}
                  </Text>
                </HStack>

                <HStack textAlign="left" width="100%" alignItems="start" my={1}>
                  <Text color="#fff" width="40%">
                    Appointment With
                  </Text>
                  <Text
                    width="60%"
                    fontWeight="400"
                    color="#fff"
                    className="two-line-wrap"
                    fontFamily="'Rubik', sans-serif"
                  >
                    {data.provider_name}
                  </Text>
                </HStack>

                <HStack textAlign="left" width="100%" alignItems="start" my={1}>
                  <Text color="#fff" width="40%">
                    Appointment Time
                  </Text>
                  <Text
                    width="60%"
                    fontWeight="400"
                    color="#fff"
                    className="two-line-wrap"
                    fontFamily="'Rubik', sans-serif"
                  >
                    {data.appointment_time}
                  </Text>
                </HStack>
              </Box>
            </Stack>
          </Box>

          {Object.keys(orderData).length !== 0 && (
            <Box
              mt={10}
              style={{
                padding: '20px',
                borderRadius: '8px',
                border: '1px solid #fff',
                width: '90%',
              }}
            >
              <Text
                fontWeight="700"
                fontFamily="'Rubik', sans-serif"
                color="#fff"
              >
                Please show this at the Front Desk{' '}
              </Text>
              {orderData.paid_to_upi && (
                <Stack mt={4}>
                  <Text
                    fontWeight="500"
                    fontFamily="'Rubik', sans-serif"
                    color="#fff"
                  >
                    Paid To:{' '}
                    {orderData.paid_to_name !== ''
                      ? orderData.paid_to_name
                      : data.provider_name}
                  </Text>
                  <Text
                    fontWeight="400"
                    fontFamily="'Mulish', sans-serif"
                    color="#fff"
                    fontSize="14px"
                    mt={0}
                  >
                    {orderData.paid_to_upi}
                  </Text>
                </Stack>
              )}

              {orderData.bank_reference && (
                <Stack mt={4}>
                  <Text
                    fontWeight="500"
                    fontFamily="'Rubik', sans-serif"
                    color="#fff"
                  >
                    Bank Reference
                  </Text>
                  <Text
                    fontWeight="400"
                    fontFamily="'Mulish', sans-serif"
                    color="#fff"
                    fontSize="14px"
                    mt={0}
                  >
                    {orderData.bank_reference}
                  </Text>
                </Stack>
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Success;
