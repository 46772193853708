import React from 'react';
import {
  Box,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Image,
} from '@chakra-ui/react';

import { primaryText, primaryColor, px14, px13 } from '../../constants';
import errorNewLogo from './../../../../assets/images/errorNewLogo.png';
import { useHistory } from 'react-router-dom';

const AlertModal = ({ isOpen, onClose, message, link = '' }) => {
  const history = useHistory();

  const buttonText = message => {
    if (message.includes('upload last')) {
      return 'Upload';
    } else if (message.includes('clear the dues')) {
      return 'Pay';
    } else if (message.includes('associating this device')) {
      return 'Reload';
    } else {
      return 'Okay';
    }
  };

  const buttonAction = message => {
    if(link.includes('inAppTransaction')) {
      return window.open(link, '_self');
    }
    if (message.includes('upload last')) {
      return history.push('/upload');
    } else if (message.includes('clear the dues')) {
      return window.open(link, '_blank').focus();
    } else if (message.includes('associating this device')) {
      return window.location.reload();
    } else {
      return () => {};
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent width={300}>
        <ModalBody>
          <Box p={5}>
            <Box display="flex" justifyContent="center">
              <Image
                boxSize="55px"
                objectFit="cover"
                src={errorNewLogo}
                alt="Error"
              />
            </Box>
            <Text
              textAlign="center"
              fontSize={px14}
              mt={8}
              fontWeight="bold"
              color={primaryText}
            >
              {message}
            </Text>

            <Box display="flex" flexDir="column" justifyContent="center" mt={5}>
              <Button
                color={primaryColor}
                border="2px"
                borderColor={primaryColor}
                onClick={() => {
                  buttonAction(message);
                  onClose();
                }}
                size="sm"
                height={['40px']}
                variant="outline"
              >
                <Text fontSize={px13}>{buttonText(message)}</Text>
              </Button>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AlertModal;
