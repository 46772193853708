import React, { useRef } from 'react';
import Webcam from 'react-webcam';
import { Box, HStack, Text, Button } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

import { px13, px14, tealGreenColor } from '../../constants';
import { registerEvent, ec, actions } from '../../../utils';
import { compressFile } from './utils';

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'environment',
};

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const CaptureView = ({ capturedImages, setCapturedImages, setState }) => {
  const isSmallScreen = window.screen.width < 325;
  const webcamRef = useRef(null);

  const captureImage = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const compressedImage = await compressFile(imageSrc);
    setCapturedImages([...capturedImages, compressedImage]);
    setState('upload');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webcamRef]);

  const handleUploadFromGallery = async files => {
    let tempImages = [];

    for (const file of files) {
      let base64File = await toBase64(file);
      if (file.type === 'application/pdf') {
        tempImages.push(base64File);
      } else {
        const compressedImage = await compressFile(base64File);
        tempImages.push(compressedImage);
      }
    }

    setCapturedImages([...capturedImages, ...tempImages]);
    setState('upload');
  };

  return (
    <Box bgColor="rgb(0,0,0,0.7)" className="containerHeight" overflow="scroll">
      <Box
        display="flex"
        flexDir="column"
        justifyContent="space-between"
        alignItems="center"
        height="100%"
        width="100%"
        margin="auto"
      >
        <Box
          display="flex"
          flexDir="column"
          justifyContent="flex-start"
          alignItems="center"
          height={isSmallScreen ? '75vh' : '85vh'}
          overflow="hidden"
        >
          <HStack
            width="100%"
            px={'5%'}
            mb={2}
            height="40px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            bgColor="blackAlpha.500"
            padding={0}
          >
            <Button
              leftIcon={<ArrowBackIcon />}
              colorScheme="#000"
              variant="solid"
              onClick={() => setState('upload')}
              visibility={capturedImages.length > 0 ? 'visible' : 'hidden'}
              fontSize="14px"
            >
              Back
            </Button>
            <Text
              fontSize={px14}
              className="poppins fw-600"
              color="white"
              my={3}
              width="100%"
              textAlign="center"
            >
              Upload Prescription and Bills
            </Text>
            <Button
              disabled
              leftIcon={<ArrowBackIcon />}
              colorScheme="#000"
              variant="solid"
              onClick={() => setState('upload')}
              visibility="hidden"
              fontSize="14px"
            >
              Back
            </Button>
          </HStack>

          <Box display="flex" justifyContent="center" alignItems="center">
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width="90%"
              screenshotQuality={1}
              videoConstraints={videoConstraints}
              forceScreenshotSourceSize={true}
            />
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          flexDir="column"
          width="90%"
          mb="3vh"
          mt={2}
        >
          <Button
            isFullWidth
            minH={['40px']}
            _active={{
              bgColor: { tealGreenColor },
              color: 'white',
            }}
            _hover={{
              bgColor: { tealGreenColor },
              color: 'white',
            }}
            bgColor={tealGreenColor}
            color="white"
            variant="solid"
            size="md"
            onClick={captureImage}
          >
            <Text className="poppins fw-600" fontSize={px13}>
              Capture
            </Text>
          </Button>

          <Box
            as="button"
            height={['40px']}
            fontSize={px13}
            onClick={() => {
              document.getElementById('uploadGallery').click();
            }}
          >
            <input
              type="file"
              id="uploadGallery"
              name="bill"
              multiple
              accept="image/png, image/jpeg, application/pdf"
              style={{ display: 'none' }}
              onChange={e => {
                registerEvent(ec.InAppUpload, actions.BILL_SELECT_FROM_GALLERY);
                handleUploadFromGallery(e.target.files);
              }}
            />
            <Text className="poppins fw-500" color="white">
              Upload from Gallery
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CaptureView;
