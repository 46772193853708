import React from 'react';

import { Box, Text, Image, Stack, Button } from '@chakra-ui/react';

import errorImage from './../../assets/images/errorNewLogo.png';
import { primaryText, primaryColor, px16, px12, px13 } from '../scan/constants';

const FallbackCheckIn = () => {
  return (
    <Box className="containerHeight">
      <Box
        display="flex"
        flexDir="column"
        flex={1}
        mt={10}
        height="100%"
        width="90%"
        margin="auto"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          width="100%"
          mt={5}
          p={5}
          bgColor="white"
          borderRadius="5px"
          boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
        >
          <Box
            textAlign="center"
            display="flex"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              boxSize={['85px']}
              objectFit="cover"
              src={errorImage}
              alt="error"
            />
          </Box>

          <Stack color={primaryText} textAlign="center">
            <Stack spacing={0} mt={5} fontSize={px16} fontWeight="bold">
              <Text>This was unexpected.</Text>
            </Stack>
            <Stack spacing={0} pt={5} fontSize={px12}>
              <Text>Rest assured we are working on it.</Text>
            </Stack>
          </Stack>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          flexDir="column"
          width="100%"
          mb="2vh"
        >
          <Button
            isFullWidth
            minH={['40px']}
            _active={{
              bgColor: { primaryColor },
              color: 'white',
            }}
            _hover={{
              bgColor: { primaryColor },
              color: 'white',
            }}
            bgColor={primaryColor}
            color="white"
            variant="solid"
            size="md"
            onClick={() => window.location.reload()}
          >
            <Text fontSize={px13}>Try Again</Text>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FallbackCheckIn;
