import React from 'react';
import {
  Box,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Image,
} from '@chakra-ui/react';

import errorNewLogo from './../../../../assets/images/errorNewLogo.png';
import successLogo from './../../../../assets/images/success.png';

const AlertModal = ({ isOpen, onClose, message, type = 'fail' }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent width={300}>
        <ModalBody>
          <Box p={5}>
            <Box
              display="flex"
              justifyContent="center"
              mr={type === 'fail' ? '0%' : '3.5%'}
            >
              <Image
                boxSize="55px"
                objectFit="cover"
                src={type === 'fail' ? errorNewLogo : successLogo}
                alt="Error"
              />
            </Box>
            <Text textAlign="center" mt={8} fontWeight="bold">
              {message}
            </Text>

            <Box display="flex" flexDir="column" justifyContent="center" mt={5}>
              <Button
                border="2px"
                borderColor={'#0058ff'}
                onClick={() => {
                  onClose();
                }}
                size="sm"
                height={['40px']}
                variant="outline"
              >
                <Text>Okay</Text>
              </Button>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AlertModal;
