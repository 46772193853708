import React, { useEffect, useState } from 'react';
import { Box, VStack, Text, Input } from '@chakra-ui/react';
import { useHistory } from 'react-router';

import { retrieveHelper, pc, getTypeIcon } from '../../../utils';
import {
  primaryColor,
  primaryText,
  px12,
  px13,
  px14,
  px16,
  secondryText,
} from '../../constants';
import { BackButton } from '../../subComponents/button';

const TransactionForm = ({ amount, setAmount, blurBackground, isLoading }) => {
  // const [data, setData] = useState({ merchant_type: '' });
  // const [paymentCode, setPaymentCode] = useState('');
  const [qrName, setQrName] = useState('');
  const [balance, setBalance] = useState('');

  const history = useHistory();
  // const storedClinic = retrieveHelper(pc.clinic);
  const qrCode = retrieveHelper(pc.qrCode);

  const isSmallScreen = window.screen.width < 325;

  useEffect(() => {
    const balance = retrieveHelper(pc.balance);
    const qrName = retrieveHelper(pc.qrName);

    if (
      // storedClinic == null ||
      qrCode == null ||
      balance == null ||
      balance.trim() === ''
    ) {
      const employeeId = retrieveHelper(pc.employeeId);
      history.push(`/?id=${employeeId}`);
    } else {
      // setData(storedClinic);
      // setPaymentCode(JSON.parse(qrCode));
      setQrName(qrName);
      setBalance(balance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <Box width="100%">
      <VStack>
        <Box
          display="flex"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            width={['75px']}
            height={['75px']}
            borderRadius={['7px']}
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor="#E6EFFF"
          >
            {getTypeIcon('others', '40px')}
          </Box>
          <Text
            fontSize={px16}
            my={2}
            fontWeight="bold"
            textAlign="center"
            color={primaryText}
          >
            Paying to - {qrName}
          </Text>

          <Text fontSize={px12} color={secondryText} mb={3}>
            Beneficiary name as per UPI ID
          </Text>
        </Box>

        <Box width="50%">
          <Input
            disabled={isLoading}
            textAlign="center"
            height={['36px']}
            color={secondryText}
            type="number"
            autoFocus={true}
            min={0}
            step={1}
            value={amount}
            pattern="[0-9]"
            onChange={event => {
              if (
                Number(event.target.value) <= Number(balance) &&
                Number(event.target.value >= 0)
              ) {
                if (
                  Number(event.target.value) === 0 &&
                  event.target.value.length > 0
                ) {
                  // console.log(Number(event.target.value) === 0);
                  setAmount('0');
                } else {
                  setAmount(Number.parseInt(event.target.value));
                }
              }
            }}
            placeholder="Enter Amount"
            fontSize={isSmallScreen ? px14 : px16}
            fontWeight="bold"
          />
        </Box>

        <Box>
          {Number(amount) === 0 ? null : (
            <Box
              width={['100%']}
              textAlign="center"
              fontSize={px12}
              color={secondryText}
            >
              {`Available OPD Benefits: `}
              <span style={{ color: primaryColor }}>
                ₹{Math.round(balance).toString()}{' '}
              </span>
            </Box>
          )}
        </Box>

        <Text fontSize={px13} fontWeight="bold" color={primaryText}>
          ISO 27001 Certified
        </Text>

        {!isLoading && (
          <BackButton
            color={primaryColor}
            onClick={() => history.goBack()}
            disable={blurBackground}
          />
        )}
      </VStack>
    </Box>
  );
};

export default TransactionForm;
