import React, { useEffect, useState } from 'react';
import { Box, Image } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import {
  storeHelper,
  retrieveHelper,
  pc,
  comparator,
  parseErrorMessage,
  registerEvent,
  ec,
  actions,
} from '../../../utils';
import { instance } from '../../../api';
import useAlertHook from '../../hooks/errorHook';
import SearchUI from '../../scanUI/homeUI/searchUI';
import Filters from '../../scanUI/homeUI/filters';
import AlertModal from '../../subComponents/alertModal/alertModal';
import ListingCards from '../../scanUI/homeUI/listingsUI';
import OverlayLoader from '../../subComponents/overlayLoader';
import whiteBackImage from './../../../../assets/images/whiteBack.png';

const Listings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [progressMessage, setprogressMessage] = useState('');
  const [subTypes, setSubTypes] = useState([]);
  const [selectedSubType, setSelectedSubType] = useState('');
  const [topRated, setTopRated] = useState(false);
  const [data, setData] = useState([]);
  const [sortedData, setSortedData] = useState(false);
  const [input, setInput] = useState('');
  const [inputSearch, setInputSearch] = useState(false);

  const { errorMessage, setErrorMessage, showAlertModal, setShowAlertModal } =
    useAlertHook();

  const history = useHistory();

  const getSubTypes = type => {
    const searchQuery = retrieveHelper(pc.searchQuery);

    if (searchQuery.keyword === 'dentist') {
      setSubTypes(['Dentist']);
    } else {
      const location = retrieveHelper(pc.location);
      setIsLoading(true);
      setprogressMessage('Fetching categories');
      registerEvent(
        ec.Listing,
        `/opd/network/autocomplete?coordinates=${location}&type=${type}`
      );
      instance
        .get(`/opd/network/autocomplete?coordinates=${location}&type=${type}`)
        .then(response => {
          // setIsLoading(false);
          let data = response.data;
          if (data.status === 'success') {
            setSubTypes([...data.response.keyword]);
          } else {
            setSubTypes([]);
            setErrorMessage(data.message);
          }
        })
        .catch(err => {
          setIsLoading(false);
          const errMsg = parseErrorMessage(err);
          setErrorMessage(errMsg);
        });
    }
  };

  const getProviders = (type, subtype, querytype = 'distance') => {
    const location = retrieveHelper(pc.location);
    if (location == null) {
      setErrorMessage('Location not available');
      return;
    }
    storeHelper(pc.searchProvider, false);
    setInputSearch(false);

    setIsLoading(true);
    setprogressMessage('Fetching providers');
    registerEvent(
      ec.Listing,
      `/opd/network/providers?coordinates=${location}&type=${type}&keyword=${subtype}`
    );
    instance
      .get(
        `/opd/network/providers?coordinates=${location}&type=${type}&keyword=${subtype}`
      )
      .then(response => {
        setIsLoading(false);
        let data = response.data;
        if (data.status === 'success') {
          const providers = [...data.response];
          setData([...providers]);
          const sortedProviders = providers.sort(comparator);
          setSortedData([...sortedProviders]);

          // console.log(providers);

          const searchQuery = {
            type: type,
            keyword: subtype,
            location: location,
            time: new Date(),
          };
          storeHelper(pc.searchQuery, searchQuery);
        } else {
          setErrorMessage(data.message);
        }
      })
      .catch(err => {
        setIsLoading(false);
        const errMsg = parseErrorMessage(err);
        setErrorMessage(errMsg);
      });
  };

  // useEffect(() => {
  //   if (subTypes.length > 0) {
  //     const searchQuery = retrieveHelper(pc.searchQuery);

  //     getProviders(
  //       searchQuery.type,
  //       searchQuery.keyword,
  //       topRated ? 'prominance' : 'distance'
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [topRated]);

  useEffect(() => {
    const searchQuery = retrieveHelper(pc.searchQuery);

    if (searchQuery != null && searchQuery.keyword) {
      setSelectedSubType(searchQuery.keyword);
    }

    if (subTypes.length > 0) {
      getProviders(
        searchQuery.type,
        searchQuery.keyword,
        topRated ? 'prominance' : 'distance'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTypes]);

  useEffect(() => {
    const searchQuery = retrieveHelper(pc.searchQuery);

    if (searchQuery != null) {
      getSubTypes(searchQuery.type);
    } else {
      const employeeId = retrieveHelper(pc.employeeId);
      history.push(`/?id=${employeeId}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  return (
    <>
      <Box
        pt={5}
        className={isLoading ? 'containerHeight overlay' : 'containerHeight'}
        display="flex"
        flexDir="column"
        alignItems="center"
        maxW="500px"
        width="100%"
        // margin="auto"
        bgColor="#EFEFEF"
      >
        <Box
          display="flex"
          flexDir="column"
          alignItems="center"
          width="90%"
          // margin="auto"
          overflow="hidden"
        >
          <SearchUI
            input={input}
            setInput={setInput}
            inputSearch={inputSearch}
            setInputSearch={setInputSearch}
            setIsSearchLoading={setIsSearchLoading}
            setData={setData}
            setSortedData={setSortedData}
            setErrorMessage={setErrorMessage}
            getProviders={getProviders}
            topRated={topRated}
            selectedSubType={selectedSubType}
          />
          <Filters
            topRated={topRated}
            setTopRated={setTopRated}
            subTypes={subTypes}
            selectedSubType={selectedSubType}
            setSelectedSubType={setSelectedSubType}
            getProviders={getProviders}
          />
          <Box
            overflowY={isSearchLoading ? '' : 'scroll'}
            mb={5}
            px={'5%'}
            width="110%"
          >
            <ListingCards
              data={topRated ? sortedData : data}
              isSearchLoading={isSearchLoading}
            />
          </Box>
        </Box>

        {showAlertModal && (
          <AlertModal
            isOpen={showAlertModal}
            message={errorMessage}
            onClose={() => {
              setErrorMessage('');
              setShowAlertModal(false);
            }}
          />
        )}
      </Box>
      {isLoading && <OverlayLoader message={progressMessage} />}
      {!isLoading && (
        <Box width="100%" position="relative" margin="auto">
          <Box
            as="button"
            position="absolute"
            left={0}
            bottom={0}
            ml={['5%']}
            mb={['5%']}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="50%"
            width="50px"
            height="50px"
            bgColor="#0058ff"
            onClick={() => {
              registerEvent(ec.Listing, actions.BACK_NAVIGATION);
              history.goBack();
            }}
            boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
          >
            <Image
              boxSize="30px"
              objectFit="cover"
              src={whiteBackImage}
              alt="Back button"
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Listings;
