import React from 'react';
import { Box, Image, Text, Button, Stack } from '@chakra-ui/react';

import failLogo from './../../../../assets/images/fail.png';
import { primaryText, px13, px16 } from '../../constants';
import { useHistory } from 'react-router-dom';
import { primaryColor } from '../../constants';
import { retrieveHelper, pc } from './../../../utils';

const FailUpload = ({ setState }) => {
  const history = useHistory();

  return (
    <Box
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      className="containerHeight"
    >
      <Box
        width="90%"
        margin="auto"
        mt={5}
        p={5}
        bgColor="white"
        boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
        borderRadius="5px"
      >
        <Box
          borderRadius={['5px']}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Image
            width={['85px']}
            height={['85px']}
            objectFit="cover"
            src={failLogo}
            alt="failed"
          />
        </Box>

        <Box textAlign="center" color={primaryText}>
          <Text fontSize={px16} fontWeight="bold">
            Bill Upload Failed
          </Text>
          <Stack fontSize={px13} pt={5}>
            <Text>Please try again</Text>
          </Stack>
        </Box>
      </Box>

      <Box
        width="90%"
        margin="auto"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        flexDir="column"
        mb="3vh"
      >
        <Button
          isFullWidth
          minH={['40px']}
          _active={{
            bgColor: { primaryColor },
            color: 'white',
          }}
          _hover={{
            bgColor: { primaryColor },
            color: 'white',
          }}
          bgColor={primaryColor}
          color="white"
          variant="solid"
          size="md"
          onClick={() => {
            setState('upload');
          }}
        >
          <Text fontSize={px13}>Try Again</Text>
        </Button>

        <Box
          as="button"
          height={['40px']}
          fontSize={px13}
          onClick={() => {
            const employeeId = retrieveHelper(pc.employeeId);
            history.push(`/?id=${employeeId}`);
          }}
        >
          <Text color={primaryColor}>Back Home</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default FailUpload;
