import React from 'react';
import { Box, Stack, Text, Button } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import TransactionStatus from '../../scanUI/transactionsUI/transactionStatus';
import { retrieveHelper, pc } from '../../../utils';
import { primaryText, primaryColor, px16, px13 } from './../../constants';

const FailedTrasaction = ({ state, transaction }) => {
  const paidAmount = retrieveHelper(pc.paidAmount);
  const qrName = retrieveHelper(pc.qrName);

  const history = useHistory();

  return (
    <Box
      display="flex"
      flexDir="column"
      flex={1}
      mt={10}
      height="100%"
      width="90%"
      margin="auto"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        width="100%"
        p={5}
        borderRadius="5px"
        bgColor="white"
        boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
      >
        <TransactionStatus state={state} transaction={transaction} />

        <Stack color={primaryText} textAlign="center">
          <Stack spacing={0} mt={5} fontSize={px16} fontWeight="bold">
            <Text>Payment Failed </Text>
            <Text>of {paidAmount}</Text>
          </Stack>
          <Stack spacing={0} pt={5} fontSize={px16}>
            <Text>Payment to {qrName} failed. </Text>
            <Text>Please try again.</Text>
          </Stack>
        </Stack>
      </Box>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        flexDir="column"
        width="100%"
        mb="2vh"
      >
        <Button
          isFullWidth
          minH={['40px']}
          _active={{
            bgColor: { primaryColor },
            color: 'white',
          }}
          _hover={{
            bgColor: { primaryColor },
            color: 'white',
          }}
          bgColor={primaryColor}
          color="white"
          variant="solid"
          size="md"
          onClick={() => {
            history.push('/transaction');
          }}
        >
          <Text fontSize={px13}>Try Again</Text>
        </Button>

        <Box
          as="button"
          height={['40px']}
          fontSize={px13}
          onClick={() => {
            const employeeId = retrieveHelper(pc.employeeId);
            history.push(`/?id=${employeeId}`);
          }}
        >
          <Text color={primaryColor}>Back Home</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default FailedTrasaction;
