import ReactGA from 'react-ga';
import {
  DoctorIcon,
  HospitalIcon,
  LabIcon,
  OthersIcon,
  PharmacyIcon,
} from '../../assets/icons';
import clinicLogo from './../../assets/images/clinic.png';
import labLogo from './../../assets/images/lab.png';
import pharmacyLogo from './../../assets/images/pharmacy.png';

export const pc = {
  lastTransactionId: 'lastTransactionId',
  clinic: 'clinic',
  qrCode: 'qrCode',
  searchQuery: 'searchQuery',
  paymentType: 'paymentType',
  qrName: 'qrName',
  employeeId: 'employeeId',
  balance: 'balance',
  location: 'location',
  prevPath: 'prevPath',
  visitorId: 'visitorId',
  transactionStatus: 'transactionStatus',
  paidAmount: 'paidAmount',
  orderId: 'orderId',
  retryPayload: 'retryPayload',
  searchProvider: 'searchProvider',
  inAppDataURL: 'inAppDataURL',
  authToken: 'authToken',
  payment_location: 'payment_location',
  transaction_id: 'transaction_id',
  inAppInvoiceLink: 'inAppInvoiceLink',
  inAppInvoiceStatus: 'inAppInvoiceStatus',
};

export const isValidPathMove = (currentPath, prevPath) => {
  const toTransactionPath = ['/scanner', '/pay'];

  let result = true;
  let message = '';

  if (currentPath.includes('/partner') || currentPath.includes('inApp')) {
    return { result: result, message: message };
  }

  // console.log(prevPath);

  if (currentPath === '/transaction') {
    if (!toTransactionPath.includes(prevPath)) {
      result = false;
    }

    if (prevPath.includes('/transactionStatus/')) {
      result = true;
    }
  }

  if (prevPath === '/upload') {
    result = false;
  }

  return { result: result, message: message };
};

// const pathnames = [
//   ['/', '/listing'],
//   ['/', '/scanner', '/pay', '/transaction', '/upload'],
//   ['/', '/upload'],
//   ['/', '/scanner', '/transaction'],
// ];

// const transactionStatuses = ['success', 'pending', 'fail'];

// export const isValidPathMove = (currentPath, prevPath) => {
//   let result = false;
//   let message = 'This is not a valid path, taking you to home page.';

//   // let clinic = retrieveHelper(pc.clinic);
//   let transactionStatus = retrieveHelper(pc.transactionStatus);

//   if (prevPath === '/transaction') {
//     if (transactionStatuses.includes(transactionStatus)) {
//       if (currentPath === '/upload') {
//         result = true;
//         message = '';
//       } else {
//         result = false;
//         message = '';
//       }
//     } else {
//       pathnames.every(pathList => {
//         let index = pathList.indexOf(prevPath);

//         if (currentPath === prevPath) {
//           result = true;
//           message = '';
//           return false;
//         } else if (pathList[index + 1] === currentPath) {
//           result = true;
//           message = '';
//           return false;
//         } else if (pathList[index - 1] === currentPath) {
//           result = true;
//           message = '';
//           return false;
//         }
//         return true;
//       });
//     }
//   } else if (prevPath === '/upload') {
//     result = false;
//     message = '';
//   } else {
//     pathnames.every(pathList => {
//       let index = pathList.indexOf(prevPath);

//       if (currentPath === prevPath) {
//         result = true;
//         message = '';
//         return false;
//       } else if (pathList[index + 1] === currentPath) {
//         result = true;
//         message = '';
//         return false;
//       } else if (pathList[index - 1] === currentPath) {
//         result = true;
//         message = '';
//         return false;
//       }
//       return true;
//     });
//   }

//   // if (currentPath !== '/' && currentPath !== '/upload') {
//   //   if (clinic == null) {
//   //     result = false;
//   //   }
//   // }
//   return { result: result, message: message };
// };

export const storeHelper = (key, valueToStore, subKey = null) => {
  // check if it already exist

  let data = retrieveHelper(key);
  if (data == null) {
    if (subKey == null) {
      localStorage.setItem(key, JSON.stringify(valueToStore));
    } else {
      const objectToStore = { subKey: valueToStore };
      localStorage.setItem(key, JSON.stringify(objectToStore));
    }
  } else {
    // when not exist create one
    if (subKey == null) {
      localStorage.setItem(key, JSON.stringify(valueToStore));
    } else {
      data[key] = valueToStore;
      localStorage.setItem(key, JSON.stringify(data));
    }
  }
};

export const retrieveHelper = key => {
  let value = localStorage.getItem(key);
  value = String(value);

  // console.log(value, key);

  if (value === 'null' || value === 'undefined' || value == null) {
    return null;
  } else {
    return JSON.parse(value);
  }
};

// helper functions
export const distance = (lat1, lon1, lat2, lon2, unit) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') {
      dist = dist * 1.609344;
    }
    if (unit === 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }
};

export const getDefaultType = () => {
  let searchQuery = retrieveHelper(pc.searchQuery);
  if (searchQuery == null) {
    return 'pharmacy';
  } else {
    return searchQuery.type;
  }
};

export const getDefaultSubType = () => {
  let searchQuery = retrieveHelper(pc.searchQuery);
  if (searchQuery == null) {
    return 'near me';
  } else {
    // console.log('in else in subtype');
    return searchQuery.keyword;
  }
};

export const getTypeLogo = type => {
  if (type.includes('hospital')) {
    return clinicLogo;
  } else if (type.includes('pharmacy')) {
    return pharmacyLogo;
  } else if (type.includes('diagnostic')) {
    return labLogo;
  } else {
    return clinicLogo;
  }
};

export const getTypeIcon = (type, imageSize) => {
  if (type.includes('hospital')) {
    return <HospitalIcon width={imageSize} height={imageSize} />;
  } else if (type.includes('pharmacy')) {
    return <PharmacyIcon width={imageSize} height={imageSize} />;
  } else if (type.includes('diagnostic')) {
    return <LabIcon width={imageSize} height={imageSize} />;
  } else if (type.includes('doctors')) {
    return <DoctorIcon width={imageSize} height={imageSize} />;
  } else {
    return <OthersIcon width={imageSize} height={imageSize} />;
  }
};

export const comparator = (a, b) => {
  if (a.rating < b.rating) {
    return 1;
  }
  if (a.rating > b.rating) {
    return -1;
  }
  return 0;
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const nameMapping = {
  diagnostics: 'labs',
  doctors: 'doctors',
  hospitals: 'hospitals',
  others: 'others',
  pharmacy: 'pharmacy',
};

export const textSearchMapping = {
  diagnostics: 'diagnostics',
  doctors: 'doctors',
  hospitals: 'hospitals',
  others: 'others',
  pharmacy: 'pharmacy',
};

export const page = {
  form: 'form',
  otp: 'otp',
};

export const state = {
  otp: 'otp',
  success: 'success',
  fail: 'fail',
};

export const parseErrorMessage = error => {
  // console.log(error);
  try {
    // console.log(error.request);

    if (error.response) {
      // Request made and server responded
      // console.log('in 1');
      // return `${error.message}`;

      try {
        if (error.response.status === 413) {
          return 'Please upload file less or equal to 50MB in size.';
        }

        if (error.response.data.message) {
          return error.response.data.message;
        } else {
          return 'We are experiencing some problem, Please try again in some time';
        }
      } catch {
        return 'We are experiencing some problem, Please try again in some time';
      }
    } else if (error.request) {
      // The request was made but no response was received
      // console.log(JSON.stringify(error));
      // console.log('in 2');
      if (error.message.includes('Network Error')) {
        return 'No internet connection';
      }
      return `${error.message}`;
    } else if (error.message) {
      // Something happened in setting up the request that triggered an Error
      // console.log('in 3');
      // console.log(error.message);
      if (error.message.includes('undefined (reading')) {
        return 'We are experiencing some problem, Please try again in some time';
      } else {
        return 'No internet connection';
      }
    } else {
      // console.log('in 4');
      return 'Something went wrong';
    }
  } catch (err) {
    return 'Something went wrong';
  }
};

export const ec = {
  Home: 'Home',
  Listing: 'Listing',
  Scanner: 'Scanner',
  Pay: 'Pay',
  Transaction: 'Transaction',
  Upload: 'Upload',
  Error: 'Error',
  InAppTransaction: 'InAppTransaction',
  InAppUpload: 'InAppUpload',
  InAppPartialTransaction: 'InAppPartialTransaction',
  Timing: 'Timing',
};

export const actions = {
  REGISTER_FP: 'REGISTER_FP',
  AUTH_INIT: 'AUTH_INIT',
  GET_PROFILE: 'GET_PROFILE',
  LOCATION_INIT: 'LOCATION_INIT',
  LOCATION_FOUND: 'LOCATION_FOUND',
  LOCATION_ERROR: 'LOCATION_ERROR',
  TOP_RATED_CLICK: 'TOP_RATED_CLICK',
  HOME_UPLOAD_CLICK: 'HOME_UPLOAD_CLICK',
  HOME_PAY_CLICK: 'HOME_PAY_CLICK',
  BACK_NAVIGATION: 'BACK_NAVIGATION',
  HELP: 'HELP',
  BANNER_CLICK: 'BANNER_CLICK',
  BOOK_NOW: 'BOOK_NOW',
  MEDPAY: 'MEDPAY',
  MAP_DIRECTION: 'MAP_DIRECTION',
  QR_VALIDATE_INIT: 'QR_VALIDATE_INIT',
  QR_VALIDATE_SUCCESS: 'QR_VALIDATE_SUCCESS',
  MANUAL_PAYMENT_METHOD: 'MANUAL_PAYMENT_METHOD',
  MANUAL_PAYMENT_METHOD_VALIDATE_SUCCESS:
    'MANUAL_PAYMENT_METHOD_VALIDATE_SUCCESS',
  MANUAL_PAYMENT_METHOD_VALIDATE_INIT: 'MANUAL_PAYMENT_METHOD_VALIDATE_INIT',

  PAYMENT_VALIDATE_IN_PROCESS: 'PAYMENT_VALIDATE_IN_PROCESS',
  PAYMENT_VALIDATE_DONE: 'PAYMENT_VALIDATE_DONE',
  PAYMENT_REQUEST_INIT: 'PAYMENT_REQUEST_INIT',
  PAYMENT_STATUS_SUCCESS: 'PAYMENT_STATUS_SUCCESS',
  PAYMENT_STATUS_PENDING: 'PAYMENT_STATUS_PENDING',
  PAYMENT_STATUS_FAILED: 'PAYMENT_STATUS_FAILED',
  PAYMENT_STATUS_ERROR: 'PAYMENT_STATUS_ERROR',
  PAYMENT_STATUS_REFRESH_SUCCESS: 'PAYMENT_STATUS_REFRESH_SUCCESS',
  PAYMENT_STATUS_REFRESH_FAILED: 'PAYMENT_STATUS_REFRESH_FAILED',
  UPLOAD_BILL_CLICK: 'UPLOAD_BILL_CLICK',
  BILL_SELECT_FROM_GALLERY: 'BILL_SELECT_FROM_GALLERY',
  BILL_UPLOAD_INIT: 'BILL_UPLOAD_INIT',
  BILL_UPLOAD_SUCCESS: 'BILL_UPLOAD_SUCCESS',
  BILL_UPLOAD_FAILED: 'BILL_UPLOAD_FAILED',
  BILL_UPLOAD_ERROR: 'BILL_UPLOAD_ERROR',
  CONFIRM_AND_PAY: 'CONFIRM_AND_PAY',
  PARTIAL_PAYMENT_SUCCESS: 'PARTIAL_PAYMENT_SUCCESS',
  PARTIAL_PAYMENT_FAILED: 'PARTIAL_PAYMENT_FAILED',
  PARTIAL_PAYMENT_INIT: 'PARTIAL_PAYMENT_INIT',
};

export const registerEvent = (category, action, label = null) => {
  const employeeId = retrieveHelper(pc.employeeId);

  const labelInfo = label == null ? employeeId : label;

  ReactGA.event({
    category: category,
    action: action,
    label: labelInfo,
  });
};

export const entityTypes = [
  { text: 'Pharmacy', value: 'pharmacy' },
  { text: 'Doctor', value: 'doctors' },
  { text: 'Hospital', value: 'hospitals' },
  { text: 'Lab', value: 'diagnostics' },
  { text: 'Others', value: 'others' },
];

export const timingCategory = url => {
  if (url.includes('profile')) {
    return ec.Home;
  } else if (url.includes('autocomplete') || url.includes('providers')) {
    return ec.Listing;
  } else if (url.includes('booking')) {
    return 'Booking';
  } else if (url.includes('qr/validate')) {
    return ec.Scanner;
  } else if (
    url.includes('validate-request') ||
    url.includes('init-payment') ||
    url.includes('payment/status')
  ) {
    return 'Payment';
  } else if (url.includes('bill-upload')) {
    return ec.Upload;
  } else {
    return 'Uncaptured';
  }
};
