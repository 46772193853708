import React, { useEffect, useState } from 'react';

import { Box, Text, Image, Stack, Button } from '@chakra-ui/react';

import errorImage from './../../../../assets/images/fail.png';
import { primaryText, primaryColor, px16, px12, px13 } from './../../constants';
import { useHistory, useLocation } from 'react-router-dom';

const PaymentError = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [id, setId] = useState(null);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    let data = params.get('data');

    try {
      data = JSON.parse(atob(data));
      // console.log(data['error']);
      setErrorMessage(data['error']);
      setId(data['id']);
    } catch (err) {
      setErrorMessage('Please try after some time.');
      setId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="containerHeight">
      <Box
        display="flex"
        flexDir="column"
        flex={1}
        mt={10}
        height="100%"
        width="90%"
        margin="auto"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          width="100%"
          mt={5}
          p={5}
          bgColor="white"
          borderRadius="5px"
          boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
        >
          <Box
            textAlign="center"
            display="flex"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              boxSize={['85px']}
              objectFit="cover"
              src={errorImage}
              alt="error"
            />
          </Box>

          <Stack color={primaryText} textAlign="center">
            <Stack spacing={0} mt={5} fontSize={px16} fontWeight="bold">
              <Text>This was unexpected</Text>
            </Stack>
            <Stack spacing={0} pt={5} fontSize={px12}>
              <Text>{errorMessage}</Text>
            </Stack>
          </Stack>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          flexDir="column"
          width="100%"
          mb="2vh"
        >
          <Button
            variant="outline"
            isFullWidth
            borderColor={primaryColor}
            height={['40px']}
            color={primaryColor}
            onClick={() => {
              if (id != null) {
                history.push(`/?id=${id}`);
              } else {
                history.push('/');
              }
            }}
          >
            <Text fontSize={px13} fontWeight="bold" color={primaryColor}>
              Go Home
            </Text>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentError;
