import React from 'react';

import { Box, Text, Image, Stack, Button } from '@chakra-ui/react';

import successLogo from './../../../../assets/images/success.png';
import { primaryText, primaryColor, px16, px12, px13 } from './../../constants';
import { useHistory, useLocation } from 'react-router-dom';

const BillSuccess = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <Box className="containerHeight">
      <Box
        display="flex"
        flexDir="column"
        flex={1}
        mt={10}
        height="100%"
        width="90%"
        margin="auto"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          width="100%"
          mt={5}
          p={5}
          bgColor="white"
          borderRadius="5px"
          boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
        >
          <Box
            textAlign="center"
            display="flex"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              mr="3.5%"
              boxSize={['85px']}
              objectFit="cover"
              src={successLogo}
              alt="error"
            />
          </Box>

          <Stack color={primaryText} textAlign="center">
            <Stack spacing={0} mt={5} fontSize={px16} fontWeight="bold">
              <Text>Payment Already Completed</Text>
            </Stack>
            <Stack spacing={0} pt={5} fontSize={px12}>
              <Text>Thank you!</Text>
            </Stack>
          </Stack>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          flexDir="column"
          width="100%"
          mb="2vh"
        >
          <Button
            variant="outline"
            isFullWidth
            borderColor={primaryColor}
            height={['40px']}
            color={primaryColor}
            onClick={() => {
              let params = new URLSearchParams(location.search);
              const employeeId = params.get('id');
              if (employeeId != null) {
                history.push(`/?id=${employeeId}`);
              } else {
                history.push('/');
              }
            }}
          >
            <Text fontSize={px13} fontWeight="bold" color={primaryColor}>
              Go Home
            </Text>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default BillSuccess;
