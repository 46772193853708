import React, { useState, useEffect } from 'react';
import {
  Box,
  Stack,
  Text,
  VStack,
  // IconButton,
  Button,
} from '@chakra-ui/react';

import TransactionStatus from '../../scanUI/transactionsUI/transactionStatus';
import useAlertHook from '../../hooks/errorHook';
import AlertModal from './../../subComponents/alertModal/alertModal';
import {
  storeHelper,
  retrieveHelper,
  pc,
  parseErrorMessage,
  registerEvent,
  ec,
  actions,
} from '../../../utils';
import { instance } from '../../../api';
import {
  primaryColor,
  primaryText,
  px12,
  px13,
  px14,
  px16,
  secondryText,
} from '../../constants';

const PendingTransaction = ({ state, transaction, setTransaction }) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    showAlertModal,
    setShowAlertModal,
    errorMessage,
    setErrorMessage,
  } = useAlertHook();

  const paidAmount = retrieveHelper(pc.paidAmount);

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  const handleRefreshCall = transactionId => {
    setIsLoading(true);
    instance
      .get(`opd/payment/status/${transactionId}`)
      .then(response => {
        setIsLoading(false);
        let data = response.data;

        if (data.status === 'success') {
          if (data.response.status === 'success') {
            registerEvent(
              ec.Transaction,
              actions.PAYMENT_STATUS_REFRESH_SUCCESS
            );
            setTransaction({ ...data.response });
            storeHelper(pc.lastTransactionId, data.response.transaction_id);
            storeHelper(pc.transactionStatus, 'success');
          } else if (data.response.status === 'failed') {
            registerEvent(
              ec.Transaction,
              actions.PAYMENT_STATUS_REFRESH_FAILED
            );
            storeHelper(pc.transactionStatus, 'fail');
          }
        } else {
          setErrorMessage(data.message);
        }
      })
      .catch(err => {
        setIsLoading(false);
        const errMsg = parseErrorMessage(err);
        setErrorMessage(errMsg);
      });
  };

  return (
    <Box
      display="flex"
      flexDir="column"
      flex={1}
      mt={10}
      height="100%"
      width="90%"
      margin="auto"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        width="100%"
        p={5}
        borderRadius="5px"
        bgColor="white"
        boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
      >
        <TransactionStatus state={state} transaction={transaction} />

        <Stack textAlign="center" spacing={0}>
          <Text fontSize={px16} fontWeight="bold" color={primaryText}>
            ₹ {paidAmount}
          </Text>
          <Text fontSize={px16} fontWeight="bold" color={primaryText}>
            Payment Pending
          </Text>

          <Box display="flex" justifyContent="center" py={5} fontSize={px14}>
            <VStack>
              <Box display="flex" width="100%" fontSize={px12}>
                <VStack width="70%">
                  <Text textAlign="left" width={120} color={secondryText}>
                    Transaction ID
                  </Text>
                </VStack>
                <VStack width="50%">
                  <Text
                    textAlign="left"
                    width={170}
                    color={primaryText}
                    fontWeight="semibold"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {transaction.transaction_id}
                  </Text>
                </VStack>
              </Box>
            </VStack>
          </Box>
        </Stack>
      </Box>

      <Box width="100%" mb="3vh">
        <Button
          isLoading={isLoading}
          isFullWidth
          minH={['40px']}
          _active={{
            bgColor: { primaryColor },
            color: 'white',
          }}
          _hover={{
            bgColor: { primaryColor },
            color: 'white',
          }}
          bgColor={primaryColor}
          color="white"
          variant="solid"
          size="md"
          onClick={() => {
            handleRefreshCall(transaction.transaction_id);
          }}
        >
          <Text fontSize={px13}>Refresh Status</Text>
        </Button>
      </Box>

      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          showUpload={false}
          onClose={() => {
            setShowAlertModal(false);
          }}
          message={errorMessage}
        />
      )}
    </Box>
  );
};

export default PendingTransaction;
