const getImageDimension = image => {
  return new Promise((resolve, reject) => {
    const imageFile = new Image();
    imageFile.src = image;

    imageFile.onload = () => {
      const width = imageFile.width;
      const height = imageFile.height;
      const size = (height * width * 3) / 8;
      resolve({ height, width, size, imageFile });
    };
    imageFile.onerror = error => reject(error);
  });
};

const SIZE_CHECK = 300;

export const compressFile = async image => {
  let { height, width, size, imageFile } = await getImageDimension(image);
  let sizeInKB = parseInt(size / (1024 * 8));
  // console.log(height, width, sizeInKB);

  // console.log('size in KB', sizeInKB);
  if (sizeInKB > SIZE_CHECK) {
    // console.log('compressing now');
    const ratio = sizeInKB / SIZE_CHECK;
    // console.log(ratio);
    const newHeight = height / ratio;
    const newWidth = width / ratio;
    // console.log(newWidth, newHeight);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = newWidth;
    canvas.height = newHeight;
    ctx.drawImage(imageFile, 0, 0, newWidth, newHeight);
    // console.log('compressed');
    return canvas.toDataURL('image/jpeg', 0.5);

    // const newDimnsions = await getImageDimension(newImage);
    // sizeInKB = parseInt(newDimnsions.size / (1024 * 8));
    // console.log(
    //   'new file size: ',
    //   newDimnsions.height,
    //   newDimnsions.width,
    //   sizeInKB
    // );

    // return newImage;
  }
  // console.log('no need to compress');
  return image;
};
