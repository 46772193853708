import React, { useEffect, useState } from 'react'; // { useState, useEffect }
import { Box, Button } from '@chakra-ui/react';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import { useLocation, useHistory } from 'react-router';

import useAlertHook from '../../hooks/errorHook';
import AlertModal from '../../subComponents/alertModal/alertModal';
import HomePage from '../../scanUI/homeUI';
import { instance } from './../../../api';
import config from '../../../../config';
import {
  retrieveHelper,
  storeHelper,
  pc,
  parseErrorMessage,
  registerEvent,
  ec,
  actions,
} from './../../../utils';
import OverlayLoader from '../../subComponents/overlayLoader';

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [progressMessage, setProgressMessage] = useState('');
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(false);
  const [contactNumber, setContactNumber] = useState(null);

  const {
    showAlertModal,
    setShowAlertModal,
    errorMessage,
    setErrorMessage,
  } = useAlertHook();

  const location = useLocation();
  const history = useHistory();

  const getVisistorId = async () => {
    setIsLoading(true);
    setProgressMessage('Authenticating');
    const fpPromise = FingerprintJS.load({
      token: config.FP_TOKEN,
      endpoint: config.FP_DOMAIN,
    });

    let employeeId = retrieveHelper(pc.employeeId);

    registerEvent(ec.Home, actions.REGISTER_FP);
    fpPromise
      .then(fp => fp.get({ linkedId: employeeId }))
      .then(result => {
        const visitorId = result.visitorId;
        storeHelper(pc.visitorId, visitorId);
        document.getElementById('locationButton').click();
      })
      .catch(err => {
        setIsLoading(false);
        const errMsg = parseErrorMessage(err);
        setErrorMessage(errMsg);
      });
  };

  const getEmployeeDetails = () => {
    let employeeId = retrieveHelper(pc.employeeId);

    setProgressMessage('Fetching details');

    registerEvent(ec.Home, actions.AUTH_INIT);
    instance
      .get(`/opd/employee/profile/${employeeId}`)
      .then(response => {
        let data = response.data;
        setIsLoading(false);
        // let data = {
        //   status: 'success',
        //   response: {
        //     bill_status: 'accepted',
        //     // previous_transaction_status: 'processing',
        //     payment_links: ['https://www.google.com/'],
        //     message:
        //       'Your previous transaction is in processing. Please wait for some time.',
        //     last_transaction_details: {
        //       created_at: '04/01/2022 14:17:27.641967',
        //       transaction_id: '3004497803117832',
        //       amount: '21',
        //       provider_name: 'Akash Sharma',
        //     },
        //     balance: '9080.0',
        //     payment_collection_status: true,
        //     first_name: 'Vishal',
        //     banner_links: [
        //       'https://medpaydocs.s3.ap-south-1.amazonaws.com/banner_medpay_2.png',
        //     ],
        //     opd_support_number: '7905483808',
        //   },
        //   message: 'successful.',
        // };
        // console.log(data);

        if (data.status === 'success') {
          storeHelper('userData', {
            full_name: data.response.user_details.full_name,
            whatsapp_number: data.response.user_details.whatsapp_number,
            bill_status: data.response.bill_status,
            message: data.response.message,
            payment_collection_status: data.response.payment_collection_status,
            payment_link: data.response.payment_links
              ? data.response.payment_links[0]
              : '',
          });
          // console.log(data.response.balance);
          storeHelper(pc.balance, data.response.balance);
          setResponse(data.response);
          if (data.response.opd_support_number != null) {
            setContactNumber(data.response.opd_support_number);
          }
        } else {
          if (data.response.opd_support_number != null) {
            setContactNumber(data.response.opd_support_number);
          }
          setIsLoading(false);
          setErrorMessage(data.message);
        }
      })
      .catch(err => {
        // console.log(err);
        setIsLoading(false);
        const errMsg = parseErrorMessage(err);
        setErrorMessage(errMsg);
      });
  };

  const showPosition = position => {
    let searchLocation = '';

    let params = new URLSearchParams(location.search);
    searchLocation = params.get('location');

    if (searchLocation == null) {
      searchLocation = `${position.coords.latitude},${position.coords.longitude}`;
      // console.log('search location found:', searchLocation);
    }
    registerEvent(ec.Home, actions.LOCATION_FOUND);
    storeHelper(pc.location, searchLocation);

    getEmployeeDetails();
  };

  const onLocationErr = err => {
    registerEvent(ec.Home, actions.LOCATION_ERROR);
    setIsLoading(false);
    // console.log('search location failed');
    setErrorMessage(
      'Please allow location permissions to view nearby healthcare centers.'
    );
  };

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  const getLocation = () => {
    setProgressMessage('Fetching location');
    if (navigator.geolocation) {
      setIsLoading(true);
      registerEvent(ec.Home, actions.LOCATION_INIT);
      let searchLocation = '';

      let params = new URLSearchParams(location.search);
      searchLocation = params.get('location');

      if (searchLocation != null) {
        // console.log('in if');
        registerEvent(ec.Home, actions.LOCATION_FOUND);
        storeHelper(pc.location, searchLocation);
        getEmployeeDetails();
      } else {
        // console.log('in else');
        navigator.geolocation.getCurrentPosition(showPosition, onLocationErr);
      }
    } else {
      setErrorMessage('Geolocation is not supported by this browser.');
    }
  };

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    let employeeId = params.get('id');

    if (employeeId != null) {
      // let empId = retrieveHelper(pc.employeeId);
      // let visitorId = retrieveHelper(pc.visitorId);

      // localStorage.clear();

      // const status =
      //   employeeId === empId &&
      //   visitorId !== 'undefined' &&
      //   visitorId !== 'null' &&
      //   visitorId != null;

      // if (status) {
      // storeHelper(pc.visitorId, visitorId);
      // storeHelper(pc.employeeId, employeeId);
      // document.getElementById('locationButton').click();
      // } else {
      storeHelper(pc.employeeId, employeeId);
      getVisistorId();
      // }
    } else {
      setErrorMessage('Could not identify the user. Please use correct url.');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
      setError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  return (
    <Box
      className="containerHeight"
      display="flex"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <Button
        id="locationButton"
        display="none"
        onClick={() => getLocation()}
      ></Button>
      {isLoading ? (
        <OverlayLoader message={progressMessage} />
      ) : (
        <Box width="100%">
          <HomePage
            error={error}
            history={history}
            response={response}
            contactNumber={contactNumber}
            setErrorMessage={setErrorMessage}
          />
        </Box>
      )}

      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          message={errorMessage}
          link={response && response.payment_links && response.payment_links[0]}
          onClose={() => {
            if (errorMessage.includes('allow location')) {
              document.getElementById('locationButton').click();
            }
            setErrorMessage('');
            setShowAlertModal(false);
          }}
        />
      )}
    </Box>
  );
};

export default Home;
