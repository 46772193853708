import React, { useState, useEffect } from 'react';
import { Box, Text, HStack, Image, Button, VStack } from '@chakra-ui/react';
import { primaryColor, px13, px16 } from '../../constants';
import {
  DentistIcon,
  DoctorIcon,
  HospitalIcon,
  LabIcon,
  OthersIcon,
  PharmacyIcon,
  QRIcon,
  WalletIcon,
} from '../../../../assets/icons';
import hiImage from './../../../../assets/images/hi.png';
import HomeCard from './homeCard';
import { storeHelper, pc, registerEvent, ec, actions } from './../../../utils';
import { WarningIcon } from '@chakra-ui/icons';
import ContactCard from './contactCard';
// import banner1 from './../../../../assets/images/banner1.png';

let showUploadArrayStatus = ['pending', 'rejected'];

const HomePage = ({
  error,
  history,
  response,
  setErrorMessage,
  contactNumber,
}) => {
  const [disable, setDisable] = useState(false);
  const [errorState, setErrorState] = useState(null);
  const [banners, setBanners] = useState([]);

  const cards = [
    {
      icon: <DoctorIcon boxSize={['33px']} />,
      text: 'Doctor',
      onClick: () => {
        let searchQuery = {
          type: 'doctors',
          keyword: 'general physician',
        };
        registerEvent(ec.Home, 'CARD_CLICKED_DOCTORS');
        storeHelper(pc.searchQuery, searchQuery);
        history.push('/listing');
      },
    },
    {
      icon: <PharmacyIcon boxSize={['33px']} />,
      text: 'Medical Store',
      onClick: () => {
        let searchQuery = {
          type: 'pharmacy',
          keyword: 'near me',
        };
        registerEvent(ec.Home, 'CARD_CLICKED_PHARMACY');
        storeHelper(pc.searchQuery, searchQuery);
        history.push('/listing');
      },
    },
    {
      icon: <HospitalIcon boxSize={['33px']} />,
      text: 'Hospital',
      onClick: () => {
        let searchQuery = {
          type: 'hospitals',
          keyword: 'near me',
        };
        registerEvent(ec.Home, 'CARD_CLICKED_HOSPITALS');
        storeHelper(pc.searchQuery, searchQuery);
        history.push('/listing');
      },
    },
    {
      icon: <LabIcon boxSize={['33px']} />,
      text: 'Lab',
      onClick: () => {
        let searchQuery = {
          type: 'diagnostics',
          keyword: 'near me',
        };
        registerEvent(ec.Home, 'CARD_CLICKED_LABS');
        storeHelper(pc.searchQuery, searchQuery);
        history.push('/listing');
      },
    },
    {
      icon: <DentistIcon boxSize={['33px']} />,
      text: 'Dentist',
      onClick: () => {
        let searchQuery = {
          type: 'doctors',
          keyword: 'dentist',
        };
        registerEvent(ec.Home, 'CARD_CLICKED_DOCTORS_DENTIST');
        storeHelper(pc.searchQuery, searchQuery);
        history.push('/listing');
      },
    },
    {
      icon: <OthersIcon boxSize={['33px']} />,
      text: 'More Services',
      onClick: () => {
        let searchQuery = {
          type: 'others',
          keyword: 'optician',
        };
        registerEvent(ec.Home, 'CARD_CLICKED_OTHERS');
        storeHelper(pc.searchQuery, searchQuery);
        history.push('/listing');
      },
    },
  ];

  useEffect(() => {
    // setBanners([banner1]);
    if (response != null) {
      if (response.banner_links) {
        setBanners([...response.banner_links]);
      }

      if (
        response.previous_transaction_status != null &&
        response.previous_transaction_status === 'processing'
      ) {
        setDisable(true);
        setErrorState('message');
      } else {
        if (response.payment_collection_status) {
          setDisable(true);
          setErrorState('payment');
        } else if (showUploadArrayStatus.includes(response.bill_status)) {
          setDisable(true);
          storeHelper(
            pc.lastTransactionId,
            response.last_transaction_details.transaction_id
          );
          setErrorState('upload');
        } else if (response.bill_status === 'processing') {
          setDisable(true);
          setErrorState('message');
        }
      }
    }
  }, [response]);

  const uploadButtonUI = (
    <Button
      variant="outline"
      isFullWidth
      bgColor="#E7EDF9"
      borderColor={primaryColor}
      height={['40px']}
      color={primaryColor}
      onClick={() => {
        registerEvent(ec.Home, actions.HOME_UPLOAD_CLICK);
        history.push('/upload');
      }}
    >
      <Text fontSize={px13} fontWeight="bold" color={primaryColor}>
        Upload Last Bill
      </Text>
    </Button>
  );

  const paymentButtonUI = (
    <Button
      variant="outline"
      isFullWidth
      bgColor="#E7EDF9"
      borderColor={primaryColor}
      height={['40px']}
      color={primaryColor}
      onClick={() => {
        registerEvent(ec.Home, actions.HOME_PAY_CLICK);
        window
          .open(response.payment_links && response.payment_links[0])
          .focus();
      }}
    >
      <Text fontSize={px13} fontWeight="bold" color={primaryColor}>
        Pay Bill
      </Text>
    </Button>
  );

  const messageUI = (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgColor="#E7EDF9"
      borderRadius="5px"
      p={2}
    >
      <WarningIcon ml={2} boxSize="20px" color="orange.300" />
      <Text
        pl={2}
        fontSize={px13}
        fontWeight="bold"
        color={primaryColor}
        textAlign="center"
      >
        {response && response.message}
      </Text>
    </Box>
  );

  const getErrorUI = errorState => {
    if (errorState === 'upload') {
      return uploadButtonUI;
    } else if (errorState === 'message') {
      return messageUI;
    } else if (errorState === 'payment') {
      return paymentButtonUI;
    }
  };

  return (
    <Box
      className="containerHeight"
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Box
        width="100%"
        overflow="scroll"
        mb="70px"
        pt={banners.length === 0 ? '55px' : '15px'}
      >
        <Box
          position="fixed"
          top={0}
          left={0}
          right={0}
          maxW={['500px']}
          width="100%"
          margin="auto"
          height={'40px'}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bgColor={primaryColor}
        >
          <HStack
            spacing={1}
            pl="5%"
            width={'200px'}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            <Text fontSize={px16} color="white">
              Hi, {response && response.first_name}
            </Text>
            <Image
              boxSize="18px"
              objectFit="cover"
              src={hiImage}
              alt="hand wave"
            />
          </HStack>
          <HStack spacing={1} pr="5%">
            <WalletIcon boxSize={['16px']} />
            <Text fontSize={px16} fontWeight="bold" color="white">
              ₹
              {response != null
                ? Number.parseFloat(response.balance).toLocaleString()
                : '0'}
            </Text>
          </HStack>
        </Box>

        {banners.length > 0 && (
          <Box
            // border="1px solid black"
            height={['80px']}
            width="90%"
            margin="auto"
            borderRadius={['5px']}
            marginTop={['40px']}
            display="flex"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            // py={['10px']}
            mb={['15px']}
          >
            <Box>
              {banners.map((banner, index) => (
                <Image
                  ml={index === 0 ? 0 : 2}
                  key={index}
                  height="100%"
                  width="100%"
                  objectFit="fit"
                  src={banner}
                  alt="banner"
                  onClick={() => {
                    registerEvent(ec.Home, actions.BANNER_CLICK);
                  }}
                />
              ))}
            </Box>
          </Box>
        )}

        {errorState && (
          <Box
            // height={['50px']}
            width="90%"
            margin="auto"
            borderRadius={['5px']}
            display="flex"
            justifyContent="center"
            alignItems="center"
            my={['15px']}
          >
            {getErrorUI(errorState)}
          </Box>
        )}

        <Box width="90%" margin="auto">
          {/* <Text
            fontSize={px14}
            color={primaryText}
            fontWeight="bold"
            pl={4}
            pt={errorState == null ? 8 : 0}
          >
            Look around for -
          </Text> */}

          <Box
          // pt={
          //   errorState == null ? (banners.length === 0 ? '35px' : '17px') : 0
          // }
          >
            <VStack>
              <HStack
                display="flex"
                justifyContent="space-between"
                width="100%"
              >
                <HomeCard
                  disable={response == null}
                  key={cards[0].text}
                  icon={cards[0].icon}
                  text={cards[0].text}
                  onClick={cards[0].onClick}
                  width="48%"
                />
                <HomeCard
                  disable={response == null}
                  key={cards[1].text}
                  icon={cards[1].icon}
                  text={cards[1].text}
                  onClick={cards[1].onClick}
                  width="48%"
                />
              </HStack>

              <HStack
                display="flex"
                justifyContent="space-between"
                width="100%"
              >
                <HomeCard
                  disable={response == null}
                  key={cards[2].text}
                  icon={cards[2].icon}
                  text={cards[2].text}
                  onClick={cards[2].onClick}
                  width="48%"
                />
                <HomeCard
                  disable={response == null}
                  key={cards[3].text}
                  icon={cards[3].icon}
                  text={cards[3].text}
                  onClick={cards[3].onClick}
                  width="48%"
                />
              </HStack>

              <HStack
                display="flex"
                justifyContent="space-between"
                width="100%"
              >
                <HomeCard
                  disable={response == null}
                  key={cards[4].text}
                  icon={cards[4].icon}
                  text={cards[4].text}
                  onClick={cards[4].onClick}
                  width="48%"
                />
                <HomeCard
                  disable={response == null}
                  key={cards[5].text}
                  icon={cards[5].icon}
                  text={cards[5].text}
                  onClick={cards[5].onClick}
                  width="48%"
                />
              </HStack>

              <ContactCard contactNumber={contactNumber} />
            </VStack>
          </Box>
        </Box>
      </Box>
      <Box
        position="fixed"
        bottom={0}
        left={0}
        right={0}
        as="button"
        disable={response == null}
        pointerEvents={response == null ? 'none' : 'painted'}
        maxW="500px"
        width="100%"
        margin="auto"
        height={['50px']}
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor={primaryColor}
        onClick={() => {
          registerEvent(ec.Home, actions.MEDPAY);
          if (disable) {
            setErrorMessage(response.message);
          } else {
            history.push('/scanner');
          }
        }}
      >
        <QRIcon color={'white'} boxSize={['20px']} />
        <Text pl={2} pt={0.5} fontSize={px16} fontWeight="bold" color={'white'}>
          Tap to Pay
        </Text>
      </Box>
    </Box>
  );
};

export default HomePage;
