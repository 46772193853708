import React from 'react';
import { Box } from '@chakra-ui/react';

import PaymentCard from './paymentCard';
// import phonepeLogo from './../../../assets/images/phonepe.png';
// import gpayLogo from './../../../assets/images/gpay.png';
// import mobikwikLogo from './../../../assets/images/mobikwik.png';
import paytmLogo from './../../../../assets/images/paytm.png';

const paymentOptions = [
  { name: 'Paytm', image: paytmLogo },
  // { name: 'Google Pay', image: gpayLogo },
  // { name: 'MobiKwik', image: mobikwikLogo },
  // { name: 'Phonepe', image: phonepeLogo },
];

const PaymentOptions = ({
  upiID,
  selectedMethod,
  setSelectedMethod,
  isLoading,
}) => {
  return (
    <Box>
      {paymentOptions.map((option, index) => (
        <PaymentCard
          key={index}
          upiID={upiID}
          option={option}
          selectedMethod={selectedMethod}
          setSelectedMethod={setSelectedMethod}
          isLoading={isLoading}
        />
      ))}
    </Box>
  );
};

export default PaymentOptions;
