import React, { useEffect, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';

import OnboardingForm from './onboardingForm';
import useAlertHook from './../hooks/errorHook/index';
import AlertModal from './../subComponents/alertModal';
import { page as vPage } from './../../utils';
import OTPView from './otpView';
// import medpayLogo from './../../../assets/images/medpayLogo.png';

const View = () => {
  const [page, setPage] = useState(vPage.form);
  const [type, setType] = useState('fail');

  const { showAlertModal, setShowAlertModal, errorMessage, setErrorMessage } =
    useAlertHook();

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  // const getHeader = (page) => {
  //   if (page === vPage.form) {
  //     return "Onboarding Form";
  //   } else if (page === vPage.otp) {
  //     return "Submit OTP";
  //   }
  // };

  const getView = page => {
    if (page === vPage.form) {
      return (
        <OnboardingForm
          setPage={setPage}
          setErrorMessage={setErrorMessage}
          setType={setType}
        />
      );
    } else if (page === vPage.otp) {
      return <OTPView setErrorMessage={setErrorMessage} setType={setType} />;
    }
  };

  return (
    <Box
      className="containerHeight"
      width="100%"
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      alignItems="center"
      overflow="scroll"
    >
      <Box
        position="fixed"
        top={0}
        height={['50px']}
        bgColor="#0058ff"
        width={['100%']}
        display="flex"
        justifyContent="center"
        alignItems="center"
        zIndex={99999}
      >
        <Text color="white" fontWeight="bold">
          Corporate Cashless Program
        </Text>
        {/* <Image
          boxSize="100px"
          objectFit="contain"
          src={medpayLogo}
          alt="Medpay Logo"
        /> */}
      </Box>
      <Box maxW={'500px'} width={'100%'}>
        {/* <Text
          textAlign="center"
          color="white"
          fontSize={["lg"]}
          fontWeight={"bold"}
        >
          {getHeader(page)}
        </Text> */}
        {getView(page)}
      </Box>

      <Box
        // position="fixed"
        // bottom={0}
        height={['30px']}
        bgColor="#3c3732"
        width={['100%']}
        display="flex"
        justifyContent="center"
        alignItems="center"
        zIndex={99999}
      >
        <Text color="white">Powered by MedPay®</Text>
        {/* <Image
          boxSize="100px"
          objectFit="contain"
          src={medpayLogo}
          alt="Medpay Logo"
        /> */}
      </Box>

      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          message={errorMessage}
          type={type}
          onClose={() => {
            setType('fail');
            setErrorMessage('');
            setShowAlertModal(false);
          }}
        />
      )}
    </Box>
  );
};

export default View;
