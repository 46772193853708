import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Text,
  HStack,
  Divider,
  OrderedList,
  ListItem,
} from '@chakra-ui/react';
import { useHistory } from 'react-router';

import TransactionForm from './../../scanUI/transactionForm';
import { instance } from '../../../api';
import useAlertHook from '../../hooks/errorHook';
import AlertModal from '../../subComponents/alertModal/alertModal';
import {
  retrieveHelper,
  storeHelper,
  pc,
  parseErrorMessage,
  registerEvent,
  ec,
  actions,
} from '../../../utils';
import { primaryColor, primaryText, px13, px16 } from '../../constants';
import Loader from '../../subComponents/loader';
import { ListIcon as ListBookmarkIcon } from '../../../../assets/icons';
import './index.css';

const Transaction = () => {
  const [amount, setAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShowConfirmationPage, setShouldShowConfirmationPage] =
    useState(false);
  const [showConfirmationPage, setShowConfirmationPage] = useState(false);
  const [blurBackground, setBlurBackground] = useState(false);

  const { showAlertModal, setShowAlertModal, errorMessage, setErrorMessage } =
    useAlertHook();

  const history = useHistory();

  useEffect(() => {
    setShouldShowConfirmationPage(true);
  }, []);

  const handleTransactionCall = () => {
    // let paymentPartner = '';
    let QRCode = '';
    let QRName = '';
    // let clinic = '';
    let location = '';
    let paymentType = '';

    try {
      paymentType = retrieveHelper(pc.paymentType);
      QRCode = retrieveHelper(pc.qrCode);
      QRName = retrieveHelper(pc.qrName);
      // clinic = retrieveHelper(pc.clinic);
      location = retrieveHelper(pc.location);
      // if (paymentType === 'manual') {
      //   paymentPartner = JSON.parse(localStorage.getItem('paymentPartner'));
      // }
    } catch (err) {
      const employeeId = JSON.parse(localStorage.getItem('employeeId'));
      history.push(`/?id=${employeeId}`);
    }

    const payload = {
      provider_details: {
        // name: clinic.name,
        // provider_type: clinic.merchant_type,
        coordinates: location,
        // provider_id: clinic.provider_id,
        // address: clinic.address,
        provider_account_name: QRName,
        // mobile: clinic.mobile,
      },
      payment_upi_id: QRCode,
      coordinates: location,
      amount: amount.toString(),
    };

    storeHelper(pc.paidAmount, amount.toString());

    if (paymentType === 'manual') {
      payload['payment_upi_mode'] = 'provider_phone_number';
    } else {
      payload['payment_upi_mode'] = 'qr_scan';
    }

    registerEvent(ec.Transaction, actions.PAYMENT_VALIDATE_IN_PROCESS);

    setIsLoading(true);
    setBlurBackground(false);
    instance
      .post('/opd/payment/validate-request', payload)
      .then(response => {
        let data = response.data;
        if (data.status === 'success') {
          registerEvent(ec.Transaction, actions.PAYMENT_VALIDATE_DONE);
          initPaymentCall(data.response['authentication_token']);
        } else {
          if (
            data.message.includes('Please upload bill of last transaction.')
          ) {
            storeHelper(
              pc.lastTransactionId,
              data.response.last_transaction_id
            );
          }
          setIsLoading(false);
          setBlurBackground(false);
          setErrorMessage(data.message);
        }
      })
      .catch(err => {
        setIsLoading(false);
        setBlurBackground(false);
      });
  };

  const initPaymentCall = token => {
    const payload = {
      authentication_token: token,
    };
    registerEvent(ec.Transaction, actions.PAYMENT_REQUEST_INIT);
    instance
      .post('/opd/payment/init-payment', payload)
      .then(response => {
        setIsLoading(false);
        setBlurBackground(false);
        let data = response.data;
        if (data.status === 'success') {
          if (data.response.status === 'success') {
            registerEvent(ec.Transaction, actions.PAYMENT_STATUS_SUCCESS);
            storeHelper(pc.lastTransactionId, data.response.transaction_id);
            storeHelper(pc.balance, data.response.balance);
            storeHelper(pc.transactionStatus, 'success');
          } else if (data.response.status === 'pending') {
            registerEvent(ec.Transaction, actions.PAYMENT_STATUS_PENDING);
            storeHelper(pc.lastTransactionId, data.response.transaction_id);
            storeHelper(pc.transactionStatus, 'pending');
          } else {
            registerEvent(ec.Transaction, actions.PAYMENT_STATUS_FAILED);
            storeHelper(pc.transactionStatus, 'fail');
          }
          history.push(`/transactionStatus/${data.response.transaction_id}`);
        } else {
          registerEvent(ec.Transaction, actions.PAYMENT_STATUS_ERROR);
          setErrorMessage(data.message);
        }
      })
      .catch(err => {
        setIsLoading(false);
        setBlurBackground(false);
        const errMsg = parseErrorMessage(err);
        setErrorMessage(errMsg);
      });
  };

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  const paymentComponent = (
    <>
      <Box
        display="flex"
        flexDir="column"
        flex={1}
        mt={10}
        height="100%"
        width="90%"
        margin="auto"
        justifyContent="space-between"
        alignItems="center"
        className={blurBackground ? 'overlay' : ''}
      >
        <Box
          width="100%"
          p={5}
          borderRadius="5px"
          bgColor="white"
          boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
        >
          <TransactionForm
            isLoading={isLoading}
            amount={amount}
            setAmount={setAmount}
            blurBackground={blurBackground}
          />
        </Box>

        {!isLoading && (
          <Button
            isLoading={isLoading}
            loadingText="processing"
            isFullWidth
            minH={['40px']}
            _active={{
              bgColor: { primaryColor },
              color: 'white',
            }}
            _hover={{
              bgColor: { primaryColor },
              color: 'white',
            }}
            bgColor={primaryColor}
            color="white"
            variant="solid"
            size="md"
            disabled={Number(amount) === 0 || isNaN(Number(amount))}
            onClick={() => {
              if (shouldShowConfirmationPage) {
                setBlurBackground(true);
                setShowConfirmationPage(true);
              } else {
                handleTransactionCall();
              }
            }}
            mb="5vh"
          >
            <Text fontSize={px13}>Pay</Text>
          </Button>
        )}
      </Box>

      {isLoading && (
        <Box
          height={['62px']}
          position="fixed"
          bottom={0}
          maxW="500px"
          width="100%"
          borderTopRadius="10px"
          bgColor={primaryColor}
          display="flex"
          justifyContent="center"
        >
          <HStack
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            width="60%"
          >
            <Loader mt={0} color="white" size="md" />
            <Text fontSize={px13} color="white">
              Payment Processing
            </Text>
          </HStack>
        </Box>
      )}

      {showConfirmationPage && (
        <Box
          position="fixed"
          bottom={0}
          maxW="500px"
          width="100%"
          borderTopRadius="15px"
          bgColor="white"
          display="flex"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            width="70%"
            display="flex"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
          >
            <ListBookmarkIcon boxSize="55px" my={7} />
            <Text
              fontSize={px16}
              fontWeight="bold"
              color={primaryColor}
              textAlign="center"
            >
              Always ask for the bill and upload it after the transaction
            </Text>
          </Box>

          <Divider
            orientation="horizontal"
            color="#D5D5D5"
            width="95%"
            my={4}
          />

          <Box
            width="90%"
            display="flex"
            color={primaryText}
            fontSize={px13}
            px={2}
          >
            <OrderedList>
              <ListItem>
                Please ensure to collect the bill after the successful
                transaction.
              </ListItem>
              <ListItem>
                Upload the bill after the transaction to keep your account
                active.
              </ListItem>
              <ListItem>
                Talk to our support team if you don’t get the bill.
              </ListItem>
            </OrderedList>
          </Box>

          <Box width="90%">
            <Button
              isLoading={isLoading}
              loadingText="processing"
              isFullWidth
              minH={['40px']}
              _active={{
                bgColor: { primaryColor },
                color: 'white',
              }}
              _hover={{
                bgColor: { primaryColor },
                color: 'white',
              }}
              bgColor={primaryColor}
              color="white"
              variant="solid"
              size="md"
              disabled={Number(amount) === 0 || isNaN(Number(amount))}
              onClick={() => {
                setShowConfirmationPage(false);
                handleTransactionCall();
              }}
              mt={10}
              mb="2vh"
            >
              <Text fontSize={px13}>Proceed</Text>
            </Button>
          </Box>
        </Box>
      )}
    </>
  );

  return (
    <Box
      margin="auto"
      className="containerHeight"
      pt={5}
      bgColor={blurBackground ? 'gray.400' : '#EFEFEF'}
    >
      {paymentComponent}

      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          showUpload={false}
          onClose={() => {
            if (errorMessage.includes('Please upload bill ')) {
              setErrorMessage('');
              setShowAlertModal(false);
              history.push('/upload');
            } else {
              setErrorMessage('');
              setShowAlertModal(false);
            }
          }}
          message={errorMessage}
        />
      )}
    </Box>
  );
};

export default Transaction;
