import React from 'react';
import {
  Box,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from '@chakra-ui/react';

import { primaryColor, primaryText, px13, px14 } from '../../constants';
import { PhoneIconModal } from '../../../../assets/icons';
import BookDoctor from '../bookDoctor';


const BookConfirmModal = ({ isOpen, onClose, item }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent width={item.provider_type === 'doctors' ? 375 : 300}>
        <ModalBody>
          {
            item.provider_type === 'doctors' ?
            (
              <BookDoctor item={item} onClose={onClose} />
            ) :
            (
              <Box p={5}>
                <Box display="flex" justifyContent="center">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    boxSize={['75px']}
                    bgColor="#E6EFFF"
                    borderRadius="7px"
                  >
                    <PhoneIconModal boxSize={['45px']} />
                  </Box>
                </Box>
                <Text
                  textAlign="center"
                  fontSize={px14}
                  mt={2}
                  fontWeight="bold"
                  color={primaryText}
                >
                  {item.provider_type === 'pharmacy'
                    ? 'Order Now'
                    : 'Book Appointment'}{' '}
                  {item.provider_type === 'pharmacy' ? 'at' : 'with'} {item.name}
                </Text>
    
                <Box display="flex" flexDir="column" justifyContent="center" mt={8}>
                  <Button
                    _active={{
                      bgColor: { primaryColor },
                      color: 'white',
                    }}
                    _hover={{
                      bgColor: { primaryColor },
                      color: 'white',
                    }}
                    bgColor={primaryColor}
                    onClick={() => {
                      onClose();
                      document.location.href = `tel:${item.mobile}`;
                    }}
                    size="sm"
                    height={['40px']}
                    variant="solid"
                  >
                    <Text fontSize={px13} color="white">
                      Make call
                    </Text>
                  </Button>
    
                  <Text
                    as="button"
                    color={primaryColor}
                    mt={5}
                    fontSize={px13}
                    onClick={onClose}
                  >
                    Cancel
                  </Text>
                </Box>
              </Box>
            )
          }
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BookConfirmModal;
