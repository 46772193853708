import React, { useState } from 'react';
import { Box, Image, Text, Stack, HStack, Button } from '@chakra-ui/react';

import pendingLogo from './../../../../assets/images/pending.png';
import useAlertHook from '../../../scan/hooks/errorHook';
import AlertModal from './../../subComponents/alertModal/alertModal';
import { retrieveHelper, pc } from '../../../utils';
import { checkInInstance } from '../../../api';

const Pending = ({ data, setPage }) => {
  const [retryLoading, setRetryLoading] = useState(false);
  const { showAlertModal, setShowAlertModal, errorMessage, setErrorMessage } =
    useAlertHook();

  const handleCheckIn = () => {
    let payload = retrieveHelper(pc.retryPayload);

    setRetryLoading(true);

    checkInInstance
      .post('/scan-auth/authorization', payload)
      .then(response => {
        setRetryLoading(false);
        let data = response.data;
        if (data.status === 'success') {
          if (data.response.status === 'success') {
            setPage('success');
          } else if (data.response.status === 'pending') {
            setPage('pending');
          } else {
            setPage('failed');
          }
        } else {
          setErrorMessage(data.message);
        }
      })
      .catch(err => {
        setRetryLoading(false);
        setErrorMessage('Something went wrong');
      });
  };

  return (
    <Box
      height="100%"
      display="flex"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      mt={-10}
    >
      {data && (
        <>
          <Image
            width={['100px']}
            height={['100px']}
            objectFit="contain"
            src={pendingLogo}
            alt="pending"
          />
          <Text color="3C3732" fontSize="24px" fontWeight="bold">
            Check-in Pending
          </Text>

          <Box
            width="95%"
            // margin="auto"
            // bgColor="white"
            // borderRadius={10}
          >
            <Stack p={5} fontSize={['14px']} fontWeight="normal">
              <Box
                display="flex"
                justifyContent="center"
                flexDir="column"
                alignItems="center"
              >
                <HStack textAlign="left" width="100%" alignItems="start" my={1}>
                  <Text width="40%">Patient Name</Text>
                  <Text
                    width="60%"
                    fontWeight="bold"
                    color="#0059ff"
                    className="two-line-wrap"
                  >
                    {data.patient_name}
                  </Text>
                </HStack>

                <HStack textAlign="left" width="100%" alignItems="start" my={1}>
                  <Text width="40%">Visited At</Text>
                  <Text
                    width="60%"
                    fontWeight="bold"
                    color="#0059ff"
                    className="two-line-wrap"
                  >
                    {data.provider_name}
                  </Text>
                </HStack>

                <HStack textAlign="left" width="100%" alignItems="start" my={1}>
                  <Text width="40%">Address</Text>
                  <Text
                    width="60%"
                    fontWeight="bold"
                    color="#0059ff"
                    className="two-line-wrap"
                  >
                    {data.provider_address}
                  </Text>
                </HStack>

                <HStack textAlign="left" width="100%" alignItems="start" my={1}>
                  <Text width="40%">Time of Visit</Text>
                  <Text
                    width="60%"
                    fontWeight="bold"
                    color="#0059ff"
                    className="two-line-wrap"
                  >
                    {data.appointment_time}
                  </Text>
                </HStack>

                <HStack
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  my={2}
                  mt={5}
                >
                  {/* <Button
                    isLoading={cancelLoading}
                    loadingText="cancelling"
                    color="black"
                    borderWidth={1.5}
                    borderColor="#0059ff"
                    variant="outline"
                    size="xs"
                    onClick={handleCancel}
                  >
                    <Text
                      fontWeight="normal"
                      color="#3C3732"
                      fontSize={['11px']}
                    >
                      Cancel
                    </Text>
                  </Button> */}

                  <Button
                    isLoading={retryLoading}
                    loadingText="retrying"
                    onClick={handleCheckIn}
                    _active={{
                      bgColor: '#0059ff',
                      color: 'white',
                    }}
                    _hover={{
                      bgColor: '#0059ff',
                      color: 'white',
                    }}
                    minW={[100]}
                    bgColor="#0059ff"
                    color="white"
                    variant="solid"
                    size="xs"
                  >
                    <Text fontWeight="normal" fontSize={['11px']}>
                      Retry
                    </Text>
                  </Button>
                </HStack>
              </Box>
            </Stack>
          </Box>
        </>
      )}

      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          onClose={() => {
            setErrorMessage('');
            setShowAlertModal(false);
          }}
          message={errorMessage}
        />
      )}
    </Box>
  );
};

export default Pending;
