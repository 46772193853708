/* eslint-disable no-unused-vars */
const dev = {
  ENV: 'DEV',
  API_KEY: '7uwLpggw7g89MAxfI4qHO76jTuYRI3LY4yK377aZ',
  URL: 'https://api.dev.medpay.in/v1',
  FP_TOKEN: '95p2IpmADzHdb1ILR8Hc',
  FP_DOMAIN: 'https://metrics-dev.medpay.in',
  UA_CODE: 'UA-212220333-1',
  UA_CODE_INAPP: 'UA-212220333-3',
  PAYU_URL: 'https://test.payu.in/_payment',
  UA_CODE_CHECKIN: 'UA-212220333-5',
};

const prod = {
  ENV: 'PROD',
  API_KEY: 'IwqYmi3pfn9NNjrVeaSRZ2KNMKSuoLMD6mQbGIDi',
  URL: 'https://apis.medpay.in/v1',
  FP_TOKEN: 'KuFwAw3Ajb2TaiGJ93Sg',
  FP_DOMAIN: 'https://metrics.medpay.in',
  UA_CODE: 'UA-212220333-2',
  UA_CODE_INAPP: 'UA-212220333-4',
  PAYU_URL: 'https://secure.payu.in/_payment',
  UA_CODE_CHECKIN: 'UA-212220333-6',
};

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;

export default config;
