import React from 'react';
import { Box, Image } from '@chakra-ui/react';

import CameraScanner from './../../checkInUI/cameraScanner';
import MedPayLogoBlack from '../../../../assets/images/medpayLogoBlack.png';

const Scanner = ({ data, setPage }) => {
  return (
    <Box height="100vh">
      <Image src={MedPayLogoBlack} width="40%" marginLeft="30%" pt={2} pb={5} />
      <Box
        height="90%"
        display="flex"
        flexDir="column"
        justifyContent="space-between"
        alignItems="center"
        pb="5%"
      >
        <CameraScanner data={data} setPage={setPage} />
      </Box>
    </Box>
  );
};

export default Scanner;
