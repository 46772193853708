import { useState } from 'react';

const useAlertHook = () => {
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  return {
    showAlertModal,
    setShowAlertModal,
    errorMessage,
    setErrorMessage,
  };
};

export default useAlertHook;
