import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { Formik } from 'formik';
import { InputControl, SubmitButton } from 'formik-chakra-ui';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { loginInstance } from './../../api/index';
import useAlertHook from '../hooks/errorHook';
import AlertModal from '../subComponents/alertModal';
import { parseErrorMessage } from '../../utils';
// import logo from './../../images/LogoBG.png';

const LoginForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { showAlertModal, setShowAlertModal, errorMessage, setErrorMessage } =
    useAlertHook();

  let history = useHistory();

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  const onSubmit = values => {
    setIsLoading(true);
    let payload = {
      user_id: values.username,
      passcode: values.password,
      client_name: 'orders-dashboard',
    };

    setIsLoading(true);
    loginInstance
      .post('/auth/login', payload)
      .then(response => {
        setIsLoading(false);
        if (response.data.status === 'success') {
          let data = response.data.response;
          const onboardingPartnerId = data.onboarding_partner_id;
          const limit = data.limit;
          const payer = data.payer;
          const billingDay = data.billing_day;

          if (onboardingPartnerId && limit && payer && billingDay) {
            let onboardingData = {
              onboarding_partner_id: onboardingPartnerId,
              limit: limit,
              payer: payer,
              billing_day: billingDay,
            };

            onboardingData = window.btoa(JSON.stringify(onboardingData));

            localStorage.setItem(
              'loggedInUser',
              JSON.stringify(response.data.response)
            );
            localStorage.setItem('loggedIn', JSON.stringify(true));
            history.push(`/otc-onboarding?data=${onboardingData}`);
          } else {
            setErrorMessage('Please contact MedPay or use right credentials.');
          }
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch(error => {
        setIsLoading(false);
        const errMsg = parseErrorMessage(error);
        setErrorMessage(errMsg);
      });
  };

  const initialValues = {
    username: '',
    password: '',
  };

  const validationSchema = Yup.object({
    username: Yup.string().required('Username is required!'),
    password: Yup.string().required('Password is required!'),
  });

  return (
    <Box width={['90%']}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, values, errors }) => (
          <Box
            borderWidth="1px"
            rounded="lg"
            shadow="1px 1px 3px rgba(0,0,0,0.3)"
            maxWidth={600}
            p={6}
            m="10px auto"
            mt="15%"
            as="form"
            onSubmit={handleSubmit}
          >
            <InputControl name="username" label="Username" isRequired />
            <InputControl
              name="password"
              label="Password"
              inputProps={{ type: 'password' }}
              isRequired
            />

            <Box marginTop={5} display="flex" justifyContent="center">
              <SubmitButton
                backgroundColor="#0059ff"
                color="white"
                _hover={{
                  backgroundColor: '#0059ff',
                }}
                _active={{
                  backgroundColor: '#0059ff',
                }}
                isLoading={isLoading}
              >
                Login
              </SubmitButton>
            </Box>
          </Box>
        )}
      </Formik>

      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          message={errorMessage}
          onClose={() => {
            setErrorMessage('');
            setShowAlertModal(false);
          }}
        />
      )}
    </Box>
  );
};

export default LoginForm;
