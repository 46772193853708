import React, { useState, useRef, useEffect } from 'react';
import { Box, Image, Button, Text, HStack } from '@chakra-ui/react';
import Webcam from 'react-webcam';
import { useLocation } from 'react-router';

import useAlertHook from '../../hooks/errorHook';
import AlertModal from './../../subComponents/alertModal/alertModal';
import { instance } from '../../../api';
import { compressFile } from './utils';
import SuccessUpload from './successUpload';
import FailUpload from './failUpload';
import {
  retrieveHelper,
  pc,
  storeHelper,
  parseErrorMessage,
  registerEvent,
  ec,
  actions,
} from '../../../utils';
import { primaryColor, px13, px14 } from '../../constants';
import './index.css';
import { useHistory } from 'react-router-dom';
import { TorchIcon } from '../../../../assets/icons';

const WebCamUISingle = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [state, setState] = useState('upload');

  const location = useLocation();
  const isSmallScreen = window.screen.width < 325;

  const { showAlertModal, setShowAlertModal, errorMessage, setErrorMessage } =
    useAlertHook();

  const webcamRef = useRef(null);
  const history = useHistory();

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: 'environment',
  };

  const captureImage = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const image = await compressFile(imageSrc);
    setCapturedImage(image);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webcamRef]);

  const deleteImage = () => {
    setCapturedImage(null);
  };

  const handleUploadFromGallery = file => {
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async function () {
      const image = await compressFile(reader.result);
      setCapturedImage(image);
    };
    reader.onerror = function (error) {
      setErrorMessage(error);
    };
  };

  const dataUrlToFile = async (dataUrl, fileName) => {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
  };

  const handleUploadImage = async () => {
    let lastTransactionId = retrieveHelper(pc.lastTransactionId);
    let params = new URLSearchParams(location.search);
    let paramTId = params.get('transaction_id');

    if (paramTId != null) {
      lastTransactionId = paramTId;
    }

    registerEvent(ec.Upload, actions.BILL_UPLOAD_INIT);

    setIsLoading(true);
    let formData = new FormData();
    let file = await dataUrlToFile(capturedImage, 'bill.png');
    formData.append('file', file);
    formData.append('transaction_id', lastTransactionId);

    instance
      .post('/opd/payment/bill-upload', formData)
      .then(response => {
        setIsLoading(false);
        let data = response.data;
        if (data.status === 'success') {
          registerEvent(ec.Upload, actions.BILL_UPLOAD_SUCCESS);
          setState('success');
        } else {
          registerEvent(ec.Upload, actions.BILL_UPLOAD_FAILED);
          setErrorMessage(data.message);
          setState('fail');
        }
      })
      .catch(err => {
        registerEvent(ec.Upload, actions.BILL_UPLOAD_ERROR);
        setIsLoading(false);
        let errMsg = parseErrorMessage(err);
        if (errMsg.includes('No internet')) {
          errMsg = 'Please upload file less or equal to 1MB in size.';
        }
        setErrorMessage(errMsg);
      });
  };

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  useEffect(() => {
    let employeeId = retrieveHelper(pc.employeeId);

    if (employeeId == null) {
      let params = new URLSearchParams(location.search);
      employeeId = params.get('id');
      if (employeeId != null) {
        storeHelper(pc.employeeId, employeeId);
      } else {
        history.push('/');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {state === 'upload' ? (
        capturedImage != null ? (
          <Box
            bgColor="rgb(0,0,0,0.7)"
            className="containerHeight"
            display="flex"
            justifyContent="center"
            overflow="scroll"
          >
            <Box
              width="90%"
              display="flex"
              flexDir="column"
              justifyContent="space-between"
              alignItems="center"
              // overflow="scroll"
            >
              <Box
                display="flex"
                flexDir="column"
                alignItems="center"
                justifyContent="center"
                // overflow="scroll"
              >
                <Text fontSize={px14} fontWeight="bold" color="white" py={3}>
                  Upload Bill
                </Text>
                <Box
                  height={isSmallScreen ? '65vh' : '75vh'}
                  width="100%"
                  overflow="scroll"
                >
                  <Image
                    height={isSmallScreen ? '65vh' : '75vh'}
                    alt="bill"
                    objectFit="contain"
                    src={capturedImage}
                  />
                </Box>
              </Box>

              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                flexDir="column"
                width="100%"
                mb="3vh"
                mt={5}
              >
                <Button
                  isLoading={isLoading}
                  isFullWidth
                  minH={['40px']}
                  _active={{
                    bgColor: { primaryColor },
                    color: 'white',
                  }}
                  _hover={{
                    bgColor: { primaryColor },
                    color: 'white',
                  }}
                  bgColor={primaryColor}
                  color="white"
                  variant="solid"
                  size="md"
                  onClick={handleUploadImage}
                >
                  <Text fontSize={px13}>Upload</Text>
                </Button>

                <Box
                  as="button"
                  height={['40px']}
                  fontSize={px13}
                  onClick={deleteImage}
                >
                  <Text color="white">Retake</Text>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            bgColor="rgb(0,0,0,0.7)"
            className="containerHeight"
            overflow="scroll"
          >
            <Box
              display="flex"
              flexDir="column"
              justifyContent="space-between"
              alignItems="center"
              height="100%"
              width="100%"
              margin="auto"
              // overflow="scroll"
            >
              <Box
                display="flex"
                flexDir="column"
                justifyContent="center"
                alignItems="center"
                // overflow="scroll"
              >
                <HStack
                  width="100%"
                  px={'5%'}
                  height="40px"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  bgColor="blackAlpha.500"
                >
                  <Box width={['50px']}>
                    <TorchIcon display="none" />
                  </Box>
                  <Text fontSize={px14} fontWeight="bold" color="white" my={3}>
                    Upload Bill
                  </Text>

                  <Box
                    as="button"
                    bgColor="white"
                    height="25px"
                    width="50px"
                    display="flex"
                    borderRadius={['5px']}
                    justifyContent="center"
                    alignItems="center"
                    onClick={() => {
                      const employeeId = retrieveHelper(pc.employeeId);
                      history.push(`/?id=${employeeId}`);
                    }}
                  >
                    <Text
                      textAlign="center"
                      fontSize={px14}
                      fontWeight="bold"
                      color="black"
                    >
                      Skip
                    </Text>
                  </Box>
                </HStack>

                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width="90%"
                  screenshotQuality={1}
                  videoConstraints={videoConstraints}
                  forceScreenshotSourceSize={true}
                />
              </Box>

              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                flexDir="column"
                width="90%"
                mb="3vh"
                mt={2}
              >
                <Button
                  isLoading={isLoading}
                  isFullWidth
                  minH={['40px']}
                  _active={{
                    bgColor: { primaryColor },
                    color: 'white',
                  }}
                  _hover={{
                    bgColor: { primaryColor },
                    color: 'white',
                  }}
                  bgColor={primaryColor}
                  color="white"
                  variant="solid"
                  size="md"
                  onClick={captureImage}
                >
                  <Text fontSize={px13}>Capture</Text>
                </Button>

                <Box
                  as="button"
                  height={['40px']}
                  fontSize={px13}
                  onClick={() => {
                    document.getElementById('uploadGallery').click();
                  }}
                >
                  <input
                    type="file"
                    id="uploadGallery"
                    name="bill"
                    accept="image/png, image/jpeg"
                    style={{ display: 'none' }}
                    onChange={e => {
                      registerEvent(
                        ec.Upload,
                        actions.BILL_SELECT_FROM_GALLERY
                      );
                      handleUploadFromGallery(e.target.files[0]);
                    }}
                  />
                  <Text color="white">Upload from Gallery</Text>
                </Box>
              </Box>
            </Box>
          </Box>
        )
      ) : state === 'success' ? (
        <SuccessUpload />
      ) : (
        <FailUpload setState={setState} />
      )}

      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          onClose={() => {
            setErrorMessage('');
            setShowAlertModal(false);
          }}
          message={errorMessage}
        />
      )}
    </>
  );
};

export default WebCamUISingle;
