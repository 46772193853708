import React, { useEffect } from 'react';
import { Box, Button } from '@chakra-ui/react';
import ScannerCamera from '../../scanUI/scannerCamera';
import useAlertHook from '../../../scan-inApp/hooks/errorHook';
import { useLocation } from 'react-router-dom';
import { storeHelper, pc } from '../../../utils';
import AlertModal from '../../../scan-inApp/subComponents/alertModal/alertModal';
import { registerEvent, ec, actions } from '../../../utils';

const Scanner = () => {
  const { showAlertModal, setShowAlertModal, errorMessage, setErrorMessage } =
    useAlertHook();

  const location = useLocation();

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  useEffect(() => {
    if (sessionStorage.getItem('inAppLocationAsked') === null) {
      document.getElementById('locationButton').click();
    }
    localStorage.clear();
    const params = new URLSearchParams(location.search);
    let data = params.get('data');

    try {
      storeHelper(pc.inAppDataURL, data);
      data = JSON.parse(window.atob(data));

      const location = data['coordinates'];
      const authToken = data['authentication_token'];
      const transaction_id = data['transaction_id'];

      storeHelper(pc.location, location);
      storeHelper(pc.authToken, authToken);
      storeHelper(pc.transaction_id, transaction_id);

      if (transaction_id == null || transaction_id === '') {
        setErrorMessage('Please use correct link.');
        return;
      }
    } catch (error) {
      // console.log(error);
      setErrorMessage('Please use correct URL.');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showPosition = position => {
    let searchLocation = '';
    let params = new URLSearchParams(location.search);
    searchLocation = params.get('location');
    if (searchLocation == null) {
      searchLocation = `${position.coords.latitude},${position.coords.longitude}`;
    }
    registerEvent(ec.Home, actions.LOCATION_FOUND);
    storeHelper(pc.payment_location, searchLocation);
  };

  const onLocationErr = err => {
    registerEvent(ec.Home, actions.LOCATION_ERROR);
    setErrorMessage('Please enable location permissions.');
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      registerEvent(ec.Home, actions.LOCATION_INIT);
      let searchLocation = '';

      let params = new URLSearchParams(location.search);
      searchLocation = params.get('location');

      if (searchLocation != null) {
        // console.log('in if');
        registerEvent(ec.Home, actions.LOCATION_FOUND);
        storeHelper(pc.payment_location, searchLocation);
      } else {
        // console.log('in else');
        navigator.geolocation.getCurrentPosition(showPosition, onLocationErr);
      }
      sessionStorage.setItem('inAppLocationAsked', 'true');
    } else {
      setErrorMessage('Geolocation is not supported by this browser.');
    }
  };

  return (
    <Box>
      <Button
        id="scannerError"
        display="none"
        onClick={() => setShowAlertModal(true)}
      ></Button>
      <Button
        id="locationButton"
        display="none"
        onClick={() => getLocation()}
      ></Button>
      {errorMessage.trim() === '' && <ScannerCamera />}
      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          message={errorMessage}
          onClose={() => {
            if (errorMessage.includes('enable location')) {
              window.location.reload();
              return;
            }
            setShowAlertModal(false);
            setTimeout(() => {
              document.getElementById('scannerError').click();
            }, 0);
          }}
        />
      )}
    </Box>
  );
};

export default Scanner;
