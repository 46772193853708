import React from 'react';

import { Box, Text } from '@chakra-ui/react';
import Loader from '../../../subComponents/loader';

import Card from './card';

const ListingCards = ({ data, isSearchLoading }) => {
  return (
    <Box width={['100%']}>
      {!isSearchLoading &&
        data.map((item, index) => (
          <Card item={item} key={item.location_id} index={index} />
        ))}

      {isSearchLoading && <Loader />}

      {data.length === 0 && (
        <Text mt={20} textAlign="center">
          No result
        </Text>
      )}
    </Box>
  );
};

export default ListingCards;
