import React from 'react';
import { Box, Image, Text, Button, Stack } from '@chakra-ui/react';

import failLogo from './../../../../assets/images/fail.png';
import { primaryText, px13, px16, tealGreenColor } from '../../constants';
// import { useHistory } from 'react-router-dom';
// import { retrieveHelper, pc } from './../../../utils';
import MfineLogoBlack from '../../../../assets/images/mfine.svg';
import PoweredBy from '../../subComponents/poweredBy';

const FailUpload = ({ setState }) => {
  // const history = useHistory();

  return (
    <Box
      width="100%"
      margin="auto"
      p={5}
      bgColor="white"
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      className="containerHeight"
      style={{ backgroundColor: '#fff', padding: '5% 5% 0 5%' }}
    >
      <Box>
        <Image
          margin="0 30%"
          width="40%"
          src={MfineLogoBlack}
          alt="logo_black"
        />
      </Box>
      <Box
        borderRadius={['5px']}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          width={['85px']}
          height={['85px']}
          objectFit="cover"
          src={failLogo}
          alt="failed"
        />
        <Text className="poppins fw-600" color={primaryText} fontSize={px16}>
          Documents Upload Failed
        </Text>
        <Stack fontSize={px13} pt={5}>
          <Text className="poppins fw-500" color={primaryText}>
            Please try again
          </Text>
        </Stack>
      </Box>

      <Box>
        <Button
          isFullWidth
          minH={['40px']}
          _active={{
            bgColor: { tealGreenColor },
            color: 'white',
          }}
          _hover={{
            bgColor: { tealGreenColor },
            color: 'white',
          }}
          bgColor={tealGreenColor}
          color="white"
          variant="solid"
          size="md"
          onClick={() => {
            setState('upload');
          }}
          mb="1vh"
        >
          <Text className="poppins fw-600" fontSize={px13}>
            Try Again
          </Text>
        </Button>
        <PoweredBy logoType="black" />
      </Box>
    </Box>
  );
};

export default FailUpload;
