import React from 'react';
import { Box } from '@chakra-ui/react';
import WebCamUIMultiple from '../../scanUI/uploadBillUI/webCamUIMultiple';
// import WebCamExperiment from '../../scanUI/uploadBillUI/webCamExperiment';

const UploadBill = () => {
  return (
    <Box>
      <WebCamUIMultiple />
      {/* <WebCamExperiment /> */}
    </Box>
  );
};

export default UploadBill;
