import React from 'react';
import {
  Box,
  // Text,
  Image,
  // VStack
} from '@chakra-ui/react';

import successLogo from './../../../../assets/images/success_new.png';
import pendingLogo from './../../../../assets/images/pending.png';
import failLogo from './../../../../assets/images/fail.png';

const TransactionStatus = ({ state }) => {
  const getTransactionLogo = state => {
    if (state === 'success') {
      return successLogo;
    } else if (state === 'fail') {
      return failLogo;
    } else if (state === 'pending') {
      return pendingLogo;
    } else {
      return pendingLogo;
    }
  };

  return (
    <Box
      textAlign="center"
      display="flex"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
    >
      <Image
        //mr={state === 'success' ? '3.5%' : '0%'}
        boxSize={['85px']}
        objectFit="cover"
        src={getTransactionLogo(state)}
        alt="entity type"
      />
    </Box>
  );
};

export default TransactionStatus;
