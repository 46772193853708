import React, { useEffect } from 'react';
import { Box, Image } from '@chakra-ui/react';
import useAlertHook from '../../hooks/errorHook';
import AlertModal from './../../subComponents/alertModal/alertModal';

const PaymentCard = ({
  option,
  selectedMethod,
  setSelectedMethod,
  upiID,
  isLoading,
}) => {
  const { errorMessage, setErrorMessage, showAlertModal, setShowAlertModal } =
    useAlertHook();

  const isSelected = option.name === selectedMethod.name;
  const isDisabled = upiID.trim() === '' || upiID.length < 10;

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  return (
    <Box
      // p={5}
      mx={5}
      bgColor={'white'}
      borderRadius={10}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      onClick={() => {
        if (!isLoading) {
          if (!isDisabled) {
            setSelectedMethod(option);
          }
        }
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
        borderRadius="5px"
        p={5}
      >
        <Box
          width={5}
          height={5}
          bgColor={isDisabled ? 'gray.500' : 'white'}
          border="2px solid #748094"
          borderRadius="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            width={3}
            height={3}
            bgColor={isSelected ? 'gray.500' : 'white'}
            border="2px solid #748094"
            borderRadius="50%"
          ></Box>
        </Box>
        <Box>
          <Image height={7} src={option.image} alt="payment" />
        </Box>
        <Box
          width={5}
          height={5}
          bgColor="black"
          borderRadius="50%"
          opacity={0}
        ></Box>
      </Box>

      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          onClose={() => {
            setErrorMessage('');
            setShowAlertModal(false);
          }}
          message={errorMessage}
        />
      )}
    </Box>
  );
};

export default PaymentCard;
