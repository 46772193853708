import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import MedpayLogoWhite from '../../../../assets/images/medpayLogo.png';
import MedpayLogoBlack from '../../../../assets/images/medpayLogoBlack.png';

const PoweredBy = ({ logoType }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" mb="1vh">
      <Text className="poppins fw-500" fontSize="14px">
        Powered by
      </Text>
      <img
        alt="logo"
        src={logoType === 'black' ? MedpayLogoBlack : MedpayLogoWhite}
        style={{ padding: '5px 10px', width: '100px' }}
      />
    </Box>
  );
};

export default PoweredBy;
