import React, { useEffect, useState, useRef } from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router';
import QrReader from 'react-qr-reader';
import MfineLogoWhite from '../../../../assets/images/mfine-white.svg';

import {
  storeHelper,
  pc,
  parseErrorMessage,
  registerEvent,
  ec,
  actions,
} from '../../../utils';
import { inAppInstance } from '../../../api';
import useAlertHook from '../../hooks/errorHook';
import './index.css';
import { TorchIcon } from '../../../../assets/icons';
// import { BackButton } from '../../subComponents/button';
import QRErrorModal from '../../subComponents/alertModal/qrErrorModal';
import Loader from './../../subComponents/loader';
import {
  //  px13,
  px14,
  //  px16,
} from '../../constants';
import PoweredBy from '../../subComponents/poweredBy';

const ScannerCamera = () => {
  const qrReader = useRef(null);
  const history = useHistory();

  const [result, setResult] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [supported, setSupported] = useState(true);

  const { showAlertModal, setShowAlertModal, errorMessage, setErrorMessage } =
    useAlertHook();

  useEffect(() => {
    // console.log('mounted');
    setIsMounted(true);

    return () => {
      // console.log('unmounted');
      // qrReader.current.stopCamera();
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  useEffect(() => {
    if (result != null) {
      let extractedCode = result;

      setIsLoading(true);

      registerEvent(ec.Scanner, actions.QR_VALIDATE_INIT);

      const payload = {
        payment_qr_code: extractedCode,
      };

      inAppInstance
        .post('/opd/offline/cashless/payment/validate-qr', payload)
        .then(response => {
          sessionStorage.removeItem('inAppLocationAsked');
          setIsLoading(false);
          let data = response.data;
          if (data.status === 'success') {
            registerEvent(ec.Scanner, actions.QR_VALIDATE_SUCCESS);
            storeHelper(pc.paymentType, 'scan');
            if (data.response.is_valid_health_care_mcc) {
              if (data.response.provider_account_name == null) {
                storeHelper(pc.qrName, '');
              } else {
                storeHelper(pc.qrName, data.response.provider_account_name);
              }
              storeHelper(pc.qrCode, data.response.payment_upi_id);
              history.push('/inAppTransaction');
            } else {
              setErrorMessage(
                'Merchant is not authorized for this transaction.'
              );
            }
          } else {
            setErrorMessage(data.message);
          }
        })
        .catch(err => {
          setIsLoading(false);
          const errMsg = parseErrorMessage(err);
          setErrorMessage(errMsg);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  return (
    <>
      <Box display="flex" justifyContent="center" className="containerHeight">
        <Box
          width="100%"
          overflow="clip"
          position="relative"
          bgColor="rgba(0,0,0,0.8)"
          maxWidth="500px"
        >
          <Box backgroundColor="#38434E">
            <img
              alt="logo"
              src={MfineLogoWhite}
              style={{ width: '30%', margin: '0 35%', padding: '10px 0' }}
            />
          </Box>
          {isMounted &&
            (isLoading ? (
              <Loader mt="45%" color="white" />
            ) : (
              <Box>
                <QrReader
                  ref={qrReader}
                  delay={10}
                  legacyMode={!supported}
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  facingMode="environment"
                  showViewFinder={false}
                  onError={err => {
                    setSupported(false);
                  }}
                  onScan={data => {
                    setResult(data);
                  }}
                  className="scannerParent"
                />
              </Box>
            ))}

          <Box
            className="element"
            height="100%"
            maxWidth="500px"
            marginTop="15%"
          >
            <Box width="90%" height="40%" marginTop="5%">
              <Box display="flex" justifyContent="space-between" height="100%">
                <Box
                  display="flex"
                  flexDir="column"
                  justifyContent="space-between"
                >
                  <Box
                    w="45px"
                    h="37px"
                    borderLeft="5px solid #2297BE"
                    borderTop="5px solid #2297BE"
                    borderTopLeftRadius="25%"
                  ></Box>
                  <Box
                    w="45px"
                    h="37px"
                    borderLeft="5px solid #2297BE"
                    borderBottom="5px solid #2297BE"
                    borderBottomLeftRadius="25%"
                  ></Box>
                </Box>
                <Box
                  display="flex"
                  flexDir="column"
                  justifyContent="space-between"
                >
                  <Box
                    w="45px"
                    h="37px"
                    borderRight="5px solid #2297BE"
                    borderTop="5px solid #2297BE"
                    borderTopRightRadius="25%"
                  ></Box>
                  <Box
                    w="45px"
                    h="37px"
                    borderRight="5px solid #2297BE"
                    borderBottom="5px solid #2297BE"
                    borderBottomRightRadius="25%"
                  ></Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            height="auto"
            color="white"
            style={{
              position: 'fixed',
              bottom: '0',
              backgroundColor: 'transparent',
              width: '100%',
              maxWidth: '500px',
            }}
          >
            <Stack display="flex" justifyContent="center" alignItems="center">
              <PoweredBy logoType="white" />
              <TorchIcon boxSize={10} display="none" />
              <Box
                style={{
                  backgroundColor: '#38434E',
                  width: '100%',
                  textAlign: 'center',
                  marginTop: 0,
                }}
              >
                <Text className="poppins fw-500" fontSize="18px" color>
                  Scan any QR code to pay
                </Text>
                <Text
                  className="poppins fw-500"
                  fontSize={px14}
                  style={{ marginBottom: '8px' }}
                >
                  Google Pay . PhonePe . PayTM . UPI{' '}
                </Text>
              </Box>
            </Stack>

            {/* <Stack textAlign="center">
                <Text fontSize={px13}>QR code not available?</Text>
                <Text
                  as="button"
                  fontSize={px14}
                  textDecoration="underline"
                  fontWeight="bold"
                  onClick={() => {
                    registerEvent(ec.Scanner, actions.MANUAL_PAYMENT_METHOD);
                    history.push('/pay');
                  }}
                >
                  Click here for more options
                </Text>
              </Stack> */}

            {/* <BackButton
                onClick={() => {
                  registerEvent(ec.Scanner, actions.BACK_NAVIGATION);
                  history.goBack();
                }}
              /> */}
          </Box>
        </Box>
      </Box>

      {showAlertModal && (
        <QRErrorModal
          isOpen={showAlertModal}
          message={errorMessage}
          onClose={() => {
            setErrorMessage('');
            setShowAlertModal(false);
          }}
        />
      )}
    </>
  );
};

export default ScannerCamera;
