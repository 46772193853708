import React from 'react';
import { Box, Text, Button, Image } from '@chakra-ui/react';
import { CheckInErrorIcon } from '../../../../assets/icons';
import MedPayLogoWhite from '../../../../assets/images/medpayLogo.png';

const Failed = () => {
  return (
    <Box backgroundColor="#0058ff">
      <Image src={MedPayLogoWhite} width="40%" marginLeft="30%" pt={2} />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#0058ff',
        }}
      >
        <Box textAlign="center">
          <CheckInErrorIcon width={['80px']} height={['80px']} />
          <Text
            color="#FFF"
            fontSize="24px"
            fontWeight="700"
            fontFamily="'Mulish', sans-serif"
          >
            Check-in Failed
          </Text>
          <Button
            fontFamily="'Mulish', sans-serif"
            fontWeight="800"
            color="#0058ff"
            mt={10}
            onClick={() => {
              document.location.href = `tel:08045691331`;
            }}
          >
            Contact Support
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Failed;
