import { ColorModeScript } from '@chakra-ui/react';
import { Box, Image, Text, Button, ChakraProvider } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import 'polyfill-object.fromentries';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';

import chromeIcon from './assets/images/chrome.png';
// import playStoreIcon from './assets/images/playStore.png';

import customTheme from './components/theme/theme';
import './index.css';
import { primaryColor, cancelText } from './components/scan/constants';

const ua = navigator.userAgent;
const isMIUI = ua.toLowerCase().includes('miui');

// fetch('https://webhook.site/4cb78b6f-c503-486f-9975-80e40e7b94ce', {
//   method: 'POST', // or 'PUT'
//   headers: {
//     'Content-Type': 'application/json',
//   },
//   body: JSON.stringify({ ua: ua, miui: ua.toLowerCase().includes('miui') }),
// })
//   .then(response => response.json())
//   .then(data => {
//     console.log('Success:', data);
//   })
//   .catch(error => {
//     console.error('Error:', error);
//   });

let main = (
  <StrictMode>
    <ColorModeScript />
    <Router>
      <App />
    </Router>
  </StrictMode>
);

const url = window.location.href.split('//')[1];

const chromeURL = `intent://${url}/#Intent;scheme=https;package=com.android.chrome;end`;

// eslint-disable-next-line no-unused-vars
function loadURL() {
  window.location.replace(chromeURL);

  setTimeout(() => {
    window.location.replace('market://details?id=com.android.chrome');
  }, 2000);
}

// const chromeUI = (
//   <div
//     style={{
//       display: 'flex',
//       flexDirection: 'column',
//       justifyContent: 'center',
//       alignItems: 'center',
//       marginTop: '30%',
//     }}
//     // onLoad={() => loadURL()}
//   >
//     <div
//       style={{
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'center',
//         alignItems: 'center',
//       }}
//       onClick={() => {
//         loadURL();
//       }}
//     >
//       <div>
//         <img src={chromeIcon} width="50px" alt="chrome" />
//       </div>
//       <div style={{ marginBottom: '-10px' }}>
//         <p
//           style={{
//             color: 'blue',
//             textDecoration: 'underline',
//             fontSize: '16px',
//             fontWeight: 'bold',
//           }}
//         >
//           Open in Chrome
//         </p>
//       </div>
//     </div>

//     <br />

//     <p style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold' }}>
//       We currently don’t support this browser!
//     </p>
//   </div>
// );

const chromeUI2 = (
  <Box height="100vh" width="100%" bgColor="rgba(0, 0, 0, 0.7)" pt="10%">
    <Box width="80%" margin="auto" bgColor="white" borderRadius="5px" p={10}>
      <Box
        display="flex"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          boxSize="65px"
          objectFit="cover"
          src={chromeIcon}
          alt="Chrome"
          rounded
        />

        <Text fontSize="14px" pt={2} fontWeight="bold">
          Open link in chrome browser
        </Text>

        <Box display="flex" flexDir="column" justifyContent="center" mt={5}>
          <Button
            bgColor={primaryColor}
            color="white"
            _hover={{
              bgColor: { primaryColor },
              color: 'white',
            }}
            _active={{
              bgColor: { primaryColor },
              color: 'white',
            }}
            onClick={() => {}}
            size="sm"
            height={['30px']}
            variant="solid"
          >
            <Text fontSize={['11px']} fontWeight="bold">
              Open In Chrome
            </Text>
          </Button>

          <Text
            as="button"
            color={cancelText}
            mt={3}
            fontSize={['11px']}
            fontWeight="bold"
            onClick={() => {}}
          >
            Cancel
          </Text>
        </Box>
      </Box>
    </Box>
  </Box>
);

if (isMIUI) {
  main = chromeUI2;
}

ReactDOM.render(
  <ChakraProvider theme={customTheme}>{main}</ChakraProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
