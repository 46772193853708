import React, { useEffect, useState } from 'react';
import { Box, Text, Image, Stack, Button } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';

import successImage from './../../../../assets/images/success.png';
import { retrieveHelper, pc, parseErrorMessage } from '../../../utils';
import { primaryText, primaryColor, px16, px12, px13 } from '../../constants';
import { onboardInstance } from '../../../api';
import useAlertHook from '../../hooks/errorHook';
import AlertModal from '../../subComponents/alertModal/alertModal';

const BillPayment = () => {
  const [data, setData] = useState(null);

  const {
    showAlertModal,
    setShowAlertModal,
    errorMessage,
    setErrorMessage,
  } = useAlertHook();

  const history = useHistory();
  const location = useLocation();

  const handleSettlement = data => {
    onboardInstance
      .post('/opd/payment/settlement/status', data)
      .then(response => {
        let data = response.data;
        if (data.status === 'success') {
        } else {
          setErrorMessage(data.message);
        }
      })
      .catch(err => {
        const errMsg = parseErrorMessage(err);
        setErrorMessage(errMsg);
      });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    try {
      const data = JSON.parse(window.atob(params.get('data')));
      setData(data);
      handleSettlement(data);
    } catch (err) {
      setErrorMessage('Please use correct url!');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  return (
    <Box className="containerHeight">
      <Box
        display="flex"
        flexDir="column"
        flex={1}
        mt={10}
        height="100%"
        width="90%"
        margin="auto"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          width="100%"
          mt={5}
          p={5}
          bgColor="white"
          borderRadius="5px"
          boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
        >
          <Box
            textAlign="center"
            display="flex"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              mr="3.5%"
              boxSize={['85px']}
              objectFit="cover"
              src={successImage}
              alt="error"
            />
          </Box>

          <Stack color={primaryText} textAlign="center">
            <Stack spacing={0} mt={5} fontSize={px16} fontWeight="bold">
              <Text>Payment Successfull!</Text>
            </Stack>
            <Stack spacing={0} pt={5} fontSize={px12}>
              <Text>{data && <>Amount - ₹{data.amount}</>}</Text>
            </Stack>
          </Stack>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          flexDir="column"
          width="100%"
          mb="2vh"
        >
          <Button
            isFullWidth
            minH={['40px']}
            disabled
            _active={{
              bgColor: { primaryColor },
              color: 'white',
            }}
            _hover={{
              bgColor: { primaryColor },
              color: 'white',
            }}
            bgColor={primaryColor}
            color="white"
            variant="solid"
            size="md"
            onClick={() => window.location.reload()}
          >
            <Text fontSize={px13}>Paid</Text>
          </Button>

          <Box
            as="button"
            height={['40px']}
            fontSize={px13}
            onClick={() => {
              const employeeId = retrieveHelper(pc.employeeId);
              history.push(`/?id=${employeeId}`);
            }}
          >
            <Text color={primaryColor}>Back Home</Text>
          </Box>
        </Box>
      </Box>

      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          message={errorMessage}
          onClose={() => {
            setErrorMessage('');
            setShowAlertModal(false);
          }}
        />
      )}
    </Box>
  );
};

export default BillPayment;
