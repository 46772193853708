import React from 'react';
import { Box, Spinner } from '@chakra-ui/react';

const Loader = ({ size = 'md', mt = 20, color = 'black' }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" mt={mt}>
      <Spinner size={size} color={color} />
    </Box>
  );
};

export default Loader;
