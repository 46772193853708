import React from 'react';
import { Box } from '@chakra-ui/react';
import ScannerCamera from '../../scanUI/scannerCamera';

const Scanner = () => {
  return (
    <Box>
      <ScannerCamera />
    </Box>
  );
};

export default Scanner;
