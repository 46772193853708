import jsPDF from 'jspdf';
// import PDFMerger from 'pdf-merger-js';
import { PDFDocument } from 'pdf-lib';

const getImageDimension = image => {
  return new Promise((resolve, reject) => {
    const imageFile = new Image();
    imageFile.src = image;

    imageFile.onload = () => {
      const width = imageFile.width;
      const height = imageFile.height;
      const size = (height * width * 3) / 8;
      resolve({ height, width, size, imageFile });
    };
    imageFile.onerror = error => reject(error);
  });
};

const SIZE_CHECK = 300;

export const compressFile = async image => {
  let { height, width, size, imageFile } = await getImageDimension(image);
  let sizeInKB = parseInt(size / (1024 * 8));
  // console.log(height, width, sizeInKB);

  // console.log('size in KB', sizeInKB);
  if (sizeInKB > SIZE_CHECK) {
    // console.log('compressing now');
    const ratio = sizeInKB / SIZE_CHECK;
    // console.log(ratio);
    const newHeight = height / ratio;
    const newWidth = width / ratio;
    // console.log(newWidth, newHeight);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = newWidth;
    canvas.height = newHeight;
    ctx.drawImage(imageFile, 0, 0, newWidth, newHeight);
    return canvas.toDataURL('image/jpeg', 0.5);

    // const newDimnsions = await getImageDimension(newImage);
    // sizeInKB = parseInt(newDimnsions.size / (1024 * 8));
    // console.log(
    //   'new file size: ',
    //   newDimnsions.height,
    //   newDimnsions.width,
    //   sizeInKB
    // );

    // return newImage;
  }
  // console.log('no need to compress');
  return image;
};

// The dimensions are in millimeters.
const A4_PAPER_DIMENSIONS = {
  width: 210,
  height: 297,
};

const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;

// Calculates the best possible position of an image on the A4 paper format,
// so that the maximal area of A4 is used and the image ratio is preserved.
const imageDimensionsOnA4 = dimensions => {
  const isLandscapeImage = dimensions.width >= dimensions.height;

  // If the image is in landscape, the full width of A4 is used.
  if (isLandscapeImage) {
    return {
      width: A4_PAPER_DIMENSIONS.width,
      height:
        A4_PAPER_DIMENSIONS.width / (dimensions.width / dimensions.height),
    };
  }

  // If the image is in portrait and the full height of A4 would skew
  // the image ratio, we scale the image dimensions.
  const imageRatio = dimensions.width / dimensions.height;
  if (imageRatio > A4_PAPER_RATIO) {
    const imageScaleFactor =
      (A4_PAPER_RATIO * dimensions.height) / dimensions.width;

    const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;

    return {
      height: scaledImageHeight,
      width: scaledImageHeight * imageRatio,
    };
  }

  // The full height of A4 can be used without skewing the image ratio.
  return {
    width: A4_PAPER_DIMENSIONS.height / (dimensions.height / dimensions.width),
    height: A4_PAPER_DIMENSIONS.height,
  };
};

export function downloadBlob(blob, name = 'file.txt') {
  if (window.navigator && window.navigator.msSaveOrOpenBlob)
    return window.navigator.msSaveOrOpenBlob(blob);

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = data;
  link.download = name;

  // this is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
    link.remove();
  }, 100);
}

// Creates a PDF document containing all the uploaded images.
export const generatePdfFromImages = async images => {
  let fileDistribution = [];
  let interImages = [];

  for (let i = 0; i < images.length; i++) {
    const image = images[i];
    let nextImage = null;

    if (i + 1 < images.length) {
      // console.log('there is next image');
      nextImage = images[i + 1];
    } else {
      // console.log('no next image');
    }

    if (image.startsWith('data:image')) {
      interImages.push(image);
    } else {
      if (interImages.length > 0) {
        fileDistribution.push(interImages);
      }
      // console.log('clearing interImages');
      interImages = [];
      // console.log('cleared interImages', interImages);
      fileDistribution.push([image]);
    }

    // console.log(interImages);

    if (nextImage == null && interImages.length > 0) {
      fileDistribution.push(interImages);
    }
  }

  // console.log('file distribution', fileDistribution);

  let files = [];

  fileDistribution.forEach(async images => {
    const comingImages = [...images];
    // console.log(comingImages);
    if (comingImages[0].startsWith('data:image')) {
      // Default export is A4 paper, portrait, using millimeters for units.
      const doc = new jsPDF();

      // We let the images add all pages,
      // therefore the first default page can be removed.
      doc.deletePage(1);
      comingImages.forEach(async comingImage => {
        // console.log('running');
        doc.addPage();

        let i = new Image();
        i.src = comingImage;

        const imageDimensions = imageDimensionsOnA4({
          width: i.width,
          height: i.height,
        });

        doc.addImage({
          imageData: comingImage,
          format: 'jpeg',
          x: (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
          y: (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
          w: imageDimensions.width,
          h: imageDimensions.height,
          compression: 'FAST',
        });
      });
      const output = doc.output('arraybuffer');
      files.push(output);
    } else {
      files.push(comingImages[0]);
    }
  });

  // console.log('files', files);

  const pdfDoc = await PDFDocument.create();

  for (const file of files) {
    const donor = await PDFDocument.load(file);
    const pageLength = donor.getPages().length;

    for (let k = 0; k < pageLength; k++) {
      const [donorPage] = await pdfDoc.copyPages(donor, [k]);
      pdfDoc.addPage(donorPage);
    }
  }

  const pdfBytes = await pdfDoc.save();

  const generatedBlob = new Blob([pdfBytes]);
  // downloadBlob(generatedBlob, 'file.pdf');
  return generatedBlob;
};
