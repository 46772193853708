export const primaryText = '#1C2543';
export const secondryText = '#66729C';
export const cancelText = '#F05555';

export const primaryColor = '#0058FF';
export const secondryColor = '#2DC2BD';

export const px8 = '8px';
export const px10 = '10px';
export const px11 = '11px';
export const px12 = '12px';
export const px13 = '13px';
export const px14 = '14px';
export const px15 = '15px';
export const px16 = '16px';
export const px20 = '20px';
export const px22 = '22px';
