import React from 'react';
import { Box, Image, Stack, Text } from '@chakra-ui/react';

import { primaryText, px14, px15 } from '../../constants';
import supportImage from './../../../../assets/images/support.png';
import { PhoneIcon } from '../../../../assets/icons';

const ContactCard = ({ contactNumber, width = '100%' }) => {
  return (
    <Box
      as="button"
      height={['75px']}
      width={width}
      bgColor="white"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={5}
      boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
      borderRadius="5px"
      onClick={() => {
        if (contactNumber == null) {
          document.location.href = 'tel:08800684733';
        } else {
          document.location.href = `tel:${contactNumber}`;
        }
      }}
    >
      <Box
      // border="1px solid black"
      // display="flex"
      // justifyContent="center"
      // alignItems="center"
      // minWidth={['70px']}
      // minHeight={['70px']}
      // borderRadius={['7px']}
      // overflow="clip"
      // mb={-3}
      >
        <Image
          // ml={-3}
          boxSize="75px"
          objectFit="contain"
          src={supportImage}
          alt="Contact"
          // overflow="clip"
        />
      </Box>

      <Stack
        spacing={0}
        textAlign="center"
        // border="1px solid black"
        display="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        <Text
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          pl={2}
          fontSize={px14}
          fontWeight="bold"
          color={primaryText}
          textAlign="left"
        >
          Customer Support
        </Text>

        <Text
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          pl={2}
          fontSize={px15}
          fontWeight="bold"
          color={primaryText}
          textAlign="left"
        >
          {contactNumber == null ? '08800684733' : `${contactNumber}`}
        </Text>
      </Stack>

      <Box
        // border="1px solid black"
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius={['7px']}
      >
        <PhoneIcon boxSize={['33px']} />
      </Box>
    </Box>
  );
};

export default ContactCard;
