import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import useAlertHook from '../../hooks/errorHook';
import SuccessTransaction from './successTransaction';
import PendingTransaction from './pendingTransaction';
import FailedTrasaction from './failedTrasaction';
import AlertModal from '../../subComponents/alertModal/alertModal';
import { instance } from '../../../api';
import { storeHelper, pc, parseErrorMessage } from '../../../utils';
import OverlayLoader from '../../subComponents/overlayLoader';

const TransactionStatus = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState('');
  const [transaction, setTransaction] = useState(null);

  const { transactionId } = useParams();

  // console.log(transactionId);

  const {
    showAlertModal,
    setShowAlertModal,
    errorMessage,
    setErrorMessage,
  } = useAlertHook();

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  useEffect(() => {
    setIsLoading(true);
    instance
      .get(`opd/payment/status/${transactionId}`)
      .then(response => {
        setIsLoading(false);
        let data = response.data;

        if (data.status === 'success') {
          setTransaction(data);
          if (data.response.status === 'success') {
            setState('success');
            setTransaction({ ...data.response });
            storeHelper(pc.lastTransactionId, data.response.transaction_id);
            storeHelper(pc.transactionStatus, 'success');
          } else if (data.response.status === 'failed') {
            setState('fail');
            setTransaction({ ...data.response });
            storeHelper(pc.transactionStatus, 'fail');
          } else {
            setState('pending');
            setTransaction({ ...data.response });
            storeHelper(pc.transactionStatus, 'pending');
          }
        } else {
          setErrorMessage(data.message);
        }
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
        const errMsg = parseErrorMessage(err);
        setErrorMessage(errMsg);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId]);

  const decideState = state => {
    if (state === 'success') {
      return <SuccessTransaction state={state} transaction={transaction} />;
    } else if (state === 'fail') {
      return <FailedTrasaction state={state} transaction={transaction} />;
    } else if (state === 'pending') {
      return <PendingTransaction state={state} transaction={transaction} />;
    }
  };

  return (
    <Box margin="auto" className="containerHeight" pt={5}>
      {isLoading && <OverlayLoader message="Fetching" />}
      {!isLoading && decideState(state)}

      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          showUpload={false}
          onClose={() => {
            setErrorMessage('');
            setShowAlertModal(false);
          }}
          message={errorMessage}
        />
      )}
    </Box>
  );
};

export default TransactionStatus;
