import React from 'react';
import { Button, Box, Text, Image } from '@chakra-ui/react';

import blueBackImage from './../../../../assets/images/blueBack.png';
import whiteBackImage from './../../../../assets/images/whiteBack.png';
import { px13 } from '../../constants';

export const MPButton = ({
  text,
  onClick,
  size = 'md',
  leftIcon = null,
  rightIcon = null,
  disabled = false,
  color = '#0059ff',
  textColor = 'white',
}) => {
  return (
    <Button
      size={size}
      _active={{
        bgColor: { color },
        color: { textColor },
      }}
      _hover={{
        bgColor: { color },
        color: { textColor },
      }}
      disabled={disabled}
      bgColor={color}
      color={textColor}
      variant="solid"
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export const BackButton = ({
  iconSize = 4,
  fontSize = px13,
  color = 'white',
  onClick,
  mb = 0,
  disable = false,
}) => {
  return (
    <Box
      as="button"
      pointerEvents={disable ? 'none' : 'painted'}
      display="flex"
      onClick={onClick}
      mb={mb}
      disabled={disable}
    >
      <Image
        boxSize="18px"
        objectFit="cover"
        src={color === 'white' ? whiteBackImage : blueBackImage}
        alt="Back button"
      />
      <Text
        ml={1}
        textDecoration="underline"
        fontSize={[fontSize]}
        fontWeight="bold"
        color={color}
      >
        Go Back
      </Text>
    </Box>
  );
};
