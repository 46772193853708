import React, {useEffect, useState} from 'react';
import {
  Box,
  Text,
  Button,
  useToast,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';

import { primaryColor, px14, px11, px22 } from '../../constants';
import { DoctorIcon } from '../../../../assets/icons';

import { Formik } from 'formik';
import { InputControl, SelectControl, SubmitButton, CheckboxSingleControl } from 'formik-chakra-ui';
import * as Yup from 'yup';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import addMonths from 'date-fns/addMonths/index.js';
import { retrieveHelper, parseErrorMessage } from '../../../utils';
import { format, setHours, setMinutes } from 'date-fns/esm';
import { instance } from '../../../api';
import './index.css';

const validationSchema = Yup.object({
  full_name: Yup.string()
    .required('Full name is required')
    .matches(/^[a-zA-Z\s]*$/, "Only characters are allowed")
    .min(2, 'min 2 char allowed')
    .max(50, 'max 50 char allowed'),
  patient_age: Yup.number()
  .required('Age is required')
  .min(0.1, 'Age should be greater than 0')
  .max(150, 'Age cannot be more than 150'),
  mobile_number: Yup.number()
    .required('Whatsapp number is required')
    .max(9999999999, 'Must be a valid mobile number')
    .min(6000000000, 'Must be a valid mobile number'),
  patient_gender: Yup.string()
    .required('Gender is required')
});

const BookDoctor = ({ onClose, item }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [formState, setFormState] = useState({});
  const toast = useToast();

  useEffect(() => {
    setFormState({
      full_name: '',
      patient_age: '',
      patient_gender: '',
      mobile_number: '',
      alt_mobile_number: '',
    })
  },[])

  const onSubmit = (values,{resetForm}) => {
    setIsLoading(true);
    let providerDetails = {...item, 'rating': parseInt(item.rating)};
    const createAppointmentObject = {
      "time_slot": format(selectedDate, 'dd-MM-yyyy, h:mm aa'),
      "customer_details": {
        "full_name": values.full_name,
        "whatsapp_number": values.mobile_number,
        "age": values.patient_age,
        "gender": values.patient_gender,
        "alternate_number": values.alt_mobile_number,
        "coordinates": retrieveHelper('location')
      },
      "provider": providerDetails,
    }
    //console.log("appointment obj", createAppointmentObject);
    instance.post('/opd/booking/consultation/offline', createAppointmentObject)
    .then(response => {
        setIsLoading(false);
        let data = response.data;
        if(data.status === "success") {
            resetForm();
            setSelectedDate(null);
            onClose();
            toast({
            title: "Success",
            description: data.message,
            status: "success",
            duration: 3000,
            isClosable: true,
            });
        }
        else {
            toast({
            title: "Error!",
            description: data.message,
            status: "error",
            duration: 3000,
            isClosable: true,
            });
        }
    })
    .catch(e => {
        setIsLoading(false);
        const errMsg = parseErrorMessage(e);
        toast({
          title: "Error!",
          description: errMsg,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
    });
  };

  const handleFormData = (e,setFieldValue) => {
    e.preventDefault();
    if(e.target.checked) {
      setFieldValue('full_name', retrieveHelper('userData').full_name);
      setFieldValue('mobile_number', retrieveHelper('userData').whatsapp_number);
    } else {
      setFieldValue('full_name', '');
      setFieldValue('mobile_number', '');
    }
  }

  const filterPassedTime = (time) => {
    const currentDate = new Date(setHours(setMinutes(new Date(), new Date().getMinutes()),new Date().getHours() + 6));
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
        <>
            <Text textAlign="center" fontWeight="bold" fontSize={px22} style={{marginBottom:'20px'}}>Book an appointment</Text>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start" style={{marginBottom:'20px'}}>
                <div style={{backgroundColor:'#E6EFFF', padding:'10px', borderRadius:'4px'}}>
                <DoctorIcon width='40px' height='40px' />
                </div>
                <div style={{marginLeft:'10px'}}>
                <Text fontSize={px14} fontWeight="bold">{item.name}</Text>
                <Text fontSize={px11} color="#66729C">{item.address}</Text>
                </div>
            </Box>
            <Formik
            key={JSON.stringify(formState)}
            enableReinitialize={true}
            initialValues={formState}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({ handleSubmit, values, errors, setFieldValue, resetForm }) => (
            <Box
                width="100%"
                paddingX={0}
                m="10px auto"
                as="form"
                onSubmit={handleSubmit}
            >
                <CheckboxSingleControl
                name="self"
                my={3}
                onChange={e => handleFormData(e, setFieldValue)}
                >
                Booking for myself
                </CheckboxSingleControl>

                <InputGroup>
                <InputRightElement children={<CalendarIcon />} />
                <DatePicker
                        selected={selectedDate}
                        onChange={(date) => {
                          if(date){
                            if(date.getDate() > new Date().getDate()){
                              date.getHours() === 0 ? setSelectedDate(setHours(setMinutes(date, 0), 9)) : setSelectedDate(date);
                            } else {
                              const timeDiffinMin = (date.getTime() - new Date().getTime()) / (1000*60);
                              if(timeDiffinMin >= 360){
                                setSelectedDate(date);
                              } else {
                                setSelectedDate(null);
                              }
                            }
                          } else {
                            setSelectedDate(null);
                          }
                        }}
                        timeInputLabel="Time:"
                        dateFormat="dd-MM-yyyy, h:mm aa"
                        showTimeSelect
                        filterTime={filterPassedTime}
                        timeIntervals={30}
                        minDate={new Date()}
                        maxDate={addMonths(new Date(), 1)}
                        isClearable={false}
                        className='chakra-input css-nfdp5z'
                        placeholderText="Select appointment slot*"
                        minTime={setHours(setMinutes(new Date(), 0), 9)}
                        maxTime={setHours(setMinutes(new Date(), 0), 21)}
                        required
                />
                </InputGroup>
                

                <InputControl
                inputProps={{
                    type: 'text',
                    placeholder: 'Full name*',
                    color: 'black',
                }}
                width={['100%']}
                name="full_name"
                minH={'60px'}
                mt={5}
                //isRequired
                />

                <InputControl
                inputProps={{
                    type: 'number',
                    placeholder: 'Age*',
                    color: 'black',
                }}
                width={['100%']}
                name="patient_age"
                minH={'60px'}
                //isRequired
                />

                <SelectControl
                name="patient_gender"
                //isRequired
                width={['100%']}
                minH={'60px'}
                selectProps={{ placeholder: 'Select Gender*' }}
                >
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="others">Others</option>
                </SelectControl>

                <InputControl
                inputProps={{
                  type: 'number',
                  placeholder: 'Whatsapp Number*'
                }}
                onChange={e => {
                  const value = e.target.value;
                  if (value.length <= 10) {
                  setFieldValue('mobile_number', value);
                  } else {
                  setFieldValue('mobile_number', value.slice(0, -1));
                  }
              }}
                name='mobile_number'
                width={['100%']}
                minH={'60px'}
                />

                {/* <InputControl
                inputProps={{
                    type: 'number',
                    placeholder: 'Alternate Mobile No',
                }}
                onChange={e => {
                    const value = e.target.value;
                    if (value.length <= 10) {
                    setFieldValue('alt_mobile_number', value);
                    } else {
                    setFieldValue('alt_mobile_number', value.slice(0, -1));
                    }
                }}
                width={['100%']}
                name="alt_mobile_number"
                minH={'60px'}
                /> */}
                
                <Box display="flex" justifyContent="center" my={10}>
                    <Button
                    _hover={{
                        backgroundColor: '#fff'
                    }}
                    color={primaryColor}
                    borderWidth={1.5}
                    borderColor="#0059ff"
                    variant="outline"
                    onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <SubmitButton
                    isLoading={isLoading}
                    loadingText="Saving"
                    backgroundColor="#0058ff"
                    ml={2}
                    _hover={{
                        backgroundColor: '#0058ff',
                    }}
                    _active={{
                        backgroundColor: '#0058ff',
                    }}
                    >
                    Book
                    </SubmitButton>
                </Box>
            </Box>
            )}
            </Formik>
        </>
  );
};

export default BookDoctor;
