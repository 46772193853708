import React, { useEffect } from 'react';
import {
  Box,
  Stack,
  Text,
  VStack,
  // IconButton,
  Button,
} from '@chakra-ui/react';

import TransactionStatus from '../../scanUI/transactionsUI/transactionStatus';
import useAlertHook from '../../hooks/errorHook';
import AlertModal from './../../subComponents/alertModal/alertModal';
import {
  // storeHelper,
  retrieveHelper,
  pc,
  // parseErrorMessage,
  // registerEvent,
  // ec,
  // actions,
} from '../../../utils';
// import { inAppInstance } from '../../../api';
import { px12, px13, px14, px16, tealGreenColor } from '../../constants';
import MfineLogoBlack from '../../../../assets/images/mfine.svg';
import PoweredBy from '../../subComponents/poweredBy';

const PendingTransaction = ({ state, transaction }) => {
  // const [isLoading, setIsLoading] = useState(false);

  const {
    showAlertModal,
    setShowAlertModal,
    errorMessage,
    // setErrorMessage
  } = useAlertHook();

  const paidAmount = retrieveHelper(pc.paidAmount);

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  const handleRefreshCall = () => {
    window.location.reload();
    // setIsLoading(true);
    // inAppInstance
    //   .get(`opd/payment/status/${transactionId}`)
    //   .then(response => {
    //     setIsLoading(false);
    //     let data = response.data;

    //     if (data.status === 'success') {
    //       if (data.response.status === 'success') {
    //         registerEvent(
    //           ec.InAppTransaction,
    //           actions.PAYMENT_STATUS_REFRESH_SUCCESS
    //         );
    //         setTransaction({ ...data.response });
    //         storeHelper(pc.lastTransactionId, data.response.transaction_id);
    //         storeHelper(pc.transactionStatus, 'success');
    //       } else if (data.response.status === 'failed') {
    //         registerEvent(
    //           ec.InAppTransaction,
    //           actions.PAYMENT_STATUS_REFRESH_FAILED
    //         );
    //         storeHelper(pc.transactionStatus, 'fail');
    //       }
    //     } else {
    //       setErrorMessage(data.message);
    //     }
    //   })
    //   .catch(err => {
    //     setIsLoading(false);
    //     const errMsg = parseErrorMessage(err);
    //     setErrorMessage(errMsg);
    //   });
  };

  return (
    <Box
      display="flex"
      flexDir="column"
      flex={1}
      mt={10}
      height="100%"
      width="100%"
      margin="auto"
      justifyContent="space-between"
      alignItems="center"
      style={{ padding: '5% 5% 0 5%' }}
    >
      <img
        src={MfineLogoBlack}
        alt="logo_black"
        style={{ margin: '0 30%', width: '40%' }}
      />
      <Box width="100%" p={5} borderRadius="5px" bgColor="white">
        <TransactionStatus state={state} transaction={transaction} />

        <Stack textAlign="center" spacing={0}>
          <Text
            fontSize="32px"
            className="poppins fw-700"
            color={tealGreenColor}
          >
            ₹ {paidAmount}
          </Text>
          <Text className="poppins fw-500" fontSize={px16} color="#536474">
            Payment Pending
          </Text>

          <Box display="flex" justifyContent="center" py={5} fontSize={px14}>
            <VStack>
              <Box display="flex" width="100%" fontSize={px12}>
                <Text
                  textAlign="center"
                  color="#8EA4BB"
                  fontWeight="semibold"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  className="poppins fw-500"
                >
                  {transaction.transaction_id}
                </Text>
              </Box>
            </VStack>
          </Box>
        </Stack>
      </Box>

      <Box width="100%">
        <Button
          // isLoading={isLoading}
          isFullWidth
          minH={['40px']}
          _active={{
            bgColor: { tealGreenColor },
            color: 'white',
          }}
          _hover={{
            bgColor: { tealGreenColor },
            color: 'white',
          }}
          bgColor={tealGreenColor}
          color="white"
          variant="solid"
          size="md"
          onClick={() => {
            handleRefreshCall();
          }}
          mb="1vh"
        >
          <Text fontSize={px13}>Refresh Status</Text>
        </Button>
        <PoweredBy logoType="black" />
      </Box>

      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          showUpload={false}
          onClose={() => {
            setShowAlertModal(false);
          }}
          message={errorMessage}
        />
      )}
    </Box>
  );
};

export default PendingTransaction;
