import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: 'Rubik',
    body: 'Rubik',
  },
  styles: {
    global: {
      'html, body': {
        letterSpacing: 'wide',
      },
    },
  },
});
export default theme;
