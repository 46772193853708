import React from 'react';
import { Box } from '@chakra-ui/react';
import WebCamUISingle from '../../scanUI/uploadBillUI/webCamUISingle';
// import WebCamExperiment from '../../scanUI/uploadBillUI/webCamExperiment';

const UploadBill = () => {
  return (
    <Box>
      <WebCamUISingle />
      {/* <WebCamExperiment /> */}
    </Box>
  );
};

export default UploadBill;
