import React, { useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import {
  getTypeIcon,
  retrieveHelper,
  pc,
  parseErrorMessage,
  registerEvent,
  ec,
  actions,
} from '../../../../utils';
import {
  primaryColor,
  primaryText,
  px11,
  px13,
  px14,
  px8,
  secondryColor,
  secondryText,
} from '../../../constants';
import {
  BookDirectionIcon,
  PhoneIcon,
  StarIcon,
} from '../../../../../assets/icons';
import BookConfirmModal from '../../../subComponents/alertModal/bookConfirmModal';
import { instance } from '../../../../api';
import useAlertHook from '../../../hooks/errorHook';
import AlertModal from '../../../subComponents/alertModal/alertModal';
import Loader from '../../../subComponents/loader';

const Card = ({ item, index }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showBookConfirmModal, setShowBookConfirmModal] = useState(false);
  const [modalLink, setModalLink] = useState(null);

  const isSmallScreen = window.screen.width < 325;

  const { showAlertModal, setShowAlertModal, errorMessage, setErrorMessage } =
    useAlertHook();

  const handleBookNowCall = clinic => {
    let employeeId = retrieveHelper(pc.employeeId);
    let location = retrieveHelper(pc.location);

    registerEvent(ec.Listing, actions.BOOK_NOW);

    const payload = {
      provider_id: clinic.provider_id,
      employee_id: employeeId,
      coordinates: location,
    };

    if(retrieveHelper('searchQuery').type === "doctors") {
      if(retrieveHelper('balance') === "0"){
        setShowAlertModal(true);
        setErrorMessage("You have insufficient balance");
        return;
      }
      if(retrieveHelper('userData').bill_status === 'pending' || retrieveHelper('userData').bill_status === 'processing') {
        setShowAlertModal(true);
        setErrorMessage(retrieveHelper('userData').message);
        return;
      }
      if(retrieveHelper('userData').payment_collection_status) {
        setShowAlertModal(true);
        setErrorMessage(retrieveHelper('userData').message);
        setModalLink(retrieveHelper('userData').payment_link);
        return;
      }
    }

    setIsLoading(true);
    instance
      .post('/opd/network/booking', payload)
      .then(response => {
        setIsLoading(false);
        let data = response.data;
        if (data.status === 'success') {
          setShowBookConfirmModal(true);
        } else {
          setErrorMessage(data.message);
        }
      })
      .catch(err => {
        setIsLoading(false);
        const errMsg = parseErrorMessage(err);
        setErrorMessage(errMsg);
      });
  };

  const handleOpen = item => {
    if (item.opening_hours != null) {
      if (item.opening_hours.open_now === true) {
        return 'Open now';
      } else {
        return 'Closed';
      }
    } else {
      return '';
    }
  };

  const getParsedRating = rating => {
    const rate = Number.parseFloat(rating);
    if (isNaN(rate)) {
      return 'NA';
    } else {
      if (rate.toFixed(1) === '0.0') {
        return 'NA';
      } else {
        return rate.toFixed(1);
      }
    }
  };

  const getParsedDistance = distance => {
    const dist = Number.parseFloat(distance);
    if (isNaN(dist)) {
      return '-';
    } else {
      return item.distance.toFixed(2);
    }
  };

  const bookButtonText =
    item.provider_type === 'pharmacy' ? 'Order now' : 'Book now';

  return (
    <Box
      height="131px"
      bgColor="white"
      boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
      borderRadius="5px"
      my={index === 0 ? 0 : 4}
      mb={index === 0 ? 4 : 0}
      display="flex"
      flexDir="column"
      justifyContent="space-between"
    >
      <Box
        p={['10px']}
        height={['85px']}
        display="flex"
        width="100%"
        overflow="hidden"
      >
        <Box
          minHeight="75px"
          minWidth="75px"
          bgColor="#E6EFFF"
          borderRadius={['7px']}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {getTypeIcon(item.provider_type, '40px')}
        </Box>

        <Box
          height={['75px']}
          width={['100%']}
          display="flex"
          flexDir="column"
          overflow="clip"
          marginLeft={['10px']}
        >
          <Box
            width={'100%'}
            pr={['10px']}
            height={['20px']}
            fontSize={px14}
            fontWeight="bold"
            color={primaryText}
          >
            <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
              {item.name}
            </Text>
          </Box>
          <Box height={['30px']} overflow="hidden" mt={['2px']} width="80%">
            <Text fontSize={px11} color={secondryText}>
              {item.address}
            </Text>
          </Box>
          <Box
            width={['85%']}
            height={['15px']}
            overflow="clip"
            fontSize={isSmallScreen ? px8 : px11}
            color={primaryText}
            display="flex"
            mt={['5px']}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <StarIcon fontSize={isSmallScreen ? px8 : px11} mb={0.5} />
              <Text ml={['2px']}>{getParsedRating(item.rating)}</Text>
            </Box>
            <Text>{getParsedDistance(item.distance)}km away</Text>
            <Text>{handleOpen(item)}</Text>
          </Box>
        </Box>
      </Box>
      <Box
        width="100%"
        height="34px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          as="button"
          height="100%"
          width={['50%']}
          borderBottomLeftRadius="5px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={() => {
            registerEvent(ec.Listing, actions.MAP_DIRECTION);
            window.open(item.map_link, '_blank').focus();
          }}
        >
          <BookDirectionIcon boxSize={['20px']} />
          <Text pl={2} fontSize={px13} fontWeight="bold" color={secondryColor}>
            Directions
          </Text>
        </Box>
        <Box
          as="button"
          height="100%"
          width={['50%']}
          border="1px solid #0058ff"
          borderBottomRightRadius="5px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={() => handleBookNowCall(item)}
        >
          {isLoading ? (
            <Loader mt={0} color={primaryColor} size="xs" />
          ) : (
            <>
              <PhoneIcon color={'white'} boxSize={['15px']} />
              <Text
                pl={2}
                fontSize={px13}
                fontWeight="bold"
                color={primaryColor}
              >
                {bookButtonText}
              </Text>
            </>
          )}
        </Box>
      </Box>

      {showBookConfirmModal && (
        <BookConfirmModal
          isOpen={showBookConfirmModal}
          onClose={() => {
            setShowBookConfirmModal(false);
          }}
          item={item}
        />
      )}

      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          message={errorMessage}
          onClose={() => {
            setErrorMessage('');
            setShowAlertModal(false);
          }}
          link={modalLink}
        />
      )}
    </Box>
  );
};

export default Card;
