import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Box, Text, HStack, Button, Stack } from '@chakra-ui/react';
import Loader from '../../subComponents/loader';
import {
  retrieveHelper,
  pc,
  storeHelper,
  registerEvent,
  ec,
  actions,
  parseErrorMessage,
} from '../../../utils';
import { inAppInstance } from '../../../api/index';
import useAlertHook from '../../hooks/errorHook';
import AlertModal from '../../subComponents/alertModal/alertModal';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { tealGreenColor } from '../../constants';
import config from '../../../../config';
import { CircleRightIcon } from '../../../../assets/icons';

function PaymentDetail() {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const totalAmount = params.get('amount');
  if (!totalAmount) {
    history.push('/inAppTransaction');
  }
  storeHelper('inAppTotal', totalAmount);

  // eslint-disable-next-line no-unused-vars
  const [isBalanceAvailable, setIsBalanceAvailable] = useState(false);
  const [eligibilityDetails, setEligibilityDetails] = useState(null);
  const [eligibleBalance, setEligibleBalance] = useState(0);
  const [toPay, setToPay] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  //const [availableBalance, setAvailableBalance] = useState(0);
  const [hashedResponse, setHashedResponse] = useState({});
  const [errorLink, setErrorLink] = useState('');

  const { showAlertModal, setShowAlertModal, errorMessage, setErrorMessage } =
    useAlertHook();

  const didMount = useRef(false);

  useEffect(() => {
    inAppInstance
      .post('/opd/offline/cashless/payment/eligibility', {
        amount: totalAmount,
      })
      .then(response => {
        if (response.data.status === 'success') {
          setEligibilityDetails(response.data.response);
          setEligibleBalance(response.data.response.approved_amount);
        } else {
          setIsLoading(false);
          setErrorMessage(response.data.message);
        }
      })
      .catch(err => {
        setIsLoading(false);
        const errorMessage = parseErrorMessage(err);
        setErrorMessage(errorMessage);
        setErrorLink('/inAppTransaction');
      });
    registerEvent(ec.InAppPartialTransaction, actions.PARTIAL_PAYMENT_INIT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    if (parseFloat(totalAmount) > parseFloat(eligibleBalance)) {
      setIsLoading(false);
      setToPay((totalAmount - eligibleBalance).toFixed(2));
      setIsBalanceAvailable(false);
    } else {
      setIsBalanceAvailable(true);
      handleTransactionCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eligibleBalance]);

  // const handleChange = event => {
  //   //console.log(event.target.checked);
  //   if (event.target.checked) {
  //     setToPay(totalAmount - eligibleBalance);
  //   } else {
  //     setToPay(totalAmount);
  //   }
  // };

  const handleTransactionCall = () => {
    let QRCode = '';
    let QRName = '';
    let location = '';
    let paymentType = '';
    let payment_location = '';
    let transaction_id = '';

    try {
      paymentType = retrieveHelper(pc.paymentType);
      QRCode = retrieveHelper(pc.qrCode);
      QRName = retrieveHelper(pc.qrName);
      location = retrieveHelper(pc.location);
      payment_location = retrieveHelper(pc.payment_location)
        ? retrieveHelper(pc.payment_location)
        : '';
      transaction_id = retrieveHelper(pc.transaction_id);
    } catch (err) {
      const inAppDataURL = JSON.parse(localStorage.getItem(pc.inAppDataURL));
      history.push(`/partner/opd?data${inAppDataURL}`);
    }

    if (transaction_id == null || transaction_id === '') {
      setErrorMessage('Please reinitiate the transaction.');
      return;
    }

    const payload = {
      provider_details: {
        provider_account_name: QRName,
      },
      transaction_id: transaction_id,
      payment_upi_id: QRCode,
      coordinates: location,
      amount: totalAmount.toString(),
      patient_payable: toPay.toString(),
      payment_coordinates: payment_location,
    };

    storeHelper(pc.paidAmount, totalAmount.toString());

    if (paymentType === 'manual') {
      payload['payment_upi_mode'] = 'provider_phone_number';
    } else {
      payload['payment_upi_mode'] = 'qr_scan';
    }

    registerEvent(ec.InAppTransaction, actions.PAYMENT_VALIDATE_IN_PROCESS);

    setIsLoading(true);

    inAppInstance
      .post('/opd/offline/cashless/payment/transaction', payload)
      .then(response => {
        let data = response.data;
        if (data.status === 'success') {
          setIsLoading(false);
          registerEvent(ec.InAppTransaction, actions.PAYMENT_VALIDATE_DONE);
          if (data.response.status === 'success') {
            registerEvent(ec.InAppTransaction, actions.PAYMENT_STATUS_SUCCESS);
            storeHelper(pc.lastTransactionId, data.response.transaction_id);
            storeHelper(pc.transactionStatus, 'success');
            storeHelper(pc.inAppInvoiceLink, data.response.invoice_upload_link);
            storeHelper(pc.inAppInvoiceStatus, data.response.invoice_status);
          } else if (data.response.status === 'pending') {
            registerEvent(ec.InAppTransaction, actions.PAYMENT_STATUS_PENDING);
            storeHelper(pc.lastTransactionId, data.response.transaction_id);
            storeHelper(pc.transactionStatus, 'pending');
          } else {
            registerEvent(ec.InAppTransaction, actions.PAYMENT_STATUS_FAILED);
            storeHelper(pc.transactionStatus, 'fail');
          }
          history.push(
            `/inAppTransactionStatus/${data.response.transaction_id}`
          );
        } else {
          setShowAlertModal(true);
          if (
            data.message.includes('Please upload bill of last transaction.')
          ) {
            storeHelper(
              pc.lastTransactionId,
              data.response.last_transaction_id
            );
          }
          setIsLoading(false);
          setErrorMessage(data.message);
          setErrorLink('/inAppTransaction');
        }
      })
      .catch(err => {
        setIsLoading(false);
        setErrorMessage('Something went wrong.');
        setErrorLink('/inAppTransaction');
      });
  };

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  const obtainHashAndRedirect = () => {
    registerEvent(ec.InAppPartialTransaction, actions.CONFIRM_AND_PAY);
    setIsLoading(true);
    const payload = {
      amount: totalAmount,
      patient_payable: toPay.toString(),
    };
    inAppInstance
      .post('/opd/offline/cashless/payment/pg/payu-data', payload)
      .then(response => {
        if (response.data.status === 'success') {
          const d = response.data.response;
          setHashedResponse(d);
          document.getElementById('loadPayU').click();
        } else {
          setIsLoading(false);
          setErrorMessage(response.data.message);
        }
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
        setErrorMessage('Something went wrong.');
      });
  };

  return (
    <Box
      style={{ backgroundColor: '#fff' }}
      margin="auto"
      className="containerHeight"
    >
      <Button
        id="tokenExpiredButton"
        display="none"
        onClick={() => setShowAlertModal(true)}
      ></Button>
      {isLoading && (
        <Box
          height={['62px']}
          position="fixed"
          bottom={0}
          maxW="500px"
          width="100%"
          borderTopRadius="10px"
          bgColor={tealGreenColor}
          display="flex"
          justifyContent="center"
        >
          <HStack
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            width="60%"
          >
            <Loader mt={0} color="white" size="md" />
            <Text fontSize="13px" color="white">
              Fetching Data...
            </Text>
          </HStack>
        </Box>
      )}

      {!isLoading && (
        <Box
          display="flex"
          flexDir="column"
          justifyContent="space-between"
          height="100%"
        >
          <Box px={4}>
            <HStack mt={5} justifyContent="space-between">
              <ChevronLeftIcon
                style={{ cursor: 'pointer', fontSize: '28px' }}
                onClick={() => window.history.back()}
              />
              <Text className="poppins fw-400">Payment Details</Text>
              <Text style={{ opacity: 0 }}></Text>
            </HStack>
            <Box
              style={{ marginTop: '35px' }}
              display="flex"
              justifyContent="space-between"
            >
              <Stack spacing={5} direction="column">
                {/* <Checkbox
                  color="#6D8298"
                  className="poppins fw-400"
                  colorScheme="teal"
                  defaultChecked
                  onChange={e => handleChange(e)}
                >
                  Use Corporate Balance
                </Checkbox> */}
                <Text color="#6D8298" className="poppins fw-400">
                  Use Corporate Balance
                </Text>
                <Text
                  color="#6D8298"
                  className="poppins fw-600"
                  //style={{ marginLeft: '25px', marginTop: '0' }}
                  style={{ marginTop: '0' }}
                >
                  MFine
                </Text>
              </Stack>
              <Text color={tealGreenColor}>
                - &#8377;{eligibilityDetails == null ? '--' : eligibleBalance}
              </Text>
            </Box>
            <Box
              style={{ marginTop: '35px' }}
              display="flex"
              justifyContent="space-between"
            >
              <Text className="poppins fw-400" color="#6D8298">
                Total Bill
              </Text>
              <Text className="poppins fw-400" color="#38434E">
                &#8377;{eligibilityDetails == null ? '--' : totalAmount}
              </Text>
            </Box>
            <Box
              style={{ marginTop: '35px' }}
              display="flex"
              justifyContent="space-between"
            >
              <Text color="#6D8298" fontSize="24px" className="poppins fw-600">
                To Pay
              </Text>
              <Text color="#B94085" fontSize="24px" className="poppins fw-600">
                &#8377;{eligibilityDetails == null ? '--' : toPay}
              </Text>
            </Box>
          </Box>
          <Box>
            <Button
              isLoading={isLoading}
              loadingText="processing"
              isFullWidth
              isDisabled={eligibilityDetails == null}
              minH={['40px']}
              _active={{
                color: 'white',
                background: 'linear-gradient(180deg, #FFA38C 0%, #FC6B47 100%)',
              }}
              _hover={{
                color: 'white',
                background: 'linear-gradient(180deg, #FFA38C 0%, #FC6B47 100%)',
              }}
              background="linear-gradient(180deg, #FFA38C 0%, #FC6B47 100%)"
              color="white"
              variant="solid"
              size="md"
              borderRadius="0"
              style={{
                padding: '30px 0',
                display: 'flex',
                justifyContent: 'space-between',
              }}
              onClick={() => {
                obtainHashAndRedirect();
              }}
              disabled={toPay === 0}
            >
              <Text></Text>
              <Text className="poppins fw-600" fontSize="16px">
                Confirm & Pay &#8377;{toPay}
              </Text>
              <CircleRightIcon
                style={{ marginRight: '10px' }}
                boxSize={['24px']}
              />
            </Button>
          </Box>
        </Box>
      )}

      <form action={config.PAYU_URL} method="post">
        <input type="hidden" name="key" value={hashedResponse.key} />
        <input
          type="hidden"
          name="txnid"
          value={hashedResponse.transaction_id}
        />
        <input
          type="hidden"
          name="productinfo"
          value={hashedResponse.product_info}
        />
        <input type="hidden" name="amount" value={hashedResponse.amount} />
        <input type="hidden" name="email" value={hashedResponse.email_id} />
        <input type="hidden" name="firstname" value={hashedResponse.name} />
        <input type="hidden" name="surl" value={hashedResponse.surl} />
        <input type="hidden" name="furl" value={hashedResponse.furl} />
        <input
          type="hidden"
          name="enforce_paymethod"
          value="creditcard|debitcard|UPI|lazypay"
        />
        <input type="hidden" name="hash" value={hashedResponse.hash} />
        <input
          style={{ display: 'none' }}
          type="submit"
          id="loadPayU"
          value="submit"
        />
      </form>

      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          showUpload={false}
          onClose={() => {
            setShowAlertModal(false);
            if (errorMessage.includes('timed out')) {
              setTimeout(() => {
                document.getElementById('tokenExpiredButton').click();
              }, 0);
            } else {
              setErrorMessage('');
            }
          }}
          message={errorMessage}
          link={errorLink}
        />
      )}
    </Box>
  );
}

export default PaymentDetail;
