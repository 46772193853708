import React from 'react';
import { Box, Image, Text, Button } from '@chakra-ui/react';

import successLogo from './../../../../assets/images/success.png';
import { primaryColor, primaryText, px13, px16 } from '../../constants';
import { useHistory } from 'react-router-dom';
import { retrieveHelper, pc } from './../../../utils';

const SuccessUpload = () => {
  const history = useHistory();

  return (
    <Box
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      className="containerHeight"
    >
      <Box
        width="90%"
        margin="auto"
        mt={5}
        p={5}
        bgColor="white"
        boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
        borderRadius="5px"
      >
        <Box
          borderRadius={['5px']}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Image
            mr="3.5%"
            width={['85px']}
            height={['85px']}
            objectFit="cover"
            src={successLogo}
            alt="success"
          />
        </Box>

        <Box textAlign="center" color={primaryText} mt={2}>
          <Text fontSize={px16} fontWeight="bold">
            Bill Uploaded Successfully
          </Text>
          <Text fontSize={px13} pt={10}>
            Thank You!
          </Text>
        </Box>
      </Box>

      <Box width="90%" margin="auto" height="40px" mb="5vh">
        <Button
          variant="outline"
          isFullWidth
          borderColor={primaryColor}
          height={['40px']}
          color={primaryColor}
          onClick={() => {
            const employeeId = retrieveHelper(pc.employeeId);
            history.push(`/?id=${employeeId}`);
          }}
        >
          <Text fontSize={px13} fontWeight="bold" color={primaryColor}>
            Close
          </Text>
        </Button>
      </Box>
    </Box>
  );
};

export default SuccessUpload;
