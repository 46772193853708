import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import { primaryText, px14 } from '../../constants';

const HomeCard = ({ icon, text, onClick, width = '100%', disable = false }) => {
  return (
    <Box
      disabled={disable}
      pointerEvents={disable ? 'none' : 'painted'}
      as="button"
      height={['75px']}
      width={width}
      display="flex"
      alignItems="center"
      p={3}
      boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
      borderRadius="5px"
      onClick={onClick}
      bgColor={disable ? 'gray.300' : 'white'}
      overflow="hidden"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minWidth={['55px']}
        minHeight={['55px']}
        borderRadius={['7px']}
        bgColor="#E6EFFF"
      >
        {icon}
      </Box>

      <Text
        pl={2}
        fontSize={px14}
        fontWeight="bold"
        color={primaryText}
        textAlign="left"
      >
        {text}
      </Text>
    </Box>
  );
};

export default HomeCard;
