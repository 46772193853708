import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import { primaryColor } from '../../constants';
import './index.css';

const OverlayLoader = ({ message }) => {
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      width="100%"
      height="100%"
      zIndex={9999999}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        bgColor="white"
        p={8}
        borderRadius="10px"
        display="flex"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
      >
        {/* <Loader mt={0} color={primaryColor} /> */}
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <Text color={primaryColor}>{message}</Text>
      </Box>
    </Box>
  );
};

export default OverlayLoader;
