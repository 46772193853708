import React from 'react';
import { Box, VStack, Button, Stack, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router';
import { format, parse } from 'date-fns';

import {
  primaryText,
  secondryText,
  primaryColor,
  px14,
  px16,
  px12,
  px13,
} from '../../constants';
import TransactionStatus from '../../scanUI/transactionsUI/transactionStatus';
import { retrieveHelper, pc, registerEvent, ec, actions } from '../../../utils';

const SuccessTransaction = ({ state, transaction }) => {
  const history = useHistory();
  const QRName = retrieveHelper(pc.qrName);
  const paidAmount = retrieveHelper(pc.paidAmount);

  const getModifiedAt = transaction => {
    try {
      return format(
        parse(
          transaction.modified_at.split('.')[0],
          'dd/MM/yyyy HH:mm:ss',
          new Date()
        ),
        "hh:mm aaaaa'm' dd/MM/yyyy "
      );
    } catch (err) {
      return '';
    }
  };

  return (
    <Box
      display="flex"
      flexDir="column"
      flex={1}
      mt={10}
      height="100%"
      width="90%"
      margin="auto"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        width="100%"
        p={5}
        borderRadius="5px"
        bgColor="white"
        boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
      >
        <TransactionStatus state={state} transaction={transaction} />

        <Stack textAlign="center" spacing={0} mt={2}>
          <Text fontSize={px16} fontWeight="bold" color={primaryText}>
            ₹ {paidAmount}
          </Text>
          <Text fontSize={px16} fontWeight="bold" color={primaryText}>
            Payment Successful
          </Text>

          <Box display="flex" justifyContent="center" py={5} fontSize={px14}>
            <VStack>
              <Box display="flex" width="100%" fontSize={px12}>
                <VStack width="70%">
                  <Text textAlign="left" width={120} color={secondryText}>
                    Paid to
                  </Text>
                  <Text textAlign="left" width={120} color={secondryText}>
                    Completed at
                  </Text>
                  <Text textAlign="left" width={120} color={secondryText}>
                    Transaction ID
                  </Text>
                  <Text textAlign="left" width={120} color={secondryText}>
                    Bank Ref no
                  </Text>
                </VStack>
                <VStack width="50%">
                  <Text
                    textAlign="left"
                    width={150}
                    color={primaryText}
                    fontWeight="semibold"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {QRName}
                  </Text>
                  <Text
                    textAlign="left"
                    width={150}
                    color={primaryText}
                    fontWeight="semibold"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {getModifiedAt(transaction)}
                  </Text>
                  <Text
                    textAlign="left"
                    width={150}
                    color={primaryText}
                    fontWeight="semibold"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {transaction.transaction_id}
                  </Text>
                  <Text
                    textAlign="left"
                    width={150}
                    color={primaryText}
                    fontWeight="semibold"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {transaction.bank_reference}
                  </Text>
                </VStack>
              </Box>
            </VStack>
          </Box>
        </Stack>
      </Box>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        flexDir="column"
        width="100%"
        mb="2vh"
      >
        <Button
          isFullWidth
          minH={['40px']}
          _active={{
            bgColor: { primaryColor },
            color: 'white',
          }}
          _hover={{
            bgColor: { primaryColor },
            color: 'white',
          }}
          bgColor={primaryColor}
          color="white"
          variant="solid"
          size="md"
          onClick={() => {
            registerEvent(ec.Transaction, actions.UPLOAD_BILL_CLICK);
            history.push('/upload');
          }}
        >
          <Text fontSize={px13}>Upload Bill</Text>
        </Button>

        <Box
          as="button"
          height={['40px']}
          fontSize={px13}
          onClick={() => {
            const employeeId = retrieveHelper(pc.employeeId);
            history.push(`/?id=${employeeId}`);
          }}
        >
          <Text color={primaryColor}>Back Home</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default SuccessTransaction;
