import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import LoginForm from './loginForm';
const LoginPage = () => {
  return (
    <Box
      className="containerHeight"
      width="100%"
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      alignItems="center"
      overflow="scroll"
    >
      <Box
        position="fixed"
        top={0}
        height={['50px']}
        bgColor="#0058ff"
        width={['100%']}
        display="flex"
        justifyContent="center"
        alignItems="center"
        zIndex={99999}
      >
        <Text color="white" fontWeight="bold">
          Corporate Cashless Program Login
        </Text>
      </Box>
      <LoginForm />

      <Box
        height={['30px']}
        bgColor="#3c3732"
        width={['100%']}
        display="flex"
        justifyContent="center"
        alignItems="center"
        zIndex={99999}
      >
        <Text color="white">Powered by MedPay®</Text>
      </Box>
    </Box>
  );
};

export default LoginPage;
