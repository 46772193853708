import React, { useEffect, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import useAlertHook from '../../hooks/errorHook';
import SuccessTransaction from './successTransaction';
import PendingTransaction from './pendingTransaction';
import FailedTrasaction from './failedTrasaction';
import AlertModal from '../../subComponents/alertModal/alertModal';
import { inAppInstance } from '../../../api';
import {
  storeHelper,
  pc,
  parseErrorMessage,
  retrieveHelper,
} from '../../../utils';
import OverlayLoader from '../../subComponents/overlayLoader';
import SuccessLogo from '../../../../assets/images/success_new.png';
import { tealGreenColor } from '../../constants';
import PoweredBy from '../../subComponents/poweredBy';
import MfineLogoBlack from '../../../../assets/images/mfine.svg';

const TransactionStatus = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState('');
  const [transaction, setTransaction] = useState(null);
  const [showSuccessDetail, setShowSuccessDetail] = useState(false);

  const { transactionId } = useParams();

  // console.log(transactionId);

  const { showAlertModal, setShowAlertModal, errorMessage, setErrorMessage } =
    useAlertHook();

  useEffect(() => {
    if (errorMessage.trim() !== '') {
      setShowAlertModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  useEffect(() => {
    setIsLoading(true);
    inAppInstance
      .get(`/opd/offline/cashless/payment/transaction/status/${transactionId}`)
      .then(response => {
        setIsLoading(false);
        let data = response.data;

        if (data.status === 'success') {
          setTransaction(data);
          setTimeout(() => {
            setShowSuccessDetail(true);
          }, 1000);
          const InAppPayload = {
            transactionId: data.response.transaction_id,
            amount: data.response.payment_amount,
            transaction_status: data.response.status,
            created_at: data.response.payment_initiated_at,
          };
          if (data.response.status === 'success') {
            setState('success');
            setTransaction({ ...data.response });
            storeHelper(pc.lastTransactionId, data.response.transaction_id);
            storeHelper(pc.transactionStatus, 'success');
            if (window.ReactNativeWebView) {
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  type: 'PAYMENT_SUCCESS',
                  payload: {
                    ...InAppPayload,
                    transaction_completed_at:
                      data.response.payment_completed_at,
                    invoice_status: retrieveHelper('inAppInvoiceStatus'),
                    invoice_upload_link: retrieveHelper('inAppInvoiceLink'),
                  },
                })
              );
            }
          } else if (data.response.status === 'failed') {
            setState('fail');
            setTransaction({ ...data.response });
            storeHelper(pc.transactionStatus, 'fail');
            if (window.ReactNativeWebView) {
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  type: 'PAYMENT_FAILURE',
                  payload: InAppPayload,
                })
              );
            }
          } else {
            setState('processing');
            setTransaction({ ...data.response });
            storeHelper(pc.transactionStatus, 'processing');
            if (window.ReactNativeWebView) {
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  type: 'PAYMENT_PROCESSING',
                  payload: InAppPayload,
                })
              );
            }
          }
        } else {
          setErrorMessage(data.message);
        }
      })
      .catch(err => {
        // console.log(err);
        setIsLoading(false);
        const errMsg = parseErrorMessage(err);
        setErrorMessage(errMsg);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId]);

  const decideState = state => {
    if (state === 'success') {
      return (
        <>
          {showSuccessDetail ? (
            <SuccessTransaction state={state} transaction={transaction} />
          ) : (
            <Box
              display="flex"
              flexDir="column"
              alignItems="center"
              justifyContent="space-between"
              style={{ height: '100%', padding: '5% 5% 0 5%' }}
            >
              <img
                src={MfineLogoBlack}
                alt="logo_black"
                style={{ width: '40%', margin: '0 30%' }}
              />
              <Box style={{ width: '100%' }}>
                <img
                  src={SuccessLogo}
                  alt="success"
                  style={{ margin: '0 25%', width: '50%' }}
                />
                <Text
                  mt={5}
                  fontSize="18px"
                  className="poppins fw-500"
                  color={tealGreenColor}
                  textAlign="center"
                >
                  Payment Successful
                </Text>
              </Box>
              <PoweredBy logoType="black" />
            </Box>
          )}
        </>
      );
    } else if (state === 'fail') {
      return <FailedTrasaction state={state} transaction={transaction} />;
    } else if (state === 'processing') {
      return <PendingTransaction state={state} transaction={transaction} />;
    }
  };

  return (
    <Box
      margin="auto"
      className="containerHeight"
      style={{ backgroundColor: '#fff' }}
    >
      {isLoading && <OverlayLoader message="Fetching" />}
      {!isLoading && decideState(state)}

      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          showUpload={false}
          onClose={() => {
            setErrorMessage('');
            setShowAlertModal(false);
          }}
          message={errorMessage}
        />
      )}
    </Box>
  );
};

export default TransactionStatus;
