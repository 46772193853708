import React from 'react';
import {
  Box,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from '@chakra-ui/react';

const AlertModal = ({ isOpen, onClose, message, showUpload = false }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent width={300}>
        <ModalBody>
          <Box p={5}>
            <Text textAlign="center">{message}</Text>

            <Box display="flex" justifyContent="center" mt={5}>
              <Button
                bgColor="#0059ff"
                color="white"
                _hover={{
                  bgColor: '#0059ff',
                  color: 'white',
                }}
                _active={{
                  bgColor: '#0059ff',
                  color: 'white',
                }}
                onClick={onClose}
                size="sm"
              >
                {showUpload ? 'Upload' : 'Okay'}
              </Button>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AlertModal;
