import React from 'react';
import { Box, Image, Text, Stack, HStack } from '@chakra-ui/react';

import failLogo from './../../../../assets/images/fail.png';

const Cancel = ({ data }) => {
  const { booking_details } = data;
  return (
    <Box
      height="100%"
      display="flex"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      mt={-10}
    >
      {booking_details && (
        <>
          <Image
            width={['100px']}
            height={['100px']}
            objectFit="contain"
            src={failLogo}
            alt="Cancel"
          />
          <Text color="3C3732" fontSize="24px" fontWeight="bold">
            Check-in Cancelled
          </Text>

          <Box
            width="95%"
            // margin="auto"
            // bgColor="white"
            // borderRadius={10}
          >
            <Stack p={5} fontSize={['14px']} fontWeight="normal">
              <Box
                display="flex"
                justifyContent="center"
                flexDir="column"
                alignItems="center"
              >
                <HStack textAlign="left" width="100%" alignItems="start" my={1}>
                  <Text width="40%">Patient Name</Text>
                  <Text
                    width="60%"
                    fontWeight="bold"
                    color="#0059ff"
                    className="two-line-wrap"
                  >
                    {booking_details.name}
                  </Text>
                </HStack>

                <HStack textAlign="left" width="100%" alignItems="start" my={1}>
                  <Text width="40%">Visited At</Text>
                  <Text
                    width="60%"
                    fontWeight="bold"
                    color="#0059ff"
                    className="two-line-wrap"
                  >
                    {booking_details.clinic_name}
                  </Text>
                </HStack>

                <HStack textAlign="left" width="100%" alignItems="start" my={1}>
                  <Text width="40%">Time of Visit</Text>
                  <Text
                    width="60%"
                    fontWeight="bold"
                    color="#0059ff"
                    className="two-line-wrap"
                  >
                    {booking_details.time}
                  </Text>
                </HStack>
              </Box>
            </Stack>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Cancel;
