import React, { useEffect, useCallback, useRef } from 'react';
import {
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import debounce from 'lodash.debounce';

import {
  SearchIcon,
  // SliderIcon
} from '../../../../assets/icons';
import { primaryText } from '../../constants';
import { inAppInstance } from '../../../api';
import {
  comparator,
  pc,
  storeHelper,
  retrieveHelper,
  textSearchMapping,
  parseErrorMessage,
  registerEvent,
  ec,
} from '../../../utils';

const SearchUI = ({
  setIsSearchLoading,
  setData,
  setErrorMessage,
  getProviders,
  topRated,
  setSortedData,
  inputSearch,
  setInputSearch,
  input,
  setInput,
  selectedSubType,
}) => {
  const didMount = useRef(false);

  const handleSearchInput = async (input, cb) => {
    const location = retrieveHelper(pc.location);
    const searchQuery = retrieveHelper(pc.searchQuery);
    const type = textSearchMapping[searchQuery.type];
    const keyword = searchQuery.keyword;

    let url = `/opd/offline/cashless/network/providers/nearby/autocomplete?coordinates=${location}&input=${input}&keyword=${type}`;

    if (!keyword.includes('near') && type !== 'others') {
      url = `/opd/offline/cashless/network/providers/nearby/autocomplete?coordinates=${location}&input=${input}&keyword=${type}&specialty=${keyword}`;
    } else if (type === 'others') {
      url = `/opd/offline/cashless/network/providers/nearby/autocomplete?coordinates=${location}&input=${input}&keyword=${keyword}`;
    }

    registerEvent(ec.Listing, url);

    setIsSearchLoading(true);
    inAppInstance
      .get(url)
      .then(response => {
        setIsSearchLoading(false);
        const data = response.data;

        if (data.status === 'success') {
          cb(data.response);
        } else {
          setErrorMessage(data.message);
        }
      })
      .catch(err => {
        setIsSearchLoading(false);
        const errMsg = parseErrorMessage(err);
        setErrorMessage(errMsg);
      });
  };

  // eslint-disable-next-line
  const debounceHandleInputSearch = useCallback(
    debounce((input, cb) => {
      handleSearchInput(input, cb);
    }, 500),
    []
  );

  const getRelatedProviders = () => {
    const searchQuery = retrieveHelper(pc.searchQuery);

    getProviders(
      searchQuery.type,
      searchQuery.keyword,
      topRated ? 'prominance' : 'distance'
    );
  };

  useEffect(() => {
    if (didMount.current) {
      const searchProvider = retrieveHelper(pc.searchProvider);

      if (!inputSearch && searchProvider) {
        setInput('');
        getRelatedProviders();
      } else if (!inputSearch) {
        setInput('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch]);

  useEffect(() => {
    if (didMount.current) {
      if (input.length > 1) {
        setInputSearch(true);
        debounceHandleInputSearch(input, res => {
          setData([...res]);
          const sortedProviders = res.sort(comparator);
          setSortedData([...sortedProviders]);
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  useEffect(() => {
    didMount.current = true;
  }, []);

  return (
    <>
      <InputGroup width={['100%']} my={1}>
        <InputLeftElement
          children={<SearchIcon mt={1} />}
          pointerEvents="none"
        />
        <Input
          // isDisabled={isLoading || isSearchLoading}
          placeholder={
            selectedSubType === 'near me'
              ? 'Search near me'
              : `Search in ${selectedSubType}`
          }
          value={input}
          onChange={event => {
            let value = event.target.value;
            if (value.trim() !== '') {
              setInput(event.target.value);
            } else {
              setInput('');
            }
          }}
          height={['44px']}
          bgColor="white"
          boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
          borderRadius="10px"
        />
        <InputRightElement
          children={
            inputSearch ? (
              <CloseIcon boxSize={3} color={primaryText} mt={1} />
            ) : // <SliderIcon mt={1} />
            null
          }
          pointerEvents={inputSearch ? 'painted' : 'none'}
          onClick={() => {
            storeHelper(pc.searchProvider, true);
            setInputSearch(false);
          }}
        />
      </InputGroup>
    </>
  );
};

export default SearchUI;
