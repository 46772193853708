import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  retrieveHelper,
  pc,
  storeHelper,
  registerEvent,
  ec,
  actions,
} from '../../../utils';
import { inAppInstance } from '../../../api/index';
import { Box, HStack, Text, Button } from '@chakra-ui/react';
import { tealGreenColor } from '../../constants';
import Loader from '../../subComponents/loader';
import useAlertHook from '../../hooks/errorHook';
import AlertModal from '../../subComponents/alertModal/alertModal';

const PgSuccess = () => {
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showprocessing, setShowProcessing] = useState(false);

  const { showAlertModal, setShowAlertModal, errorMessage, setErrorMessage } =
    useAlertHook();

  const handleTransactionCall = () => {
    let QRCode = '';
    let QRName = '';
    let location = '';
    let paymentType = '';
    let totalAmount = '';
    let patientPayable = '';
    let paymentLocation = '';
    let transaction_id = '';

    try {
      paymentType = retrieveHelper(pc.paymentType);
      QRCode = retrieveHelper(pc.qrCode);
      QRName = retrieveHelper(pc.qrName);
      location = retrieveHelper(pc.location);
      totalAmount = retrieveHelper('inAppTotal');
      patientPayable = retrieveHelper('patientPayable');
      paymentLocation = retrieveHelper('payment_location')
        ? retrieveHelper('payment_location')
        : '';
      transaction_id = retrieveHelper(pc.transaction_id);
    } catch (err) {
      const inAppDataURL = JSON.parse(localStorage.getItem(pc.inAppDataURL));
      history.push(`/partner/opd?data${inAppDataURL}`);
    }

    if (transaction_id == null || transaction_id === '') {
      setErrorMessage('Please reinitiate the transaction.');
      return;
    }

    const payload = {
      provider_details: {
        provider_account_name: QRName,
      },
      transaction_id: transaction_id,
      payment_upi_id: QRCode,
      coordinates: location,
      amount: totalAmount?.toString(),
      patient_payable: patientPayable?.toString(),
      payment_coordinates: paymentLocation,
    };

    storeHelper(pc.paidAmount, totalAmount?.toString());

    if (paymentType === 'manual') {
      payload['payment_upi_mode'] = 'provider_phone_number';
    } else {
      payload['payment_upi_mode'] = 'qr_scan';
    }

    registerEvent(ec.InAppTransaction, actions.PAYMENT_VALIDATE_IN_PROCESS);

    setIsLoading(true);

    inAppInstance
      .post('/opd/offline/cashless/payment/transaction', payload)
      .then(response => {
        let data = response.data;
        if (data.status === 'success') {
          setIsLoading(false);
          registerEvent(ec.InAppTransaction, actions.PAYMENT_VALIDATE_DONE);
          if (data.response.status === 'success') {
            registerEvent(ec.InAppTransaction, actions.PAYMENT_STATUS_SUCCESS);
            storeHelper(pc.lastTransactionId, data.response.transaction_id);
            storeHelper(pc.transactionStatus, 'success');
            storeHelper(pc.inAppInvoiceLink, data.response.invoice_link);
            storeHelper(pc.inAppInvoiceStatus, data.response.invoice_status);
          } else if (data.response.status === 'pending') {
            registerEvent(ec.InAppTransaction, actions.PAYMENT_STATUS_PENDING);
            storeHelper(pc.lastTransactionId, data.response.transaction_id);
            storeHelper(pc.transactionStatus, 'pending');
          } else {
            registerEvent(ec.InAppTransaction, actions.PAYMENT_STATUS_FAILED);
            storeHelper(pc.transactionStatus, 'fail');
          }
          history.push(
            `/inAppTransactionStatus/${data.response.transaction_id}`
          );
        } else {
          setShowAlertModal(true);
          if (
            data.message.includes('Please upload bill of last transaction.')
          ) {
            storeHelper(
              pc.lastTransactionId,
              data.response.last_transaction_id
            );
          }
          setIsLoading(false);
          setErrorMessage(data.message);
          setShowError(true);
        }
      })
      .catch(err => {
        setIsLoading(false);
        setErrorMessage('Something went wrong.');
        setShowAlertModal(true);
        setShowProcessing(true);
      });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let data = queryParams.get('data');
    data = JSON.parse(window.atob(data));
    console.log(data);
    storeHelper('patientPayable', data.amount);
    registerEvent(ec.InAppPartialTransaction, actions.PARTIAL_PAYMENT_SUCCESS);
    handleTransactionCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="containerHeight" style={{ backgroundColor: '#fff' }}>
      <Button
        id="tokenExpiredButton"
        display="none"
        onClick={() => setShowAlertModal(true)}
      ></Button>
      {isLoading && (
        <Box
          height={['62px']}
          position="fixed"
          bottom={0}
          maxW="500px"
          width="100%"
          borderTopRadius="10px"
          bgColor={tealGreenColor}
          display="flex"
          justifyContent="center"
        >
          <HStack
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            width="60%"
          >
            <Loader mt={0} color="white" size="md" />
            <Text fontSize="13px" color="white">
              Redirecting...
            </Text>
          </HStack>
        </Box>
      )}
      {showAlertModal && (
        <AlertModal
          isOpen={showAlertModal}
          onClose={() => {
            setShowAlertModal(false);
            if (errorMessage.includes('timed out')) {
              setTimeout(() => {
                document.getElementById('tokenExpiredButton').click();
              }, 0);
            } else {
              setErrorMessage('');
            }
          }}
          message={errorMessage}
        />
      )}
      {showError && (
        <Box textAlign="center" mt={12}>
          <Text className="poppins fw-600" color="red" fontSize="28px">
            Failed
          </Text>
          <Text
            fontSize="14px"
            color="#6D8298"
            className="poppins fw-500"
            padding="30px"
          >
            Transaction failed. Any amount paid from your bank account will be
            refunded within within 24 hours.
          </Text>
        </Box>
      )}
      {showprocessing && (
        <Box textAlign="center" mt={12}>
          <Text
            className="poppins fw-600"
            color={tealGreenColor}
            fontSize="28px"
          >
            Processing...
          </Text>
          <Text
            fontSize="14px"
            color="#6D8298"
            className="poppins fw-500"
            padding="30px"
          >
            Your transaction is under processing and will be settled within 24
            hours.
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default PgSuccess;
